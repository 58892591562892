import React, { useState, useEffect, useRef } from "react";
import {
  Accordion,
  Col,
  Container,
  Row,
  Button,
  Card,
  Image,
  Tooltip,
  OverlayTrigger,
  Spinner,
  Alert,
} from "react-bootstrap";
import Helmet from "react-helmet";
// import the progress bar
import StepProgressBar from "react-step-progress";
// import the stylesheet
import "react-step-progress/dist/index.css";
import CustomForm from "../customcomponents/CustomForm";
import CustomFileUpload from "../customcomponents/CustomFileUpload";
import CustomFormIcon from "../customcomponents/CustomFormIcon";
import { Form } from "react-bootstrap";
import { Icon } from "@iconify/react";
import CustomFormWithToolTip from "../customcomponents/CustomFormWithToolTip";
import { Link } from "react-router-dom";
import Table from "react-bootstrap/Table";
import RightIcon from "../images/crossii.svg";
import RiskIcon from "../images/crossii.svg";
import { useNavigate } from "react-router-dom";
import LogoNew from "../images/logonew.png";
import LogoNew1 from "../images/logonew1.png";
import LogoNew2 from "../images/logonew2.png";
import CorssIconRed from "../images/crossii1.svg";
import CorssIconBlank from "../images/crossblank.svg";
import * as SERVICE from "../../services/index";
import * as API from "../../services/env";
import { Oval } from "react-loader-spinner";
import * as PIC from "../../services/env";

import debounce from "lodash/debounce";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useStickyBox } from "react-sticky-box";
import { logDOM } from "@testing-library/react";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
var countryCodeData = [];
const screeningCheck = {
  hitOne: "hitOne",
  hitSec: "hitSec",
};

const consgineescreeningCheck = {
  hitOne: "hitOne",
  hitSec: "hitSec",
};

const EndUserscreeningCheck = {
  hitOne: "hitOne",
  hitSec: "hitSec",
};

var array = "";
var screeningTypess = "";
var apiResult = "";
var Hsindex = "";
const BatchScreening = () => {
  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      This item cannot be shipped to the country indicated
    </Tooltip>
  );
  // setup the step content
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(true);

  function onFormSubmit() {
    navigate("/screening-overview/2");
    // navigate("/screening-overview/1");
  }

  const [apiResponse, setApiResponse] = useState(false);
  const [firstresponse, setfirstresponse] = useState("");

  const [screeningTypes, setscreeningTypes] = useState("");

  const [RPSScreening, setRPSScreening] = useState(false);
  const [RPSScreeningConsignee, setRPSScreeningConsignee] = useState(false);

  const [RPSScreeningenduser, setRPSScreeningenduser] = useState(false);

  const [HSCodeVerification, setHSCodeVerification] = useState(false);
  const [HSCCodeSanctions, setCodeSanctions] = useState(false);
  const [DualUseGoods, setDualUseGoods] = useState(false);
  const [DangerousGoods, setDangerousGoods] = useState(false);

  const modalRef = useRef(null);

  const RPSScreeningRef = useRef(
    (RPSScreening == true && RPSScreening) ||
      (RPSScreeningConsignee == true && RPSScreeningConsignee) ||
      (RPSScreeningenduser == true && RPSScreeningenduser)
  );
  const HSCodeVerificationRef = useRef(HSCodeVerification);
  const HSCCodeSanctionsRef = useRef(HSCCodeSanctions);
  const DualUseGoodsRef = useRef(DualUseGoods);
  const DangerousGoodsRef = useRef(DangerousGoods);

  const Step1Content = () => {
    const history = useNavigate();

    const [screening, setScreening] = useState(false);
    const [consigneeScreening, setconsigneeScreening] = useState(false);
    const [EndUserScreening, setEndUserScreening] = useState(false);
    const [Submit, setSubmit] = useState(false);
    const [referenceErr, setreferenceErr] = useState("");
    const [reference, setreference] = useState("");
    const [screeningType1, setscreeningType1] = useState("Batch Screening");
    const [screeningTypeErr, setscreeningTypeErr] = useState("");
    const [userType, setUserType] = useState("");
    const [userTypeerr, setUserTypeerr] = useState("");
    const [errorCount, seterrCount] = useState(0);
    const [ShipperName, setshipperName] = useState("");
    const [shipperNameerr, setshipperNameerr] = useState("");
    const [shipperSubmitError, setshipperSubmitError] = useState(false);
    const [risk, setrisk] = useState([]);
    const [ShipperDoB, setShipperDoB] = useState("");
    const [ShipperFile, setShipperFile] = useState("");
    const [shareholder_owns, setshareholder_owns] = useState("");
    const [Shipper_Owner, setShipper_Owner] = useState("");
    const [Shipper_Owners_Address, setShipper_Owners_Address] = useState("");

    const [setBox, setSetbox] = useState("0");
    const [BankCheck, setBankCheck] = useState("No");
    const [BankCheckerr, setBankCheckerr] = useState("");
    const [BankName, setBankName] = useState("");
    const [BankNameerr, setBankNameerr] = useState("");
    const [BankAdress, setBankAdress] = useState("");
    const [BankAdresserr, setBankAdresserr] = useState("");
    const [repoundTable, setrepoundTable] = useState("");
    const [selectedFile, setSelectedFile] = useState(null);
    const [apiResponse, setapiResponse] = useState("0");
    const [apiResponse1, setapiResponse1] = useState("0");
    const [name, setShipper_Name] = useState("");
    const [shipperData, setShipperData] = useState([""]);
    const [loader, setloader] = useState(false);
    const [riskststus, setriskststus] = useState("0");
    const [showRiskFeild, setshowRiskFeild] = useState("0");
    const [endUserIsDifferent, setEndUserIsDifferent] = useState(false);

    const [shipperloader, setshipperloader] = useState(false);

    const [consigneeloader, setconsigneeloader] = useState(false);

    const [EndUserloaders, setEndUserloaders] = useState(false);

    const [BatchScreeningRequest, setBatchScreeningRequest] = useState([]);

    const [shipperRiskData, setshipperRiskData] = useState("");

    const [consigneeshipperRiskData, setconsigneeshipperRiskData] =
      useState("");

    const [EndUsershipperRiskData, setEndUsershipperRiskData] = useState("");

    const [BatchConsigneeRequest, setBatchConsigneeRequest] = useState([]);

    const [BatchEndUserRequest, setBatchEndUserRequest] = useState([]);

    const [usergetSearchApi, setusergetSearchApi] = useState([]);

    const [consigneegetSearchApi, setconsigneegetSearchApi] = useState([]);
    const [cendUsergetSearchApi, setcendUsergetSearchApi] = useState([]);

    const [showNameModal, setShowNameModal] = useState(false);

    const [showNameModalIndex, setShowNameModalIndex] = useState(null);

    const [showNameModal1, setShowNameModal1] = useState(false);

    const [showNameModalIndex1, setShowNameModalIndex1] = useState(null);

    const [showNameModal2, setShowNameModal2] = useState(false);

    const [showNameModalIndex2, setShowNameModalIndex2] = useState(null);

    const [ConsigneeSubmit, setConsigneeSubmit] = useState(false);
    const [ConsigneescreeningType1, setsConsigneescreeningType1] =
      useState("Batch Screening");
    const [ConsigneescreeningTypeErr, setConsigneescreeningTypeErr] =
      useState("");
    const [ConsigneeuserType, setConsigneeUserType] = useState("");
    const [ConsigneeuserTypeerr, setConsigneeUserTypeerr] = useState("");
    const [ConsigneeerrorCount, setConsigneeerrCount] = useState(0);
    const [ConsigneeshipperSubmitError, setConsigneeshipperSubmitError] =
      useState(false);
    const [Consigneerisk, setConsigneerisk] = useState([]);
    const [ConsigneeShipperDoB, setConsigneeShipperDoB] = useState("");
    const [ConsigneeShipperFile, setConsigneeShipperFile] = useState("");
    const [Consigneeshareholder_owns, setConsigneeshareholder_owns] =
      useState("");

    const [ConsigneeShipper_Owner, setConsigneeShipper_Owner] = useState("");

    const [
      ConsigneeShipper_Owners_Address,
      setConsigneeShipper_Owners_Address,
    ] = useState("");

    const [ConsigneesetBox, setConsigneeSetbox] = useState("0");
    const [ConsigneeBankCheck, setConsigneeBankCheck] = useState("No");
    const [ConsigneeBankName, setConsigneeBankName] = useState("");
    const [ConsigneeBankAdress, setConsigneeBankAdress] = useState("");
    const [ConsigneerepoundTable, setrConsigneeepoundTable] = useState("");
    const [ConsigneeapiResponse, setConsigneeapiResponse] = useState("0");
    const [ConsigneeapiResponse1, setConsigneeapiResponse1] = useState("0");
    const [Consigneename, setConsigneeShipper_Name] = useState("");
    const [ConsigneeshipperData, setConsigneeShipperData] = useState([""]);
    const [Consigneeloader, setConsigneeloader] = useState(false);
    const [Consigneeriskststus, setConsigneeriskststus] = useState("0");
    const [ConsigneeshowRiskFeild, setConsigneeshowRiskFeild] = useState("0");

    const [yesStatusIndices, setYesStatusIndices] = useState([]);

    const [ConsigneeyesStatusIndices, setConsigneeyesStatusIndices] = useState(
      []
    );
    const [EndUseryesStatusIndices, setEndUseryesStatusIndices] = useState([]);

    const [EndUserSubmit, setEndUserSubmit] = useState(false);
    const [EndUserscreeningType1, setsEndUserscreeningType1] =
      useState("Batch Screening");
    const [EndUserscreeningTypeErr, setEndUserscreeningTypeErr] = useState("");
    const [EndUseruserType, setEndUserUserType] = useState("");
    const [EndUserserTypeerr, setEndUserUserTypeerr] = useState("");
    const [EndUserrrorCount, setEndUsererrCount] = useState(0);
    const [EndUsershipperSubmitError, setEndUsershipperSubmitError] =
      useState(false);
    const [EndUserrisk, setEndUserrisk] = useState([]);
    const [EndUserShipperDoB, setEndUserShipperDoB] = useState("");
    const [EndUserShipperFile, setEndUserShipperFile] = useState("");
    const [EndUsershareholder_owns, setEndUsershareholder_owns] = useState("");
    const [EndUserShipper_Owners_Address, setEndUserShipper_Owners_Address] =
      useState("");
    const [EndUsersetBox, setEndUserSetbox] = useState("0");
    const [EndUserBankCheck, setEndUserBankCheck] = useState("No");
    const [EndUserBankName, setEndUserBankName] = useState("");
    const [EndUserrepoundTable, setEndUserpoundTable] = useState("");
    const [EndUserapiResponse, setEndUserapiResponse] = useState("0");
    const [EndUserapiResponse1, setEndUserapiResponse1] = useState("0");
    const [EndUsername, setEndUserShipper_Name] = useState("");
    const [EndUsershipperData, setEndUserShipperData] = useState([""]);
    const [EndUserloader, setEndUserloader] = useState(false);
    const [EndUserriskststus, setEndUserriskststus] = useState("0");
    const [EndUsershowRiskFeild, setEndUsershowRiskFeild] = useState("0");

    const [excelUserrrorCount, setexcelUserrrorCount] = useState(0);

    const [excelUserSubmit, setexcelUserSubmit] = useState(false);

    const [ConsigneeepounRefernceNumber, setrConsigneeepounRefernceNumber] =
      useState("");

    const [Shipperreference, setrShipperreference] = useState("");

    const [EndUserserTypeRefernce, setEndUserserTypeRefernce] = useState("");
    const [seachingLoader, setseachingLoader] = useState({});
    const [consigneeseachingLoader, setconsigneeseachingLoader] = useState({});
    const [EndseachingLoader, setEndseachingLoader] = useState({});
    const handleSelectChange = (event) => {
      if (event.target.value == "Select") {
        setCommonuserType("");
      } else {
        setCommonuserType(event.target.value);
      }
    };

    const handleOwnershipChange = (event, index, shippername) => {
      const value = event.target.value;
      setshareholder_owns(value);
      handleInputChange(index, "shipperOwn", value, shippername);
    };
    const consgoineehandleOwnershipChange = (event, index, shippername) => {
      setConsigneeshareholder_owns(value);
      congsigneehandleInputChange(index, "shipperOwn", event, shippername);
    };

    const EndUserhandleOwnershipChange = (event, index, shippername) => {
      const value = event.target.value;
      setEndUsershareholder_owns(value);
      EnduSERhandleInputChange(index, "shipperOwn", value, shippername);
    };
    const handleRadioChange = (event) => {
      const selectedValue = event.target.value;
      setscreeningType1(
        selectedValue == "batch-screening"
          ? "Batch Screening"
          : "Per Shipment Screening"
      );
      // Navigate to the corresponding page based on the selected radio button
      switch (selectedValue) {
        case "add-screening":
          history("/add-screening");
          break;
        case "batch-screening":
          history("/batch-screening");
          break;
        // Add more cases for additional pages if needed
        default:
          break;
      }
    };

    const handleRepeatColumn = () => {
      // const newItem = [];
      // setCountColumn([...countColumn, newItem]);
      const old_arr = shipperData;
      old_arr.push("");
      setShipperData([...old_arr]);
      setShipper_Name([]);
    }; //

    const deleteItem = (index) => {
      setSubmit(false);
      setshipperSubmitError(false);
      const old_arr = shipperData;
      old_arr.splice(index, 1);

      // const updatedItems = [...shipperData];
      // updatedItems.splice(index, 1);
      setShipperData([...old_arr]);
    };

    const getInitialState = () => {
      const value = "Select";
      return value;
    };

    const consigneehandleRepeatColumn = () => {
      const old_arr = ConsigneeshipperData;
      old_arr.push("");
      setConsigneeShipperData([...old_arr]);
      setConsigneeShipper_Name([]);
    }; //

    const consigneedeleteItem = (index) => {
      setConsigneeSubmit(false);
      const old_arr = ConsigneeshipperData;
      old_arr.splice(index, 1);

      setConsigneeShipperData([...old_arr]);
    };

    const consigneegetInitialState = () => {
      const value = "Select";
      return value;
    };

    const EndUserhandleRepeatColumn = () => {
      const old_arr = EndUsershipperData;
      old_arr.push("");
      setEndUserShipperData([...old_arr]);
      setEndUserShipper_Name([]);
    };

    const EndUserdeleteItem = (index) => {
      setEndUserSubmit(false);
      const old_arr = EndUsershipperData;
      old_arr.splice(index, 1);
      setEndUserShipperData([...old_arr]);
    };

    const EndUsergetInitialState = () => {
      const value = "Select";
      return value;
    };

    const [FirstScreeningrespone, setFirstScreeningrespone] = useState("");
    const [ConsigneeFirstScreeningrespone, setConsigneeFirstScreeningrespone] =
      useState("");

    const [EndUserScreeningrespone, setEndUserScreeningrespone] = useState("");

    const [value, setValue] = React.useState(getInitialState);
    const [consigneevalue, setconsigneeValue] = React.useState(
      consigneegetInitialState
    );
    const [EndUservalue, setEndUserValue] = React.useState(
      EndUsergetInitialState
    );
    const [status, setStatus] = React.useState(0);
    const [consigneestatus, setconsigneeStatus] = React.useState(0);

    const [EndUserstatus, setEndUserStatus] = React.useState(0);

    const [CommonuserType, setCommonuserType] = useState("");

    const radioHandler = (status, index, ShipperName) => {
      if (status === "Yes") {
        setBankCheck(1);
        setapiResponse1("1");
        setYesStatusIndices((prevIndices) => [...prevIndices, index]);
        handleInputChange(index, "bankCheck", "Yes", ShipperName);
      } else {
        setBankCheck(0);
        setYesStatusIndices((prevIndices) =>
          prevIndices.filter((item) => item !== index)
        );
        handleInputChange(index, "bankCheck", "No", ShipperName);
      }
    };

    const consgineeradioHandler = (status, index, shippername) => {
      if (status == "Yes") {
        setConsigneeBankCheck(1);
        setConsigneeapiResponse1("1");
        setConsigneeyesStatusIndices((prevIndices) => [...prevIndices, index]);
        congsigneehandleInputChange(index, "bankCheck", "Yes", shippername);
      } else {
        setConsigneeBankCheck(0);
        setConsigneeyesStatusIndices((prevIndices) =>
          prevIndices.filter((item) => item !== index)
        );
        congsigneehandleInputChange(index, "bankCheck", "No", shippername);
      }
      setconsigneeStatus(status);
    };

    const EndUserradioHandler = (status, index, shippername) => {
      if (status == "Yes") {
        setEndUserBankCheck(1);
        setEndUserapiResponse1("1");
        setEndUseryesStatusIndices((prevIndices) => [...prevIndices, index]);
        EnduSERhandleInputChange(index, "bankCheck", "Yes", shippername);
      } else {
        setEndUserBankCheck(0);
        setEndUseryesStatusIndices((prevIndices) =>
          prevIndices.filter((item) => item !== index)
        );
        EnduSERhandleInputChange(index, "bankCheck", "No", shippername);
      }
      setEndUserStatus(status);
    };

    const openAllUserType = () => {
      setRPSScreening(false);
      setRPSScreeningConsignee(false);
      setRPSScreeningenduser(false);
      setSetbox("0");
      setapiResponse("0");

      setUserType("Consignee");
      setshipperNameerr("");

      setconsigneeValue("Consignee");
      setConsigneeSetbox("0");
      setConsigneeapiResponse("0");
      // setConsigneeriskststus('1')

      setConsigneeUserType("Consignee");
      setConsigneeShipper_Name("");
      setConsigneeSubmit(false);
      setConsigneeShipperData([""]);

      setEndUserUserType("End User");
      setEndUserSetbox("0");
      setEndUserapiResponse("0");
      setEndUserValue("End User");
      setEndUserSubmit(false);
      setEndUserShipperData([""]);

      setValue("Shipper");
      setSubmit(false);
      setShipperData([""]);
    };

    const handleAddcolumn = () => {
      setRPSScreening(false);
      setRPSScreeningConsignee(false);
      setRPSScreeningenduser(false);
      setSetbox("0");
      setapiResponse("0");

      setUserType(CommonuserType);
      setshipperNameerr("");

      if (CommonuserType === "Consignee") {
        setconsigneeValue(CommonuserType);
        setConsigneeSetbox("0");
        setConsigneeapiResponse("0");
        // setConsigneeriskststus('1')

        setConsigneeUserType(CommonuserType);
        setConsigneeShipper_Name("");
        setConsigneeSubmit(false);
        setConsigneeShipperData([""]);
      }
      if (CommonuserType === "End User") {
        setEndUserUserType(CommonuserType);
        setEndUserSetbox("0");
        setEndUserapiResponse("0");
        setEndUserValue(CommonuserType);
        setEndUserSubmit(false);
        setEndUserShipperData([""]);
      }
      if (CommonuserType === "Shipper") {
        setValue(CommonuserType);
        setSubmit(false);
        setShipperData([""]);
      }
    };

    const onProfileValidation = async () => {
      let errorCount = 0;

      if (SERVICE.hasError(userType)) {
        errorCount++;
        let msg = "Please select user type.";
        setUserTypeerr(msg);
      } else {
        setUserTypeerr(null);
      }

      if (SERVICE.hasError(screeningType1)) {
        errorCount++;
        let msg = "Please select screening.";
        setscreeningTypeErr(msg);
      } else {
        setscreeningTypeErr(null);
      }

      if (SERVICE.hasError(reference)) {
        errorCount++;
        let msg = "Please enter your reference.";
        setreferenceErr(msg);
      } else {
        setreferenceErr(null);
      }

      if (errorCount > 0) return seterrCount(errorCount);
      else seterrCount(0);
    };

    const onProfileValidation1 = async () => {
      let errorCount = 0;

      if (SERVICE.hasError(ConsigneeuserType)) {
        errorCount++;
        let msg = "Please select user type.";
        setConsigneeUserTypeerr(msg);
      } else {
        setConsigneeUserTypeerr(null);
      }

      if (SERVICE.hasError(ConsigneescreeningType1)) {
        errorCount++;
        let msg = "Please select screening.";
        setConsigneescreeningTypeErr(msg);
      } else {
        setConsigneescreeningTypeErr(null);
      }

      if (SERVICE.hasError(reference)) {
        errorCount++;
        let msg = "Please enter your reference.";
        setreferenceErr(msg);
      } else {
        setreferenceErr(null);
      }

      if (errorCount > 0) return setConsigneeerrCount(errorCount);
      else setConsigneeerrCount(0);
    };

    const onProfileValidation2 = async () => {
      let errorCount = 0;

      if (SERVICE.hasError(EndUseruserType)) {
        errorCount++;
        let msg = "Please select user type.";
        setEndUserUserTypeerr(msg);
      } else {
        setEndUserUserTypeerr(null);
      }

      if (SERVICE.hasError(EndUserscreeningType1)) {
        errorCount++;
        let msg = "Please select screening.";
        setEndUserscreeningTypeErr(msg);
      } else {
        setEndUserscreeningTypeErr(null);
      }

      if (SERVICE.hasError(reference)) {
        errorCount++;
        let msg = "Please enter your reference.";
        setreferenceErr(msg);
      } else {
        setreferenceErr(null);
      }

      if (errorCount > 0) return setEndUsererrCount(errorCount);
      else setEndUsererrCount(0);
    };

    const excelonProfileValidation = async () => {
      let errorCount = 0;

      if (SERVICE.hasError(screeningType1)) {
        errorCount++;
        let msg = "Please select screening.";
        setscreeningTypeErr(msg);
      } else {
        setscreeningTypeErr(null);
      }

      if (SERVICE.hasError(reference)) {
        errorCount++;
        let msg = "Please enter your reference.";
        setreferenceErr(msg);
      } else {
        setreferenceErr(null);
      }

      if (errorCount > 0) return setexcelUserrrorCount(errorCount);
      else setexcelUserrrorCount(0);
    };

    const onChange = async (evt, index) => {
      setloader(true);
      const pic = evt.target.files[0];
      if (evt.target.files[0] != undefined) {
        const target = evt.target.files[0];
        setSelectedFile(target);
        // setPreview(URL.createObjectURL(evt.target.files[0]));

        let data = new FormData();

        data.append("uploadFile", evt.target.files[0]);

        var requestOptions = {
          method: "POST",
          body: data,
        };
        const response = await fetch(
          `${API.BASE_URL}uploadFile`,
          requestOptions
        )
          .then((response) => response.json())
          .then(async (res) => {
            setloader(false);
            if (res.status === true) {
              setShipperFile(res.data);
              setBatchScreeningRequest((prevState) => {
                const newState = [...prevState];
                const updatedItem = {
                  ...newState[index],
                  shipperfile: res.data,
                };
                newState[index] = updatedItem;
                return newState;
              });
            }
          })
          .catch((error) => {});
      }
    };

    const onChangeconsignne = async (evt, index) => {
      setConsigneeloader(true);
      const pic = evt.target.files[0];
      if (evt.target.files[0] != undefined) {
        const target = evt.target.files[0];
        setSelectedFile(target);
        // setPreview(URL.createObjectURL(evt.target.files[0]));

        let data = new FormData();

        data.append("uploadFile", evt.target.files[0]);

        var requestOptions = {
          method: "POST",
          body: data,
        };
        const response = await fetch(
          `${API.BASE_URL}uploadFile`,
          requestOptions
        )
          .then((response) => response.json())
          .then(async (res) => {
            setConsigneeloader(false);
            if (res.status === true) {
              setConsigneeShipperFile(res.data);
              setBatchConsigneeRequest((prevState) => {
                const newState = [...prevState];
                const updatedItem = {
                  ...newState[index],
                  shipperfile: res.data,
                };
                newState[index] = updatedItem;
                return newState;
              });
            }
          })
          .catch((error) => {});
      }
    };

    const onChangeEndUser = async (evt, index) => {
      setEndUserloader(true);
      const pic = evt.target.files[0];
      if (evt.target.files[0] != undefined) {
        const target = evt.target.files[0];
        setSelectedFile(target);
        // setPreview(URL.createObjectURL(evt.target.files[0]));

        let data = new FormData();

        data.append("uploadFile", evt.target.files[0]);

        var requestOptions = {
          method: "POST",
          body: data,
        };
        const response = await fetch(
          `${API.BASE_URL}uploadFile`,
          requestOptions
        )
          .then((response) => response.json())
          .then(async (res) => {
            setEndUserloader(false);
            if (res.status === true) {
              setEndUserShipperFile(res.data);
              setBatchEndUserRequest((prevState) => {
                const newState = [...prevState];
                const updatedItem = {
                  ...newState[index],
                  shipperfile: res.data,
                };
                newState[index] = updatedItem;
                return newState;
              });
            }
          })
          .catch((error) => {});
      }
    };

    const [excelSelectedFile, setexcelSelectedFile] = useState(null);

    const handleFileChange = (event) => {
      setexcelUserSubmit(true);

      excelonProfileValidation();
      if (excelUserrrorCount > 0) return;
      const file = event.target.files[0];
      setexcelSelectedFile(file);

      excelSheetScreeningAPi(file);
    };

    const handleInputChange = (index, field, value, itemName, validates) => {
      setBatchScreeningRequest((prevState) => {
        const newState = [...prevState];
        const updatedItem = {
          ...newState[index],
          [field]: value,
          name: itemName,
          shipperfile: ShipperFile,
        };
        newState[index] = updatedItem;

        return newState;
      });
    };

    const congsigneehandleInputChange = (
      index,
      field,
      value,
      itemName,
      validates
    ) => {
      setBatchConsigneeRequest((prevState) => {
        const newState = [...prevState]; // Create a copy of the state array
        const updatedItem = {
          ...newState[index],
          [field]: value,
          name: itemName,
          shipperfile: ShipperFile,
        }; // Update the specified field and add the item name
        newState[index] = updatedItem;

        return newState;
      });
    };

    const EnduSERhandleInputChange = (
      index,
      field,
      value,
      itemName,
      validates
    ) => {
      setBatchEndUserRequest((prevState) => {
        const newState = [...prevState]; // Create a copy of the state array
        const updatedItem = {
          ...newState[index],
          [field]: value,
          name: itemName,
          shipperfile: ShipperFile,
        }; // Update the specified field and add the item name
        newState[index] = updatedItem;

        return newState;
      });
    };
    // Usage example:

    var shipperDoberr = "";
    var consgineeDoberr = "";
    var endUserDoberr = "";

    if (FirstScreeningrespone === "1") {
      setfirstresponse("1");
    }
    if (reference) {
      array = reference;
    } else if (screeningType1) {
      screeningTypess = screeningType1;
    }

    const ScreeningAPi = async () => {
      const hasEmptyField = BatchScreeningRequest.some((item) => {
        if (
          apiResponse === "1" &&
          (item.ShipperRisk === "" ||
            item.Shipper_Owners_Address === "" ||
            item.bankCheck === "" ||
            item.shipperOwn === "")
        ) {
          setshipperloader(false);

          toast("Please fill all required fields");
          return true; // Stop iterating when an empty field is found
        }
        if (FirstScreeningrespone === "1" && shipperDoberr === "1") {
          setshipperloader(false);

          toast("Please fill all required fields");
          return true; // Stop iterating when an empty field is found
        }

        if (
          BankCheck === "yes" &&
          (item.bankAddress === "" || item.bankName === "")
        ) {
          setshipperloader(false);

          toast("Please fill all required fields");
          return true; // Stop iterating when an empty field is found
        }
        return false;
      });

      if (hasEmptyField) {
        // Stop further execution if an empty field is found
        return;
      }

      name &&
        name.map((item) => {
          if (item.length == 0) {
            setshipperSubmitError(true);
            return;
          }
        });
      // name.map((item)=>{
      // })

      setSubmit(true);

      onProfileValidation();
      if (errorCount > 0) return;

      if (name.length != 0) {
        setshipperloader(true);

        const userData = localStorage.getItem("userDetail");
        if (userData) {
          var data = JSON.parse(userData);
        }
        setshipperSubmitError(false);

        let payload = {
          name: shipperData.map((item) => ({
            name: item,
          })),
          reference_number: Shipperreference
            ? Shipperreference
            : ConsigneeepounRefernceNumber
            ? ConsigneeepounRefernceNumber
            : EndUserserTypeRefernce
            ? EndUserserTypeRefernce
            : reference,
          check: [{ userType: userType }],
          userType: userType,
          repound: shipperData.map((item) => ({
            ShipperName: item,
          })),
          shipmentType: screeningType1,
          userId: data._id,
          shareHolder_Owns: shareholder_owns,
          Shipper_Owners_name: BatchScreeningRequest,
          Shipper_Owners_Address: Shipper_Owners_Address,
          bankCheck: BankCheck,
          shipperFile: ShipperFile,
          shipperDOB: ShipperDoB,
          bankAddress: BankAdress,
          bankName: BankName,
          type: "nonexcel",
        };

        await fetch(`${API.BASE_URL}Batch_ScreningAPI`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          // mode: "cors",
          body: JSON.stringify({
            name: shipperData.map((item) => ({
              name: item,
            })),
            reference_number: Shipperreference
              ? Shipperreference
              : ConsigneeepounRefernceNumber
              ? ConsigneeepounRefernceNumber
              : EndUserserTypeRefernce
              ? EndUserserTypeRefernce
              : reference,
            check: [{ userType: userType }],
            userType: userType,
            repound: shipperData.map((item) => ({
              ShipperName: item,
            })),
            shipmentType: screeningType1,
            userId: data._id,
            shareHolder_Owns: shareholder_owns,
            Shipper_Owners_name: BatchScreeningRequest,
            Shipper_Owners_Address: Shipper_Owners_Address,
            bankCheck: BankCheck,
            shipperFile: ShipperFile,
            shipperDOB: ShipperDoB,
            bankAddress: BankAdress,
            bankName: BankName,
          }),
        })
          .then((response) => response.json())
          .then(async (res) => {
            setshipperloader(false);
            apiResult = "1";
            console.log("resresres", res);
            await localStorage.setItem(
              "BtachScreeningShipper",
              JSON.stringify(payload)
            );

            await localStorage.setItem(
              "reference",
              JSON.stringify(
                Shipperreference
                  ? Shipperreference
                  : ConsigneeepounRefernceNumber
                  ? ConsigneeepounRefernceNumber
                  : EndUserserTypeRefernce
                  ? EndUserserTypeRefernce
                  : reference
              )
            );
            localStorage.setItem("screeningcomplete", "false");
            // localStorage.removeItem("hsCodeVerficationData");
            setSetbox("1");

            if (res.status === true) {
              var filteredArrayTable = res.finalData[0].shipperNamerisk.filter(
                (item) => ({
                  ShipperName: item.name,
                  risk: item.risk,
                })
              );

              await localStorage.setItem(
                "BtachScreeningShipperRiskDatas",
                JSON.stringify(filteredArrayTable)
              );

              setshipperRiskData(filteredArrayTable);
              setriskststus("1");
              setFirstScreeningrespone("1");
              toast("No risk found, You can proceed to next");

              setRPSScreening(true);
            } else if (res.status === false) {
              if (userType == "Consignee") {
                await localStorage.setItem(
                  "ConsigneeDATA",
                  JSON.stringify(payload)
                );
              }

              var filteredArray = res.finalData[0].repound
                .filter((item) => item.risk)
                .map((item) => item.risk);

              var filteredArrayTable = res.finalData[0].repound.filter(
                (item) => ({
                  ShipperName: item.ShipperName,
                  risk: item.risk,
                  status: item.status,
                  validate: "false",
                })
              );

              await localStorage.setItem(
                "BtachScreeningShipperRiskDatas",
                JSON.stringify(filteredArrayTable)
              );

              const riskArray = [filteredArray];
              const repoundRealTimeTable = [filteredArrayTable];
              setrepoundTable(repoundRealTimeTable);
              setrisk(riskArray);

              if (screening == true) {
                if (screening) setScreening(screeningCheck.hitOne);
                if (screening === screeningCheck.hitOne)
                  setScreening(screeningCheck.hitSec);
              } else if (screening == false) {
                if (!screening) setScreening(screeningCheck.hitOne);
                if (screening === screeningCheck.hitOne)
                  setScreening(screeningCheck.hitSec);
              }

              // toast('No Risk Found')

              setRPSScreening(true);

              setSubmit(false);
            }
            if (
              res.data.status == true ||
              res.USA.status == true ||
              res.UK.status == true
            ) {
              if (BatchScreeningRequest.length > 0) {
                var filteredArrayTable =
                  res.finalData[0].shipperNamerisk.filter((item) => ({
                    ShipperName: item.name,
                    risk: item.risk,
                  }));

                setshipperRiskData(filteredArrayTable);
                setriskststus("1");
                setFirstScreeningrespone("1");
                setRPSScreening(true);

                if (res.status != true) {
                  toast("Risk Found, You can proceed to next");
                }

                await localStorage.setItem(
                  "BtachScreeningShipperRiskData",
                  JSON.stringify(filteredArrayTable)
                );
              } else {
                var filteredArray = res.finalData[0].repound
                  .filter((item) => item.risk)
                  .map((item) => item.risk);

                var filteredArrayTable = res.finalData[0].repound.filter(
                  (item) => {
                    return {
                      ShipperName: item.ShipperName,
                      risk: item.risk,
                      status: item.status,
                      // Add the validate field with a default value of false
                    };
                  }
                );

                for (let i = 0; i < filteredArrayTable.length; i++) {
                  filteredArrayTable[i].validate = false;
                }

                const riskArray = [filteredArray];
                const repoundRealTimeTable = [filteredArrayTable];

                await localStorage.setItem(
                  "BtachScreeningShipperRiskDatas",
                  JSON.stringify(filteredArrayTable)
                );

                setrepoundTable(repoundRealTimeTable);
                setrisk(riskArray);
                if (screening == true) {
                  if (screening) setScreening(screeningCheck.hitOne);
                  if (screening === screeningCheck.hitOne)
                    setScreening(screeningCheck.hitSec);
                } else if (screening == false) {
                  if (!screening) setScreening(screeningCheck.hitOne);
                  if (screening === screeningCheck.hitOne)
                    setScreening(screeningCheck.hitSec);
                }
                setapiResponse("1");
                setriskststus("1");
                setRPSScreeningConsignee(false);
                setRPSScreeningenduser(false);

                // toast("Risk found");
              }
            }

            if (
              res.data.status == false &&
              res.USA.status == false &&
              res.UK.status == false
            ) {
              setshowRiskFeild("1");
              setRPSScreening(true);

              setriskststus("0");

              var filteredArray = res.finalData[0].repound
                .filter((item) => item.risk)
                .map((item) => item.risk);

              var filteredArrayTable = res.finalData[0].repound.filter(
                (item) => ({
                  ShipperName: item.ShipperName,
                  risk: item.risk,
                  status: item.status,
                  validate: "false",
                })
              );

              await localStorage.setItem(
                "BtachScreeningShipperRiskDatas",
                JSON.stringify(filteredArrayTable)
              );

              const riskArray = [filteredArray];
              const repoundRealTimeTable = [filteredArrayTable];
              setrepoundTable(repoundRealTimeTable);
              setrisk(riskArray);

              if (screening == true) {
                if (screening) setScreening(screeningCheck.hitOne);
                if (screening === screeningCheck.hitOne)
                  setScreening(screeningCheck.hitSec);
              } else if (screening == false) {
                if (!screening) setScreening(screeningCheck.hitOne);
                if (screening === screeningCheck.hitOne)
                  setScreening(screeningCheck.hitSec);
              }

              toast("No risk found, You can proceed to next");
            }

            if (res.status == "false") {
              setRPSScreening(true);

              toast("No risk found, You can proceed to next");
            }
          })
          .catch((error) => {});
      }
    };

    const consigneeScreeningAPi = async () => {
      const hasEmptyField = BatchConsigneeRequest.some((item) => {
        if (
          ConsigneeapiResponse === "1" &&
          (item.ShipperRisk === "" ||
            item.Shipper_Owners_Address === "" ||
            item.bankCheck === "" ||
            item.shipperOwn === "")
        ) {
          setconsigneeloader(false);

          toast("Please fill all required fields");
          return true; // Stop iterating when an empty field is found
        }
        if (ConsigneeFirstScreeningrespone === "1" && consgineeDoberr == "1") {
          setconsigneeloader(false);

          toast("Please fill all required fields");
          return true; // Stop iterating when an empty field is found
        }
        if (
          ConsigneeBankCheck === "yes" &&
          (item.bankAddress === "" || item.bankName === "")
        ) {
          setconsigneeloader(false);
          toast("Please fill all required fields");
          return true; // Stop iterating when an empty field is found
        }
        return false;
      });
      if (hasEmptyField) {
        // Stop further execution if an empty field is found
        return;
      }

      Consigneename &&
        Consigneename.map((item) => {
          if (item.length == 0) {
            setConsigneeshipperSubmitError(true);
            return;
          }
        });

      setConsigneeSubmit(true);

      onProfileValidation1();
      if (ConsigneeerrorCount > 0) return;

      if (Consigneename.length != 0) {
        setconsigneeloader(true);
        const userData = localStorage.getItem("userDetail");
        if (userData) {
          var data = JSON.parse(userData);
        }
        setConsigneeshipperSubmitError(false);

        let payload = {
          name: ConsigneeshipperData.map((item) => ({
            name: item,
          })),
          reference_number: Shipperreference
            ? Shipperreference
            : ConsigneeepounRefernceNumber
            ? ConsigneeepounRefernceNumber
            : EndUserserTypeRefernce
            ? EndUserserTypeRefernce
            : reference,
          check: [{ userType: userType }],

          userType: userType,
          repound: ConsigneeshipperData.map((item) => ({
            ShipperName: item,
          })),
          shipmentType: screeningType1,
          userId: data._id,
          shareHolder_Owns: Consigneeshareholder_owns,
          Shipper_Owners_name: BatchConsigneeRequest,
          Shipper_Owners_Address: ConsigneeShipper_Owners_Address,
          bankCheck: ConsigneeBankCheck,
          shipperFile: ConsigneeShipperFile,
          shipperDOB: ConsigneeShipperDoB,
          bankAddress: ConsigneeBankAdress,
          bankName: ConsigneeBankName,
          type: "nonexcel",
        };

        await fetch(`${API.BASE_URL}Batch_ScreningAPI`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          // mode: "cors",
          body: JSON.stringify({
            name: ConsigneeshipperData.map((item) => ({
              name: item,
            })),
            reference_number: Shipperreference
              ? Shipperreference
              : ConsigneeepounRefernceNumber
              ? ConsigneeepounRefernceNumber
              : EndUserserTypeRefernce
              ? EndUserserTypeRefernce
              : reference,
            check: [{ userType: userType }],

            userType: userType,
            repound: ConsigneeshipperData.map((item) => ({
              ShipperName: item,
            })),
            shipmentType: screeningType1,
            userId: data._id,
            shareHolder_Owns: Consigneeshareholder_owns,
            Shipper_Owners_name: BatchConsigneeRequest,
            Shipper_Owners_Address: ConsigneeShipper_Owners_Address,
            bankCheck: ConsigneeBankCheck,
            shipperFile: ConsigneeShipperFile,
            shipperDOB: ConsigneeShipperDoB,
            bankAddress: ConsigneeBankAdress,
            bankName: ConsigneeBankName,
          }),
        })
          .then((response) => response.json())
          .then(async (res) => {
            setconsigneeloader(false);
            apiResult = "1";
            setConsigneeSetbox("1");
            await localStorage.setItem(
              "BtachScreeningConsignee",
              JSON.stringify(payload)
            );
            localStorage.setItem("screeningcomplete", "false");
            await localStorage.setItem(
              "reference",
              JSON.stringify(
                Shipperreference
                  ? Shipperreference
                  : ConsigneeepounRefernceNumber
                  ? ConsigneeepounRefernceNumber
                  : EndUserserTypeRefernce
                  ? EndUserserTypeRefernce
                  : reference
              )
            );
            // localStorage.removeItem("hsCodeVerficationData");
            if (res.status === true) {
              if (userType == "Consignee") {
                await localStorage.setItem(
                  "ConsigneeDATA",
                  JSON.stringify(payload)
                );
              }

              var filteredArrayTable = res.finalData[0].shipperNamerisk.filter(
                (item) => ({
                  ShipperName: item.name,
                  risk: item.risk,
                })
              );

              await localStorage.setItem(
                "BtachScreeningConsigneeRiskDatas",
                JSON.stringify(filteredArrayTable)
              );

              setconsigneeshipperRiskData(filteredArrayTable);
              setConsigneeFirstScreeningrespone("1");

              setRPSScreeningConsignee(true);

              setConsigneeriskststus("1");

              toast("Risk found, You can proceed to next");
            } else if (res.status === false) {
              if (userType == "Consignee") {
                await localStorage.setItem(
                  "ConsigneeDATA",
                  JSON.stringify(payload)
                );
              }

              var filteredArray = res.finalData[0].repound
                .filter((item) => item.risk)
                .map((item) => item.risk);

              var filteredArrayTable = res.finalData[0].repound.filter(
                (item) => ({
                  ShipperName: item.ShipperName,
                  risk: item.risk,
                  status: item.status,
                })
              );

              // Updating state with filtered data

              await localStorage.setItem(
                "BtachScreeningConsigneeRiskDatas",
                JSON.stringify(filteredArrayTable)
              );

              const riskArray = [filteredArray];
              const repoundRealTimeTable = [filteredArrayTable];
              setrConsigneeepoundTable(repoundRealTimeTable);
              setConsigneerisk(riskArray);

              if (consigneeScreening == true) {
                if (!consigneeScreening)
                  setconsigneeScreening(consgineescreeningCheck.hitOne);
                if (consigneeScreening === consgineescreeningCheck.hitOne)
                  setconsigneeScreening(consgineescreeningCheck.hitSec);
              } else if (consigneeScreening == false) {
                if (!consigneeScreening)
                  setconsigneeScreening(consgineescreeningCheck.hitOne);
                if (consigneeScreening === consgineescreeningCheck.hitOne)
                  setconsigneeScreening(consgineescreeningCheck.hitSec);
              }

              setRPSScreeningConsignee(true);

              setSubmit(false);
            }
            if (
              res.data.status == true ||
              res.USA.status == true ||
              res.UK.status == true
            ) {
              if (BatchConsigneeRequest.length > 0) {
                var filteredArrayTable =
                  res.finalData[0].shipperNamerisk.filter((item) => ({
                    ShipperName: item.name,
                    risk: item.risk,
                  }));
                await localStorage.setItem(
                  "BtachScreeningConsigneeRiskData",
                  JSON.stringify(filteredArrayTable)
                );

                setconsigneeshipperRiskData(filteredArrayTable);
                setConsigneeFirstScreeningrespone("1");
                setRPSScreeningConsignee(true);

                setConsigneeriskststus("1");

                toast("Risk Found, You can proceed to next");
              } else {
                var filteredArray = res.finalData[0].repound
                  .filter((item) => item.risk)
                  .map((item) => item.risk);

                var filteredArrayTable = res.finalData[0].repound.filter(
                  (item) => ({
                    ShipperName: item.ShipperName,
                    risk: item.risk,
                    status: item.status,
                  })
                );
                setConsigneeriskststus("1");

                // Updating state with filtered data

                const riskArray = [filteredArray];
                const repoundRealTimeTable = [filteredArrayTable];
                await localStorage.setItem(
                  "BtachScreeningConsigneeRiskDatas",
                  JSON.stringify(filteredArrayTable)
                );

                setrConsigneeepoundTable(repoundRealTimeTable);
                setConsigneerisk(riskArray);
                if (consigneeScreening == true) {
                  if (!consigneeScreening)
                    setconsigneeScreening(consgineescreeningCheck.hitOne);
                  if (consigneeScreening === consgineescreeningCheck.hitOne)
                    setconsigneeScreening(consgineescreeningCheck.hitSec);
                } else if (consigneeScreening == false) {
                  if (!consigneeScreening)
                    setconsigneeScreening(consgineescreeningCheck.hitOne);
                  if (consigneeScreening === consgineescreeningCheck.hitOne)
                    setconsigneeScreening(consgineescreeningCheck.hitSec);
                }
                setConsigneeapiResponse("1");
                // setConsigneeapiResponse1("1");
                setRPSScreeningenduser(false);

                setRPSScreeningConsignee(false);

                // toast("Risk found");
              }
            }

            if (
              res.data.status == false &&
              res.USA.status == false &&
              res.UK.status == false
            ) {
              setRPSScreeningConsignee(true);

              setConsigneeshowRiskFeild("1");
              setConsigneeriskststus("0");

              var filteredArray = res.finalData[0].repound
                .filter((item) => item.risk)
                .map((item) => item.risk);

              var filteredArrayTable = res.finalData[0].repound.filter(
                (item) => ({
                  ShipperName: item.ShipperName,
                  risk: item.risk,
                  status: item.status,
                })
              );

              await localStorage.setItem(
                "BtachScreeningConsigneeRiskDatas",
                JSON.stringify(filteredArrayTable)
              );

              const riskArray = [filteredArray];
              const repoundRealTimeTable = [filteredArrayTable];
              setrConsigneeepoundTable(repoundRealTimeTable);
              setConsigneerisk(riskArray);

              if (consigneeScreening == true) {
                if (consigneeScreening)
                  setconsigneeScreening(consgineescreeningCheck.hitOne);
                if (consigneeScreening === consgineescreeningCheck.hitOne)
                  setconsigneeScreening(consgineescreeningCheck.hitSec);
              } else if (consigneeScreening == false) {
                if (!consigneeScreening)
                  setconsigneeScreening(consgineescreeningCheck.hitOne);
                if (consigneeScreening === consgineescreeningCheck.hitOne)
                  setconsigneeScreening(consgineescreeningCheck.hitSec);
              }

              setRPSScreeningConsignee(true);

              toast("No risk found, You can proceed to next");
            }

            if (
              res.status == "false" ||
              (res.data.status == "true" &&
                res.USA.status == "true" &&
                res.UK.status == "true")
            ) {
              setRPSScreeningConsignee(true);

              toast("No risk found, You can proceed to next");
            }
          })
          .catch((error) => {});
      }
    };

    const EndUserUserScreeningAPi = async () => {
      const hasEmptyField = BatchEndUserRequest.some((item) => {
        if (
          EndUserScreeningrespone === "1" &&
          (item.ShipperRisk === "" ||
            item.Shipper_Owners_Address === "" ||
            item.bankCheck === "" ||
            item.shipperOwn === "")
        ) {
          setEndUserloaders(false);

          toast("Please fill all required fields");
          return true; // Stop iterating when an empty field is found
        }
        if (EndUserScreeningrespone === "1" && endUserDoberr == "1") {
          setEndUserloaders(false);

          toast("Please fill all required fields");
          return true; // Stop iterating when an empty field is found
        }
        if (
          EndUserBankCheck === "yes" &&
          (item.bankAddress === "" || item.bankName === "")
        ) {
          setEndUserloaders(false);
          toast("Please fill all required fields");
          return true; // Stop iterating when an empty field is found
        }
        return false;
      });
      if (hasEmptyField) {
        // Stop further execution if an empty field is found
        return;
      }
      EndUsername &&
        EndUsername.map((item) => {
          if (item.length == 0) {
            setEndUsershipperSubmitError(true);
            return;
          }
        });

      setEndUserSubmit(true);

      onProfileValidation2();
      if (EndUserrrorCount > 0) return;

      if (EndUsername.length != 0) {
        setEndUserloaders(true);

        const userData = localStorage.getItem("userDetail");
        if (userData) {
          var data = JSON.parse(userData);
        }
        setEndUsershipperSubmitError(false);

        let payload = {
          name: EndUsershipperData.map((item) => ({
            name: item,
          })),
          reference_number: Shipperreference
            ? Shipperreference
            : ConsigneeepounRefernceNumber
            ? ConsigneeepounRefernceNumber
            : EndUserserTypeRefernce
            ? EndUserserTypeRefernce
            : reference,
          userType: userType,
          check: [{ userType: userType }],
          repound: EndUsershipperData.map((item) => ({
            ShipperName: item,
          })),
          shipmentType: EndUserscreeningType1,
          userId: data._id,
          shareHolder_Owns: EndUsershareholder_owns,
          Shipper_Owners_name: BatchEndUserRequest,
          Shipper_Owners_Address: EndUserShipper_Owners_Address,
          bankCheck: EndUserBankCheck,
          shipperFile: EndUserShipperFile,
          shipperDOB: EndUserShipperDoB,
          bankAddress: EndUserShipper_Owners_Address,
          bankName: EndUserBankName,
          type: "nonexcel",
        };

        await fetch(`${API.BASE_URL}Batch_ScreningAPI`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          // mode: "cors",
          body: JSON.stringify({
            name: EndUsershipperData.map((item) => ({
              name: item,
            })),
            reference_number: Shipperreference
              ? Shipperreference
              : ConsigneeepounRefernceNumber
              ? ConsigneeepounRefernceNumber
              : EndUserserTypeRefernce
              ? EndUserserTypeRefernce
              : reference,
            userType: userType,
            check: [{ userType: userType }],
            repound: EndUsershipperData.map((item) => ({
              ShipperName: item,
            })),
            shipmentType: EndUserscreeningType1,
            userId: data._id,
            shareHolder_Owns: EndUsershareholder_owns,
            Shipper_Owners_name: BatchEndUserRequest,
            Shipper_Owners_Address: EndUserShipper_Owners_Address,
            bankCheck: EndUserBankCheck,
            shipperFile: EndUserShipperFile,
            shipperDOB: EndUserShipperDoB,
            bankAddress: EndUserShipper_Owners_Address,
            bankName: EndUserBankName,
          }),
        })
          .then((response) => response.json())
          .then(async (res) => {
            apiResult = "1";
            setEndUserloaders(false);
            await localStorage.setItem(
              "BtachScreeningEnduser",
              JSON.stringify(payload)
            );
            localStorage.setItem("screeningcomplete", "false");
            await localStorage.setItem(
              "reference",
              JSON.stringify(
                Shipperreference
                  ? Shipperreference
                  : ConsigneeepounRefernceNumber
                  ? ConsigneeepounRefernceNumber
                  : EndUserserTypeRefernce
                  ? EndUserserTypeRefernce
                  : reference
              )
            );
            // localStorage.removeItem("hsCodeVerficationData");
            setEndUserSetbox("1");

            if (res.status === true) {
              if (userType == "Consignee") {
                await localStorage.setItem(
                  "ConsigneeDATA",
                  JSON.stringify(payload)
                );
              }

              var filteredArrayTable = res.finalData[0].shipperNamerisk.filter(
                (item) => ({
                  ShipperName: item.name,
                  risk: item.risk,
                })
              );

              setEndUsershipperRiskData(filteredArrayTable);

              await localStorage.setItem(
                "BtachScreeningEnduserRiskData",
                JSON.stringify(filteredArrayTable)
              );

              setEndUserScreeningrespone("1");
              setEndUserriskststus("1");
              setRPSScreening(true);

              toast("Risk found, You can proceed to next");
            } else if (res.status === false) {
              if (userType == "Consignee") {
                await localStorage.setItem(
                  "ConsigneeDATA",
                  JSON.stringify(payload)
                );
              }

              var filteredArray = res.finalData[0].repound
                .filter((item) => item.risk)
                .map((item) => item.risk);

              var filteredArrayTable = res.finalData[0].repound.filter(
                (item) => ({
                  ShipperName: item.ShipperName,
                  risk: item.risk,
                  status: item.status,
                })
              );

              // Updating state with filtered data

              const riskArray = [filteredArray];
              const repoundRealTimeTable = [filteredArrayTable];
              setEndUserpoundTable(repoundRealTimeTable);
              setEndUserrisk(riskArray);

              if (EndUserScreening == true) {
                if (!EndUserScreening)
                  setEndUserScreening(EndUserscreeningCheck.hitOne);
                if (EndUserScreening === EndUserscreeningCheck.hitOne)
                  setEndUserScreening(EndUserscreeningCheck.hitSec);
              } else if (EndUserScreening == false) {
                if (!EndUserScreening)
                  setEndUserScreening(EndUserscreeningCheck.hitOne);
                if (EndUserScreening === EndUserscreeningCheck.hitOne)
                  setEndUserScreening(EndUserscreeningCheck.hitSec);
              }

              setRPSScreening(true);

              setSubmit(false);
            }
            if (
              res.data.status == true ||
              res.USA.status == true ||
              res.UK.status == true
            ) {
              if (BatchEndUserRequest.length > 0) {
                var filteredArrayTable =
                  res.finalData[0].shipperNamerisk.filter((item) => ({
                    ShipperName: item.name,
                    risk: item.risk,
                  }));

                setEndUsershipperRiskData(filteredArrayTable);

                setEndUserScreeningrespone("1");
                setEndUserriskststus("1");
                setRPSScreening(true);

                toast("Risk Found, You can proceed to next");
              } else {
                var filteredArray = res.finalData[0].repound
                  .filter((item) => item.risk)
                  .map((item) => item.risk);

                var filteredArrayTable = res.finalData[0].repound.filter(
                  (item) => ({
                    ShipperName: item.ShipperName,
                    risk: item.risk,
                    status: item.status,
                  })
                );
                setEndUserriskststus("1");

                // Updating state with filtered data

                const riskArray = [filteredArray];
                const repoundRealTimeTable = [filteredArrayTable];
                await localStorage.setItem(
                  "BtachScreeningEnduserRiskData",
                  JSON.stringify(filteredArrayTable)
                );

                setEndUserpoundTable(repoundRealTimeTable);
                setEndUserrisk(riskArray);
                if (EndUserScreening == true) {
                  if (!EndUserScreening)
                    setEndUserScreening(EndUserscreeningCheck.hitOne);
                  if (EndUserScreening === EndUserscreeningCheck.hitOne)
                    setEndUserScreening(EndUserscreeningCheck.hitSec);
                } else if (EndUserScreening == false) {
                  if (!EndUserScreening)
                    setEndUserScreening(EndUserscreeningCheck.hitOne);
                  if (EndUserScreening === EndUserscreeningCheck.hitOne)
                    setEndUserScreening(EndUserscreeningCheck.hitSec);
                }
                setEndUserapiResponse("1");
                setRPSScreeningConsignee(false);
                setRPSScreening(false);
              }
            }

            if (
              res.data.status == false &&
              res.USA.status == false &&
              res.UK.status == false
            ) {
              setEndUsershowRiskFeild("1");
              setEndUserriskststus("0");

              var filteredArray = res.finalData[0].repound
                .filter((item) => item.risk)
                .map((item) => item.risk);

              var filteredArrayTable = res.finalData[0].repound.filter(
                (item) => ({
                  ShipperName: item.ShipperName,
                  risk: item.risk,
                  status: item.status,
                })
              );

              // Updating state with filtered data

              await localStorage.setItem(
                "BtachScreeningEndusernonRisk",
                JSON.stringify(filteredArrayTable)
              );

              const riskArray = [filteredArray];
              const repoundRealTimeTable = [filteredArrayTable];
              setEndUserpoundTable(repoundRealTimeTable);
              setEndUserrisk(riskArray);

              if (EndUserScreening == true) {
                if (!EndUserScreening)
                  setEndUserScreening(EndUserscreeningCheck.hitOne);
                if (EndUserScreening === EndUserscreeningCheck.hitOne)
                  setEndUserScreening(EndUserscreeningCheck.hitSec);
              } else if (EndUserScreening == false) {
                if (!EndUserScreening)
                  setEndUserScreening(EndUserscreeningCheck.hitOne);
                if (EndUserScreening === EndUserscreeningCheck.hitOne)
                  setEndUserScreening(EndUserscreeningCheck.hitSec);
              }

              setRPSScreening(true);
              toast("No risk found, You can proceed to next");
            }
            if (
              res.status == "false" ||
              (res.data.status == "true" &&
                res.USA.status == "true" &&
                res.UK.status == "true")
            ) {
              toast("No risk found, You can proceed to next");
            }

            // if (
            //   res.data.msg == "shipper No Risk found" &&
            //   res.USA.msg == "shipper No Risk found" &&
            //   res.UK.msg == "shipper No Risk found"
            // ) {

            //   toast("Submit successfully");

            // }
          })
          .catch((error) => {});
      }
    };

    const excelSheetScreeningAPi = async (file) => {
      // name.map((item)=>{
      // })

      setexcelUserSubmit(true);

      excelonProfileValidation();
      if (excelUserrrorCount > 0) return;

      const userData = localStorage.getItem("userDetail");
      if (userData) {
        var data = JSON.parse(userData);
      }
      let excelData = new FormData();

      excelData.append("attachments", file ? file : excelSelectedFile);
      excelData.append(
        "reference",
        Shipperreference
          ? Shipperreference
          : ConsigneeepounRefernceNumber
          ? ConsigneeepounRefernceNumber
          : EndUserserTypeRefernce
          ? EndUserserTypeRefernce
          : reference
      );
      excelData.append("userType", userType);
      excelData.append("check", [{ userType: userType }]);

      excelData.append("userId", data._id);

      excelData.append("shareHolder_Owns", shareholder_owns);

      excelData.append("shipmentType", screeningType1);
      excelData.append("Shipper_Owners_name", Shipper_Owner);

      excelData.append("Shipper_Owners_Address", Shipper_Owners_Address);

      excelData.append("bankCheck", BankCheck);
      excelData.append("shipperFile", ShipperFile);
      excelData.append("ShipperDoB", ShipperDoB);
      excelData.append("BankAdress", BankAdress);
      excelData.append("bankCheck", BankName);

      for (const pair of excelData.entries()) {
      }
      var requestOptions = {
        method: "POST",
        body: excelData,
      };

      const response = await fetch(
        `${API.BASE_URL}uploadExcelFile`,
        requestOptions
      )
        .then((response) => response.json())
        .then(async (res) => {
          apiResult = "1";
          await localStorage.setItem(
            "reference",
            JSON.stringify(
              Shipperreference
                ? Shipperreference
                : ConsigneeepounRefernceNumber
                ? ConsigneeepounRefernceNumber
                : EndUserserTypeRefernce
                ? EndUserserTypeRefernce
                : reference
            )
          );
          localStorage.setItem("screeningcomplete", "false");
          // localStorage.removeItem("hsCodeVerficationData");
          if (res.success == true) {
            // const shipperUserType = res.data.map((item)=> {
            //   return item.userType
            // } )

            let shipperData = [];
            let consigneeData = [];
            let endUserData = [];

            res.data.forEach((entry) => {
              if (entry.userType == "Shipper") {
                shipperData.push(entry);
              } else if (entry.userType == "Consignee") {
                consigneeData.push(entry);
              } else if (entry.userType == "End User") {
                endUserData.push(entry);
              }
            });

            if (shipperData.length > 0) {
              var filteredArray = shipperData[0].repound
                .filter((item) => item.risk)
                .map((item) => item.risk);
              var filteredArrayTable = shipperData[0].repound.filter(
                (item) => ({
                  ShipperName: item.ShipperName,
                  risk: item.risk,
                  status: item.status,
                })
              );

              await localStorage.setItem(
                "BtachScreeningShipperRiskDatas",
                JSON.stringify(filteredArrayTable)
              );

              const riskArray = [filteredArray];
              const repoundRealTimeTable = [filteredArrayTable];
              setrepoundTable(repoundRealTimeTable);
              const risks = riskArray.map((item) => item.risk);

              // Update the state with the risks array
              setrisk(riskArray);

              if (screening == true) {
                if (screening) setScreening(screeningCheck.hitOne);
                if (screening === screeningCheck.hitOne)
                  setScreening(screeningCheck.hitSec);
              } else if (screening == false) {
                if (!screening) setScreening(screeningCheck.hitOne);
                if (screening === screeningCheck.hitOne)
                  setScreening(screeningCheck.hitSec);
              }
              setriskststus("1");
              setapiResponse("1");
            }
            if (consigneeData.length > 0) {
              var filteredArray = consigneeData[0].repound
                .filter((item) => item.risk)
                .map((item) => item.risk);
              var filteredArrayTable = consigneeData[0].repound.filter(
                (item) => ({
                  ShipperName: item.ShipperName,
                  risk: item.risk,
                  status: item.status,
                })
              );

              // Updating state with filtered data

              await localStorage.setItem(
                "BtachScreeningConsigneeRiskDatas",
                JSON.stringify(filteredArrayTable)
              );

              const riskArray = [filteredArray];
              const repoundRealTimeTable = [filteredArrayTable];
              setrConsigneeepoundTable(repoundRealTimeTable);
              setConsigneerisk(riskArray);

              if (consigneeScreening == true) {
                if (!consigneeScreening)
                  setconsigneeScreening(consgineescreeningCheck.hitOne);
                if (consigneeScreening === consgineescreeningCheck.hitOne)
                  setconsigneeScreening(consgineescreeningCheck.hitSec);
              } else if (consigneeScreening == false) {
                if (!consigneeScreening)
                  setconsigneeScreening(consgineescreeningCheck.hitOne);
                if (consigneeScreening === consgineescreeningCheck.hitOne)
                  setconsigneeScreening(consgineescreeningCheck.hitSec);
              }
              setConsigneeapiResponse("1");

              setConsigneeriskststus("1");
            }
            if (endUserData.length > 0) {
              var filteredArray = endUserData[0].repound
                .filter((item) => item.risk)
                .map((item) => item.risk);
              var filteredArrayTable = endUserData[0].repound.filter(
                (item) => ({
                  ShipperName: item.ShipperName,
                  risk: item.risk,
                  status: item.status,
                })
              );

              // Updating state with filtered data

              const riskArray = [filteredArray];
              const repoundRealTimeTable = [filteredArrayTable];
              setEndUserpoundTable(repoundRealTimeTable);
              setEndUserrisk(riskArray);

              await localStorage.setItem(
                "BtachScreeningEnduserRiskData",
                JSON.stringify(filteredArrayTable)
              );
              if (EndUserScreening == true) {
                if (!EndUserScreening)
                  setEndUserScreening(EndUserscreeningCheck.hitOne);
                if (EndUserScreening === EndUserscreeningCheck.hitOne)
                  setEndUserScreening(EndUserscreeningCheck.hitSec);
              } else if (EndUserScreening == false) {
                if (!EndUserScreening)
                  setEndUserScreening(EndUserscreeningCheck.hitOne);
                if (EndUserScreening === EndUserscreeningCheck.hitOne)
                  setEndUserScreening(EndUserscreeningCheck.hitSec);
              }
              setEndUserriskststus("1");
              setEndUserapiResponse("1");
            }
          }
        })
        .catch((error) => {});
    };

    const excelSheetShipperScreeningAPi = async () => {
      const hasEmptyField = BatchScreeningRequest.some((item) => {
        if (
          apiResponse === "1" &&
          (item.ShipperRisk === "" ||
            item.Shipper_Owners_Address === "" ||
            item.bankCheck === "" ||
            item.shipperOwn === "")
        ) {
          toast("Please fill all required fields");
          return true;
        }
        if (FirstScreeningrespone === "1" && shipperDoberr === "1") {
          toast("Please fill all required fields");
          return true;
        }
        if (
          BankCheck === "yes" &&
          (item.bankAddress === "" || item.bankName === "")
        ) {
          toast("Please fill all required fields");
          return true;
        }
        return false;
      });

      if (hasEmptyField) {
        return;
      }

      setSubmit(true);
      setshipperloader(true);

      excelonProfileValidation();
      if (excelUserrrorCount > 0) return;

      const userData = localStorage.getItem("userDetail");
      if (userData) {
        var data = JSON.parse(userData);
      }
      setshipperSubmitError(false);

      let payload = {
        name: shipperData.map((item) => ({
          name: item,
        })),
        reference_number: Shipperreference
          ? Shipperreference
          : ConsigneeepounRefernceNumber
          ? ConsigneeepounRefernceNumber
          : EndUserserTypeRefernce
          ? EndUserserTypeRefernce
          : reference,
        // check: [{ userType: userType }],
        userType: ["Shipper"],
        repound: shipperData.map((item) => ({
          ShipperName: item,
        })),
        shipmentType: screeningType1,
        userId: data._id,
        shareHolder_Owns: shareholder_owns,
        Shipper_Owners_name: BatchScreeningRequest,
        Shipper_Owners_Address: Shipper_Owners_Address,
        bankCheck: BankCheck,
        shipperFile: ShipperFile,
        shipperDOB: ShipperDoB,
        bankAddress: BankAdress,
        bankName: BankName,
        type: "excel",
      };

      await fetch(`${API.BASE_URL}updateExcelShipperData`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        // mode: "cors",
        body: JSON.stringify({
          name: shipperData.map((item) => ({
            name: item,
          })),
          reference_number: Shipperreference
            ? Shipperreference
            : ConsigneeepounRefernceNumber
            ? ConsigneeepounRefernceNumber
            : EndUserserTypeRefernce
            ? EndUserserTypeRefernce
            : reference,
          // check: [{ userType: userType }],
          userType: ["Shipper"],
          repound: shipperData.map((item) => ({
            ShipperName: item,
          })),
          shipmentType: screeningType1,
          userId: data._id,
          shareHolder_Owns: shareholder_owns,
          Shipper_Owners_name: BatchScreeningRequest,
          Shipper_Owners_Address: Shipper_Owners_Address,
          bankCheck: BankCheck,
          shipperFile: ShipperFile,
          shipperDOB: ShipperDoB,
          bankAddress: BankAdress,
          bankName: BankName,
        }),
      })
        .then((response) => response.json())
        .then(async (res) => {
          apiResult = "1";
          setshipperloader(false);
          // localStorage.removeItem("hsCodeVerficationData");
          setSetbox("1");
          await localStorage.setItem(
            "BtachScreeningShipper",
            JSON.stringify(payload)
          );
          await localStorage.setItem(
            "reference",
            JSON.stringify(
              Shipperreference
                ? Shipperreference
                : ConsigneeepounRefernceNumber
                ? ConsigneeepounRefernceNumber
                : EndUserserTypeRefernce
                ? EndUserserTypeRefernce
                : reference
            )
          );
          localStorage.setItem("screeningcomplete", "false");

          if (res.status == true) {
            const filteredArrayTable = res.data[0].Owners_name.filter(
              (item) => item.risk === "yes"
            );

            setshipperRiskData(filteredArrayTable);
            setriskststus("1");
            setFirstScreeningrespone("1");
            toast("No risk found, You can proceed to next");

            setRPSScreening(true);
          }
          if (res.status == "true") {
            toast("No risk found, You can proceed to next");

            setRPSScreening(true);
          }
        })
        .catch((error) => {});
    };

    const excelSheetConsigneeScreeningAPi = async () => {
      const hasEmptyField = BatchScreeningRequest.some((item) => {
        if (
          apiResponse === "1" &&
          (item.ShipperRisk === "" ||
            item.Shipper_Owners_Address === "" ||
            item.bankCheck === "" ||
            item.shipperOwn === "")
        ) {
          toast("Please fill all required fields");
          return true;
        }
        if (FirstScreeningrespone === "1" && shipperDoberr === "1") {
          toast("Please fill all required fields");
          return true;
        }
        if (
          BankCheck === "yes" &&
          (item.bankAddress === "" || item.bankName === "")
        ) {
          toast("Please fill all required fields");
          return true;
        }
        return false;
      });

      if (hasEmptyField) {
        return;
      }

      setSubmit(true);
      setshipperloader(true);

      excelonProfileValidation();
      if (excelUserrrorCount > 0) return;

      const userData = localStorage.getItem("userDetail");
      if (userData) {
        var data = JSON.parse(userData);
      }
      setshipperSubmitError(false);

      let payload = {
        name: shipperData.map((item) => ({
          name: item,
        })),
        reference_number: Shipperreference
          ? Shipperreference
          : ConsigneeepounRefernceNumber
          ? ConsigneeepounRefernceNumber
          : EndUserserTypeRefernce
          ? EndUserserTypeRefernce
          : reference,
        // check: [{ userType: userType }],
        userType: ["Consignee"],
        repound: shipperData.map((item) => ({
          ShipperName: item,
        })),
        shipmentType: screeningType1,
        userId: data._id,
        shareHolder_Owns: shareholder_owns,
        Shipper_Owners_name: BatchConsigneeRequest,
        Shipper_Owners_Address: Shipper_Owners_Address,
        bankCheck: BankCheck,
        shipperFile: ShipperFile,
        shipperDOB: ShipperDoB,
        bankAddress: BankAdress,
        bankName: BankName,
        type: "excel",
      };

      await fetch(`${API.BASE_URL}updateExcelShipperData`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        // mode: "cors",
        body: JSON.stringify({
          name: shipperData.map((item) => ({
            name: item,
          })),
          reference_number: Shipperreference
            ? Shipperreference
            : ConsigneeepounRefernceNumber
            ? ConsigneeepounRefernceNumber
            : EndUserserTypeRefernce
            ? EndUserserTypeRefernce
            : reference,
          // check: [{ userType: userType }],
          userType: ["Consignee"],
          repound: shipperData.map((item) => ({
            ShipperName: item,
          })),
          shipmentType: screeningType1,
          userId: data._id,
          shareHolder_Owns: shareholder_owns,
          Shipper_Owners_name: BatchConsigneeRequest,
          Shipper_Owners_Address: Shipper_Owners_Address,
          bankCheck: BankCheck,
          shipperFile: ShipperFile,
          shipperDOB: ShipperDoB,
          bankAddress: BankAdress,
          bankName: BankName,
        }),
      })
        .then((response) => response.json())
        .then(async (res) => {
          apiResult = "1";
          setshipperloader(false);
          await localStorage.setItem(
            "BtachScreeningConsignee",
            JSON.stringify(payload)
          );
          // localStorage.removeItem("hsCodeVerficationData");
          setSetbox("1");

          await localStorage.setItem(
            "reference",
            JSON.stringify(
              Shipperreference
                ? Shipperreference
                : ConsigneeepounRefernceNumber
                ? ConsigneeepounRefernceNumber
                : EndUserserTypeRefernce
                ? EndUserserTypeRefernce
                : reference
            )
          );
          localStorage.setItem("screeningcomplete", "false");

          if (res.status == true) {
            const filteredArrayTable = res.data[0].Owners_name.filter(
              (item) => item.risk === "yes"
            );

            setconsigneeshipperRiskData(filteredArrayTable);
            setConsigneeFirstScreeningrespone("1");

            setConsigneeriskststus("1");

            toast("No risk found, You can proceed to next");

            setRPSScreening(true);
          }
          if (res.status == "true") {
            toast("No risk found, You can proceed to next");
            setRPSScreening(true);
          }
        })
        .catch((error) => {});
    };

    const excelSheetEndUserScreeningAPi = async () => {
      const hasEmptyField = BatchScreeningRequest.some((item) => {
        if (
          apiResponse === "1" &&
          (item.ShipperRisk === "" ||
            item.Shipper_Owners_Address === "" ||
            item.bankCheck === "" ||
            item.shipperOwn === "")
        ) {
          toast("Please fill all required fields");
          return true;
        }
        if (FirstScreeningrespone === "1" && shipperDoberr === "1") {
          toast("Please fill all required fields");
          return true;
        }
        if (
          BankCheck === "yes" &&
          (item.bankAddress === "" || item.bankName === "")
        ) {
          toast("Please fill all required fields");
          return true;
        }
        return false;
      });

      if (hasEmptyField) {
        return;
      }

      setSubmit(true);
      setshipperloader(true);

      excelonProfileValidation();
      if (excelUserrrorCount > 0) return;

      const userData = localStorage.getItem("userDetail");
      if (userData) {
        var data = JSON.parse(userData);
      }
      setshipperSubmitError(false);

      let payload = {
        name: shipperData.map((item) => ({
          name: item,
        })),
        reference_number: Shipperreference
          ? Shipperreference
          : ConsigneeepounRefernceNumber
          ? ConsigneeepounRefernceNumber
          : EndUserserTypeRefernce
          ? EndUserserTypeRefernce
          : reference,
        // check: [{ userType: userType }],
        userType: ["End User"],
        repound: shipperData.map((item) => ({
          ShipperName: item,
        })),
        shipmentType: screeningType1,
        userId: data._id,
        shareHolder_Owns: shareholder_owns,
        Shipper_Owners_name: BatchEndUserRequest,
        Shipper_Owners_Address: Shipper_Owners_Address,
        bankCheck: BankCheck,
        shipperFile: ShipperFile,
        shipperDOB: ShipperDoB,
        bankAddress: BankAdress,
        bankName: BankName,
        type: "excel",
      };
      await fetch(`${API.BASE_URL}updateExcelShipperData`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        // mode: "cors",
        body: JSON.stringify({
          name: shipperData.map((item) => ({
            name: item,
          })),
          reference_number: Shipperreference
            ? Shipperreference
            : ConsigneeepounRefernceNumber
            ? ConsigneeepounRefernceNumber
            : EndUserserTypeRefernce
            ? EndUserserTypeRefernce
            : reference,
          // check: [{ userType: userType }],
          userType: ["End User"],
          repound: shipperData.map((item) => ({
            ShipperName: item,
          })),
          shipmentType: screeningType1,
          userId: data._id,
          shareHolder_Owns: shareholder_owns,
          Shipper_Owners_name: BatchEndUserRequest,
          Shipper_Owners_Address: Shipper_Owners_Address,
          bankCheck: BankCheck,
          shipperFile: ShipperFile,
          shipperDOB: ShipperDoB,
          bankAddress: BankAdress,
          bankName: BankName,
        }),
      })
        .then((response) => response.json())
        .then(async (res) => {
          apiResult = "1";
          setshipperloader(false);

          setSetbox("1");
          await localStorage.setItem(
            "BtachScreeningEnduser",
            JSON.stringify(payload)
          );
          localStorage.removeItem("hsCodeVerficationData");
          await localStorage.setItem("reference", JSON.stringify(reference));

          if (res.status == true) {
            const filteredArrayTable = res.data[0].Owners_name.filter(
              (item) => item.risk === "yes"
            );

            setEndUsershipperRiskData(filteredArrayTable);

            setEndUserScreeningrespone("1");
            setEndUserriskststus("1");

            toast("Submit successfully,You can proceed to next");
            setRPSScreening(true);
          }
          if (res.status == "true") {
            toast("Submit successfully,You can proceed to next");
            setRPSScreening(true);
          }
        })
        .catch((error) => {});
    };

    const handleDownload = () => {
      // Create a temporary link element
      const link = document.createElement("a");
      link.href = `${API.BASE_URL}server/data/accelBackend/DownlaodBatchScreeningSample.xlsx`;

      // Set the desired file name here
      const fileName = "DownlaodBatchScreeningSample.xlsx";

      // Set the download attribute to the desired file name
      link.setAttribute("download", fileName);

      // Append the link to the body
      document.body.appendChild(link);

      // Trigger the click event on the link
      link.click();

      // Clean up: remove the link from the DOM
      document.body.removeChild(link);
    };

    const getsearchaPI = async (indexs) => {
      setseachingLoader((prevStates) => ({
        ...prevStates,
        [indexs]: true,
      }));
      let payload = {
        name: ShipperName,
      };

      await fetch(`${API.BASE_URL}SearchAPI`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        // mode: "cors",
        body: JSON.stringify({
          name: [{ name: shipperData[indexs] }],
        }),
      })
        .then((response) => response.json())
        .then(async (res) => {
          setseachingLoader((prevStates) => ({
            ...prevStates,
            [indexs]: false,
          }));
          if (res.status == true && res.nameArray.length > 0) {
            const jsonString = JSON.stringify(res.nameArray);
            await localStorage.setItem("seacrhApi", jsonString);

            const filteredData = res.nameArray.filter((item) => {
              // Assuming you want to filter based on the 'Name' property of each item
              return item.toLowerCase().includes(ShipperName.toLowerCase());
            });
            setShowNameModal(true);
            setusergetSearchApi(filteredData);
            // setgetSearchApi(filteredCarrierList);
          }
          //  setgetSearchApi(res)
        })
        .catch((error) => {});
    };
    const consigneegetsearchaPI = async (indexs) => {
      setconsigneeseachingLoader((prevStates) => ({
        ...prevStates,
        [indexs]: true,
      }));
      await fetch(`${API.BASE_URL}SearchAPI`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        // mode: "cors",
        body: JSON.stringify({
          name: [{ name: ConsigneeshipperData[indexs] }],
        }),
      })
        .then((response) => response.json())
        .then(async (res) => {
          setconsigneeseachingLoader((prevStates) => ({
            ...prevStates,
            [indexs]: false,
          }));
          if (res.status == true && res.nameArray.length > 0) {
            const jsonString = JSON.stringify(res.nameArray);
            const jsonArray = JSON.parse(jsonString);

            // Store the original JSON string in local storage
            await localStorage.setItem("searchApi", jsonString);

            // Assuming 'res' is an array of objects and you want to filter based on a property called 'Name'

            // Show the modal and set the filtered data in state
            setShowNameModal1(true);
            setconsigneegetSearchApi(jsonArray);
            // setgetSearchApi(filteredCarrierList);
          }
          //  setgetSearchApi(res)
        })
        .catch((error) => {});
    };

    const EndusergetsearchaPI = async (indexs) => {
      setEndseachingLoader((prevStates) => ({
        ...prevStates,
        [indexs]: true,
      }));

      await fetch(`${API.BASE_URL}SearchAPI`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        // mode: "cors",
        body: JSON.stringify({
          name: [{ name: EndUsershipperData[indexs] }],
        }),
      })
        .then((response) => response.json())
        .then(async (res) => {
          setEndseachingLoader((prevStates) => ({
            ...prevStates,
            [indexs]: false,
          }));
          if (res.status == true && res.nameArray.length > 0) {
            const jsonString = JSON.stringify(res.nameArray);
            const jsonArray = JSON.parse(jsonString);

            await localStorage.setItem("seacrhApi", jsonString);

            setShowNameModal2(true);
            setcendUsergetSearchApi(jsonArray);
            // setgetSearchApi(filteredCarrierList);
          }
          //  setgetSearchApi(res)
        })
        .catch((error) => {});
    };

    const hadleSelectedtName = (item, index) => {
      const old_arr = shipperData;
      old_arr[index] = item;
      setShipper_Name([...old_arr]); // Update the state with the updated array
      setShowNameModal(false);
      // Close the modal after selection
    };

    const handleInputChanges = (value, index) => {
      setShowNameModalIndex(index);
      const old_arr = shipperData;
      old_arr[index] = value;
      setShipper_Name([...old_arr]);
      setShowNameModal(false);
    };

    const consigneehandleInputChanges = (value, index) => {
      setShowNameModalIndex1(index);
      const old_arr = ConsigneeshipperData;
      old_arr[index] = value;
      setConsigneeShipper_Name([...old_arr]);
      setShowNameModal1(false);
    };
    const enduserhandleInputChanges = (value, index) => {
      setShowNameModalIndex2(index);
      const old_arr = EndUsershipperData;
      old_arr[index] = value;
      setEndUserShipper_Name([...old_arr]);
      setShowNameModal2(false);

      // const updatedShipperData = [...shipperData]; // Create a copy of the shipperData array
      // updatedShipperData[index] = value; // Update the value at the specified index
      // setShipper_Name(updatedShipperData); // Update the state with the updated array
    };

    const consigneehadleSelectedtName = (item, index) => {
      const old_arr = ConsigneeshipperData;
      old_arr[index] = item;
      setConsigneeShipper_Name([...old_arr]);
      setShowNameModal1(false);
      // Close the modal after selection
    };

    const userhadleSelectedtName = (item, index) => {
      const old_arr = EndUsershipperData;
      old_arr[index] = item;
      setEndUserShipper_Name([...old_arr]);
      setShowNameModal2(false);
    };

    const getRefernceNo = async () => {
      const userData = localStorage.getItem("userDetail");

      if (userData) {
        var data = JSON.parse(userData);
      }

      await fetch(`${API.BASE_URL}generateReferenceNumber`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        // mode: "cors",
        body: JSON.stringify({
          userId: data._id,
        }),
      })
        .then((response) => response.json())
        .then(async (res) => {


          

          
          setreference(res.referenceNo);
        })
        .catch((error) => {});
    };

    useEffect(() => {
      getRefernceNo();
    }, []);

    useEffect(() => {
      var EndUserData = JSON.parse(
        localStorage.getItem("BtachScreeningEnduser")
      );

      const EndUserDataRisk = JSON.parse(
        localStorage.getItem("BtachScreeningEnduserRiskData")
      );

      const ConsigneeData = JSON.parse(
        localStorage.getItem("BtachScreeningConsignee")
      );
      const ConsigneeRiskData = JSON.parse(
        localStorage.getItem("BtachScreeningConsigneeRiskDatas")
      );

      const shipperData = JSON.parse(
        localStorage.getItem("BtachScreeningShipper")
      );

      const ShipperRiskDatas = JSON.parse(
        localStorage.getItem("BtachScreeningShipperRiskDatas")
      );

      if (EndUserData) {
        const names = EndUserData.name.map((item) => item.name);
        setEndUserShipperData(names);
        // setreference(EndUserData.reference_number);
        // setEndUserserTypeRefernce(EndUserData.reference_number)
        setEndUserserTypeRefernce(EndUserData.reference_number);

        if (EndUserData.type == "excel") {
          setexcelSelectedFile("abc");
        } else {
          setEndUserUserType(EndUserData.userType);
          setEndUserValue(EndUserData.userType);
        }

         localStorage.setItem(
          "reference",
          JSON.stringify(EndUserData.reference_number)
        );
        if (EndUserDataRisk) {
          setEndUserriskststus("1");

          const risks = EndUserDataRisk.map((item) => item.risk);

          // Update the state with the risks array
          setEndUserrisk(risks);

          setEndUserpoundTable([EndUserDataRisk]);
          if (EndUserScreening == true) {
            if (!EndUserScreening)
              setEndUserScreening(EndUserscreeningCheck.hitOne);
            if (EndUserScreening === EndUserscreeningCheck.hitOne)
              setEndUserScreening(EndUserscreeningCheck.hitSec);
          } else if (EndUserScreening == false) {
            if (!EndUserScreening)
              setEndUserScreening(EndUserscreeningCheck.hitOne);
            if (EndUserScreening === EndUserscreeningCheck.hitOne)
              setEndUserScreening(EndUserscreeningCheck.hitSec);
          }

          setEndUserScreeningrespone("1");
          setEndUsershipperRiskData(EndUserDataRisk);

          const setBatchEndUserRequestss = (newShipperOwners) => {
            setBatchEndUserRequest((prevData) => {
              // Ensure newShipperOwners is an array and filter out empty objects
              const filteredNewShipperOwners = Array.isArray(newShipperOwners)
                ? newShipperOwners.filter((obj) => Object.keys(obj).length > 0)
                : [];

              return [...prevData, ...filteredNewShipperOwners];
            });
          };

          // Usage example:
          const newShipperOwners = EndUserData.Shipper_Owners_name; // Assuming this is an array of objects

          setBatchEndUserRequestss(newShipperOwners);

          const setEndUseryesStatusIndicess = (newShipperOwners) => {
            // Find indices where bankChecsk is "Yes" in the newShipperOwners array
            const yesStatusIndices = newShipperOwners.reduce(
              (indices, item, index) => {
                if (item.bankCheck === "Yes") {
                  indices.push(index);
                }
                return indices;
              },
              []
            );

            // Update the state with the yesStatusIndices array directly
            setEndUseryesStatusIndices(yesStatusIndices); // Updated function name

            // Assuming you have a state setter function setEndUserYesStatusIndices to update the state
          };

          // Usage example

          setEndUseryesStatusIndicess(newShipperOwners);
        }
      }

      if (ConsigneeData) {
        const names = ConsigneeData.name.map((item) => item.name);
        setConsigneeShipperData(names);
        // setreference(ConsigneeData.reference_number);
        // setEndUserserTypeRefernce(EndUserData.reference_number)
        setrConsigneeepounRefernceNumber(ConsigneeData.reference_number);
         localStorage.setItem(
          "reference",
          JSON.stringify(ConsigneeData.reference_number)
        );

        if (ConsigneeData.type == "excel") {
          setexcelSelectedFile("abc");
        } else {
          setConsigneeUserType(ConsigneeData.userType);
          setconsigneeValue(ConsigneeData.userType);
        }

        if (ConsigneeRiskData) {
          setConsigneeriskststus("1");

          const risks = ConsigneeRiskData.map((item) => item.risk);

          // Update the state with the risks array
          setConsigneerisk(risks);

          setrConsigneeepoundTable([ConsigneeRiskData]);
          if (consigneeScreening == true) {
            if (!consigneeScreening)
              setconsigneeScreening(consgineescreeningCheck.hitOne);
            if (consigneeScreening === consgineescreeningCheck.hitOne)
              setconsigneeScreening(consgineescreeningCheck.hitSec);
          } else if (consigneeScreening == false) {
            if (!consigneeScreening)
              setconsigneeScreening(consgineescreeningCheck.hitOne);
            if (consigneeScreening === consgineescreeningCheck.hitOne)
              setconsigneeScreening(consgineescreeningCheck.hitSec);
          }

          setConsigneeFirstScreeningrespone("1");
          setconsigneeshipperRiskData(ConsigneeRiskData);

          const setBatchEndUserRequestss = (newShipperOwners) => {
            setBatchConsigneeRequest((prevData) => {
              // Ensure newShipperOwners is an array and filter out empty objects
              const filteredNewShipperOwners = Array.isArray(newShipperOwners)
                ? newShipperOwners.filter((obj) => Object.keys(obj).length > 0)
                : [];

              return [...prevData, ...filteredNewShipperOwners];
            });
          };

          // Usage example:
          const newShipperOwners = ConsigneeData.Shipper_Owners_name; // Assuming this is an array of objects

          setBatchEndUserRequestss(newShipperOwners);

          const setConsigneeyesStatusIndicess = (newShipperOwners) => {
            // Find indices where bankChecsk is "Yes" in the newShipperOwners array
            const yesStatusIndices = newShipperOwners.reduce(
              (indices, item, index) => {
                if (item.bankCheck === "Yes") {
                  indices.push(index);
                }
                return indices;
              },
              []
            );

            // Update the state with the yesStatusIndices array directly
            setConsigneeyesStatusIndices(yesStatusIndices); // Updated function name

            // Assuming you have a state setter function setEndUserYesStatusIndices to update the state
          };

          // Usage example

          setConsigneeyesStatusIndicess(newShipperOwners);
        }

        if (ConsigneeRiskData) {
          setConsigneeriskststus("1");

          setrConsigneeepoundTable([ConsigneeRiskData]);
          if (consigneeScreening == true) {
            if (!consigneeScreening)
              setconsigneeScreening(consgineescreeningCheck.hitOne);
            if (consigneeScreening === consgineescreeningCheck.hitOne)
              setconsigneeScreening(consgineescreeningCheck.hitSec);
          } else if (consigneeScreening == false) {
            if (!consigneeScreening)
              setconsigneeScreening(consgineescreeningCheck.hitOne);
            if (consigneeScreening === consgineescreeningCheck.hitOne)
              setconsigneeScreening(consgineescreeningCheck.hitSec);
          }

          setConsigneeFirstScreeningrespone("1");
          setconsigneeshipperRiskData(ConsigneeRiskData);

          const setBatchEndUserRequestss = (newShipperOwners) => {
            setBatchConsigneeRequest((prevData) => {
              // Ensure newShipperOwners is an array and filter out empty objects
              const filteredNewShipperOwners = Array.isArray(newShipperOwners)
                ? newShipperOwners.filter((obj) => Object.keys(obj).length > 0)
                : [];

              return [...prevData, ...filteredNewShipperOwners];
            });
          };

          // Usage example:
          const newShipperOwners = ConsigneeData.Shipper_Owners_name; // Assuming this is an array of objects

          setBatchEndUserRequestss(newShipperOwners);

          const setConsigneeyesStatusIndicess = (newShipperOwners) => {
            // Find indices where bankChecsk is "Yes" in the newShipperOwners array
            const yesStatusIndices = newShipperOwners.reduce(
              (indices, item, index) => {
                if (item.bankCheck === "Yes") {
                  indices.push(index);
                }
                return indices;
              },
              []
            );

            // Update the state with the yesStatusIndices array directly
            setConsigneeyesStatusIndices(yesStatusIndices); // Updated function name

            // Assuming you have a state setter function setEndUserYesStatusIndices to update the state
          };

          // Usage example

          setConsigneeyesStatusIndicess(newShipperOwners);
        }
      }

      if (shipperData) {
        const names = shipperData.name.map((item) => item.name);
        setShipperData(names);
        // setreference(shipperData.reference_number);
        setrShipperreference(shipperData.reference_number);
         localStorage.setItem(
          "reference",
          JSON.stringify(shipperData.reference_number)
        );
        if (shipperData.type == "excel") {
          setexcelSelectedFile("abc");
        } else {
          setUserType(shipperData.userType);
          setValue(shipperData.userType);
        }

        if (ShipperRiskDatas) {
          setriskststus("1");

          setrepoundTable([ConsigneeRiskData]);
          if (screening == true) {
            if (screening) setScreening(screeningCheck.hitOne);
            if (screening === screeningCheck.hitOne)
              setScreening(screeningCheck.hitSec);
          } else if (screening == false) {
            if (!screening) setScreening(screeningCheck.hitOne);
            if (screening === screeningCheck.hitOne)
              setScreening(screeningCheck.hitSec);
          }

          const risks = ShipperRiskDatas.map((item) => item.risk);

          // Update the state with the risks array
          setrisk(risks);

          setFirstScreeningrespone("1");
          setshipperRiskData(ShipperRiskDatas);

          const setBatchEndUserRequestss = (newShipperOwners) => {
            setBatchScreeningRequest((prevData) => {
              // Ensure newShipperOwners is an array and filter out empty objects
              const filteredNewShipperOwners = Array.isArray(newShipperOwners)
                ? newShipperOwners.filter((obj) => Object.keys(obj).length > 0)
                : [];

              return [...prevData, ...filteredNewShipperOwners];
            });
          };

          // Usage example:
          const newShipperOwners = shipperData.Shipper_Owners_name; // Assuming this is an array of objects

          setBatchEndUserRequestss(newShipperOwners);

          const setConsigneeyesStatusIndicess = (newShipperOwners) => {
            // Find indices where bankChecsk is "Yes" in the newShipperOwners array
            const yesStatusIndices = newShipperOwners.reduce(
              (indices, item, index) => {
                if (item.bankCheck === "Yes") {
                  indices.push(index);
                }
                return indices;
              },
              []
            );

            // Update the state with the yesStatusIndices array directly
            setYesStatusIndices(yesStatusIndices); // Updated function name

            // Assuming you have a state setter function setEndUserYesStatusIndices to update the state
          };

          // Usage example

          setConsigneeyesStatusIndicess(newShipperOwners);
        }

        if (ShipperRiskDatas) {
          setriskststus("1");

          setrepoundTable([ShipperRiskDatas]);
          if (screening == true) {
            if (screening) setScreening(screeningCheck.hitOne);
            if (screening === screeningCheck.hitOne)
              setScreening(screeningCheck.hitSec);
          } else if (screening == false) {
            if (!screening) setScreening(screeningCheck.hitOne);
            if (screening === screeningCheck.hitOne)
              setScreening(screeningCheck.hitSec);
          }

          setFirstScreeningrespone("1");
          setshipperRiskData(ShipperRiskDatas);

          const setBatchEndUserRequestss = (newShipperOwners) => {
            setBatchScreeningRequest((prevData) => {
              // Ensure newShipperOwners is an array and filter out empty objects
              const filteredNewShipperOwners = Array.isArray(newShipperOwners)
                ? newShipperOwners.filter((obj) => Object.keys(obj).length > 0)
                : [];

              return [...prevData, ...filteredNewShipperOwners];
            });
          };

          // Usage example:
          const newShipperOwners = shipperData.Shipper_Owners_name; // Assuming this is an array of objects

          setBatchEndUserRequestss(newShipperOwners);

          const setConsigneeyesStatusIndicess = (newShipperOwners) => {
            // Find indices where bankChecsk is "Yes" in the newShipperOwners array
            const yesStatusIndices = newShipperOwners.reduce(
              (indices, item, index) => {
                if (item.bankCheck === "Yes") {
                  indices.push(index);
                }
                return indices;
              },
              []
            );

            // Update the state with the yesStatusIndices array directly
            setYesStatusIndices(yesStatusIndices); // Updated function name

            // Assuming you have a state setter function setEndUserYesStatusIndices to update the state
          };

          // Usage example

          setConsigneeyesStatusIndicess(newShipperOwners);
        }
      }
    }, []);

    useEffect(() => {
      if (ShipperName === "") {
        setShowNameModal(false);
      }
      if (Consigneename === "") {
        setShowNameModal1(false);
      }

      if (EndUsername === "") {
        setShowNameModal2(false);
      }
    }, [ShipperName, Consigneename, EndUsername]);

    useEffect(() => {
      onProfileValidation();
    }, [
      reference,
      userType,
      ShipperName,
      shareholder_owns,
      Shipper_Owner,
      Shipper_Owners_Address,
      ShipperDoB,
      ShipperFile,
      BankCheck,
      BankName,
      BankAdress,
    ]);

    useEffect(() => {
      onProfileValidation1();
    }, [
      reference,
      userType,
      ConsigneeshipperData,
      ConsigneescreeningType1,
      Consigneeshareholder_owns,
      ConsigneeShipper_Owner,
      ConsigneeShipper_Owners_Address,
      ConsigneeBankCheck,
      ConsigneeShipperFile,
      ConsigneeShipperDoB,
      ConsigneeBankAdress,
      ConsigneeBankName,
    ]);

    useEffect(() => {
      excelonProfileValidation();
    }, [
      reference,
      ConsigneeshipperData,
      ConsigneescreeningType1,
      Consigneeshareholder_owns,
      ConsigneeShipper_Owner,
      ConsigneeShipper_Owners_Address,
      ConsigneeBankCheck,
      ConsigneeShipperFile,
      ConsigneeShipperDoB,
      ConsigneeBankAdress,
      ConsigneeBankName,
    ]);
    useEffect(() => {
      localStorage.removeItem("screeningId");
    }, []);
    useEffect(() => {
      const shipperData = JSON.parse(
        localStorage.getItem("BtachScreeningShipper")
      );

      if (shipperData) {
      } else {
        if (repoundTable) {
          const filteredItems = repoundTable[0].filter(
            (item) => item.status === "yes"
          );
          const newData = filteredItems.map((item) => ({
            name: "",
            ShipperRisk: "",
            Shipper_Owners_Address: "",
            shipperDOB: "",
            bankCheck: "",
            bankName: "",
            bankAddress: "",
            shipperfile: "",
            shipperOwn: "",
          }));
          setBatchScreeningRequest(newData);
        }
      }
    }, [repoundTable]);

    useEffect(() => {
      const ConsigneeData = JSON.parse(
        localStorage.getItem("BtachScreeningConsignee")
      );

      if (ConsigneeData) {
      } else {
        if (ConsigneerepoundTable) {
          const filteredItems = ConsigneerepoundTable[0].filter(
            (item) => item.status === "yes"
          );
          const newData = filteredItems.map((item) => ({
            name: "",
            ShipperRisk: "",
            Shipper_Owners_Address: "",
            shipperDOB: "",
            bankCheck: "",
            bankName: "",
            bankAddress: "",
            shipperfile: "",
            shipperOwn: "",
          }));
          setBatchConsigneeRequest(newData);
        }
      }
    }, [ConsigneerepoundTable]);

    useEffect(() => {
      var EndUserData = JSON.parse(
        localStorage.getItem("BtachScreeningEnduser")
      );

      if (EndUserData) {
      } else {
        if (EndUserrepoundTable) {
          const filteredItems = EndUserrepoundTable[0].filter(
            (item) => item.status === "yes"
          );
          const newData = filteredItems.map((item) => ({
            name: "",
            ShipperRisk: "",
            Shipper_Owners_Address: "",
            shipperDOB: "",
            bankCheck: "",
            bankName: "",
            bankAddress: "",
            shipperfile: "",
            shipperOwn: "",
          }));
          setBatchEndUserRequest(newData);
        }
      }
    }, [EndUserrepoundTable]);

    useEffect(() => {
      const handleClickOutside = (event) => {
        if (modalRef.current && !modalRef.current.contains(event.target)) {
          setShowNameModal1(false);
          setShowNameModal(false);
          setShowNameModal2(false);
        }
      };

      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, []);

    return (
      <>
        <Row>
          <Col md={6}>
            <CustomForm
              onChange={(e) => setreference(e.target.value)} // set the onChange handler
              value={
                Shipperreference
                  ? Shipperreference
                  : ConsigneeepounRefernceNumber
                  ? ConsigneeepounRefernceNumber
                  : EndUserserTypeRefernce
                  ? EndUserserTypeRefernce
                  : reference
              }
              color={"#222528"}
              type="text"
              lableText="Project Name"
              placeholderText="Enter Project Name"
              readOnly={true}
            />
            {(referenceErr && Submit) || (excelUserSubmit && !reference) ? (
              <div
                style={{
                  fontFamily: "AvenirLTProLight",
                  color: "#FF0000",
                  opacity: 0.6,
                }}
              >
                {referenceErr}
              </div>
            ) : null}
          </Col>

          <Col md={6}>
            <Form.Label>
              Is this Per shipment Screening or Batch Screening?
            </Form.Label>
            {["radio"].map((type) => (
              <div key={`inline-${type}`} className="radioBx">
                <Form.Check
                  inline
                  label="Per Shipment Screening"
                  name="pageSelection"
                  type={type}
                  value="add-screening"
                  id={`inline-${type}-1`}
                  onChange={handleRadioChange}
                />
                <Form.Check
                  inline
                  label="Batch Screening"
                  name="pageSelection"
                  type={type}
                  checked
                  value="batch-screening"
                  id={`inline-${type}-2`}
                  onChange={handleRadioChange}
                />

                {(screeningTypeErr && Submit) ||
                (excelUserSubmit && !screeningType1) ? (
                  <div
                    style={{
                      fontFamily: "AvenirLTProLight",
                      color: "#FF0000",
                      opacity: 0.6,
                    }}
                  >
                    {screeningTypeErr}
                  </div>
                ) : null}
              </div>
            ))}
          </Col>
        </Row>
        <div className="d-flex align-items-center mb-3">
          <h2 className="rpsHeading">RPS (Restricted Party Screening)</h2>
          {/* <Button className="uploadBtn uploadBtn1">
            <Icon icon="carbon:add-filled" fontSize={18} /> File Upload
          </Button> */}

          {reference ? (
            <>
              <div className="upload-btn-wrapper ">
                <div class="uploadBtn uploadBtn1">
                  <Button
                    className="uploadBtn uploadBtn1"
                    onClick={() => handleFileChange()}
                  >
                    <Icon icon="carbon:add-filled" fontSize={18} /> File Upload
                  </Button>
                  <input
                    type="file"
                    name="myfile"
                    accept=".xls,.xlsx"
                    onChange={handleFileChange}
                  />
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="upload-btn-wrapper ">
                <div class="uploadBtn uploadBtn1">
                  <Button
                    className="uploadBtn uploadBtn1"
                    onClick={() => handleFileChange()}
                  >
                    <Icon icon="carbon:add-filled" fontSize={18} /> File Upload
                  </Button>
                </div>
              </div>
            </>
          )}

          <Button
            variant="primary"
            className="w-25"
            size="md"
            onClick={handleDownload}
          >
            Download Sample
          </Button>
        </div>

        <div className="mb-4">
          <Form.Label>Add User Type</Form.Label>
          <div className="d-flex align-items-center w-100">
            <div className="position-relative w-50">
              <Form.Select
                // defaultValue={value}
                value={CommonuserType}
                onChange={handleSelectChange}
              >
                <option value="Select">Select</option>
                <option value="Shipper">Shipper</option>
                <option value="Consignee">Consignee</option>
                <option value="End User">End User</option>
                <option value="Export Agent">Export Agent</option>
              </Form.Select>

              {userTypeerr && Submit && !userType ? (
                <div
                  style={{
                    fontFamily: "AvenirLTProLight",
                    color: "#FF0000",
                    opacity: 0.6,
                  }}
                >
                  {userTypeerr}
                </div>
              ) : null}

              <div className="cu_icon">
                <svg width="14" height="7" viewBox="0 0 14 7" fill="none">
                  <path d="M0 0L7 7L14 0H0Z" fill="#222528" />
                </svg>
              </div>
            </div>
            {/* {
  CommonuserType?<><Button className="uploadBtn uploadBtn1" onClick={() => handleAddcolumn()}>
  <Icon icon="carbon:add-filled" fontSize={18} /> Add Column
</Button></>:<></>
} */}
            {CommonuserType ? (
              <>
                <Button
                  className="uploadBtn uploadBtn1 mx-2"
                  onClick={() => handleAddcolumn()}
                >
                  <Icon icon="carbon:add-filled" fontSize={18} /> Add Column
                </Button>
              </>
            ) : (
              <></>
            )}
            <Button
              className="uploadBtn uploadBtn1 mx-2"
              style={{ width: 100 }}
              onClick={() => openAllUserType()}
            >
              <Icon icon="carbon:add-filled" fontSize={18} /> All
            </Button>
          </div>
        </div>

        <Accordion defaultActiveKey="0">
          {value == "Shipper" ? (
            <>
              <Accordion.Item eventKey="0">
                <Accordion.Header>Shipper</Accordion.Header>
                <Accordion.Body>
                  <Row>
                    <Col md={12}>
                      <Row className="card-boxx card-boxx1 mb-3">
                        <Col md={12}>
                          {shipperData.map((item, indexs) => {
                            const newArray = shipperData.length - 1;
                            const previousArrayLength = newArray;
                            return (
                              <div className="repeat_sec" key={indexs}>
                                <div className="position-relative">
                                  <CustomForm
                                    onChange={(e) =>
                                      handleInputChanges(e.target.value, indexs)
                                    }
                                    value={shipperData[indexs]}
                                    color={"#222528"}
                                    type="text"
                                    lableText="Shipper Name *"
                                    readOnly={setBox == "1" ? true : false}
                                    placeholderText="Enter Shipper Name"
                                  />

                                  {seachingLoader[indexs] ? (
                                    <div className="text-center mb-3 position-absolute loadBox">
                                      <Spinner
                                        variant="primary"
                                        animation="border"
                                      />
                                    </div>
                                  ) : (
                                    <>
                                      {showNameModal &&
                                        showNameModalIndex == indexs && (
                                          <div
                                            className="modal-container"
                                            ref={modalRef}
                                          >
                                            {usergetSearchApi.map(
                                              (item, index) => (
                                                <li
                                                  key={index}
                                                  onClick={() =>
                                                    hadleSelectedtName(
                                                      item,
                                                      indexs
                                                    )
                                                  }
                                                >
                                                  {item}
                                                </li>
                                              )
                                            )}
                                          </div>
                                        )}
                                    </>
                                  )}

                                  {/* {showNameModal &&
                                    showNameModalIndex === indexs && (
                                      <div className="modal-container">
                                        {seachingLoader[indexs] ? (
                                          <div className="text-center mb-3">
                                            <Spinner
                                              variant="primary"
                                              animation="border"
                                            />
                                          </div>
                                        ) : (
                                          usergetSearchApi.map(
                                            (item, index) => (
                                              <li
                                                key={index}
                                                onClick={() =>
                                                  hadleSelectedtName(
                                                    item,
                                                    indexs
                                                  )
                                                }
                                              >
                                                {item}
                                              </li>
                                            )
                                          )
                                        )}
                                      </div>
                                    )} */}
                                </div>
                                <button
                                  className="btn btn-primary search_btn"
                                  style={{
                                    width: 60,
                                    position: "relative",
                                    top: 5,
                                    marginRight: 8,
                                  }}
                                  type="button"
                                  onClick={() => getsearchaPI(indexs)}
                                >
                                  <Icon
                                    icon="iconoir:search"
                                    className="icon_pos"
                                    fontSize={18}
                                  />
                                </button>
                                {indexs !== 0 && (
                                  <Icon
                                    icon="ic:outline-delete"
                                    className="delete"
                                    fontSize={22}
                                    style={{ position: "relative", top: 3 }}
                                    onClick={() => deleteItem(indexs)}
                                  />
                                )}

                                {/* {Submit &&
                                  name[indexs]?.length === 0 &&
                                  userType === "Shipper" && (
                                    <div
                                      style={{
                                        fontFamily: "AvenirLTProLight",
                                        color: "#FF0000",
                                        opacity: 0.6,
                                      }}
                                    >
                                      Please enter Shipper name for this row.
                                    </div>
                                  )} */}
                              </div>
                            );
                          })}

                          {Submit &&
                          name.length == 0 &&
                          userType == "Shipper" ? (
                            <div
                              style={{
                                fontFamily: "AvenirLTProLight",
                                color: "#FF0000",
                                opacity: 0.6,
                              }}
                            >
                              {"Please enter your Shipper name."}
                            </div>
                          ) : null}
                          {/* {shipperSubmitError ? (
                            <div
                              style={{
                                fontFamily: "AvenirLTProLight",
                                color: "#FF0000",
                                opacity: 0.6,
                              }}
                            >
                              {"Please enter your Shipper name."}
                            </div>
                          ) : null} */}
                          {screeningCheck.hitOne != screening ||
                          (screeningCheck.hitSec != screening &&
                            setBox == "0") ? (
                            <Link
                              className="textUnderline"
                              onClick={() => {
                                if (name === "") {
                                  toast("Please enter Shipper name");
                                } else {
                                  handleRepeatColumn();
                                }
                              }}
                            >
                              + Add New Row
                            </Link>
                          ) : (
                            <></>
                          )}
                        </Col>
                      </Row>
                      {(screeningCheck.hitOne === screening ||
                        screeningCheck.hitSec === screening) && (
                        <div>
                          <Col md={12}>
                            <div className="my-3">
                              <span className="rejected mx-4">{`${risk}`}</span>
                            </div>
                          </Col>

                          <Col md={12}>
                            <small className="smallText">
                              First Screening Return Hit
                            </small>
                            <Row className="align-items-end">
                              <Col md={6}>
                                <Card className="mb-3 card_Block card_BlockBordeColor card_BlockBordeColor1">
                                  <Table responsive className="cont_table1">
                                    <tbody>
                                      <tr>
                                        <td>
                                          <strong>Name</strong>
                                        </td>
                                        <td className="text-center">
                                          <strong>Risk</strong>
                                        </td>
                                        <td className="text-center">
                                          <strong>No Risk</strong>
                                        </td>
                                      </tr>

                                      {repoundTable.length > 0 ? (
                                        <>
                                          {repoundTable[0].map(
                                            (item, index) => {
                                              if (!item.ShipperName)
                                                return null;

                                              return (
                                                <tr key={index}>
                                                  <td>{`${item.ShipperName}`}</td>

                                                  {item.status == "yes" ? (
                                                    <>
                                                      <td className="text-center">
                                                        <span className="borderRight">
                                                          <OverlayTrigger
                                                            overlay={
                                                              <Tooltip className="overUk">
                                                                {`${item.risk}`}
                                                              </Tooltip>
                                                            }
                                                          >
                                                            <Image
                                                              src={RiskIcon}
                                                              alt=""
                                                            />
                                                          </OverlayTrigger>
                                                        </span>
                                                      </td>
                                                    </>
                                                  ) : (
                                                    <td className="text-center"></td>
                                                  )}

                                                  {item.status == "No" ? (
                                                    <>
                                                      <td className="text-center">
                                                        <span className="borderRight">
                                                          <OverlayTrigger
                                                            overlay={
                                                              <Tooltip className="overUk">
                                                                {`${"No Risk"}`}
                                                              </Tooltip>
                                                            }
                                                          >
                                                            <Image
                                                              src={RiskIcon}
                                                              alt=""
                                                            />
                                                          </OverlayTrigger>
                                                        </span>
                                                      </td>
                                                    </>
                                                  ) : (
                                                    <td className="text-center"></td>
                                                  )}
                                                </tr>
                                              );
                                            }
                                          )}
                                        </>
                                      ) : (
                                        <></>
                                      )}
                                    </tbody>
                                  </Table>
                                </Card>
                              </Col>
                            </Row>

                            {riskststus == "1" ? (
                              <>
                                {repoundTable &&
                                  repoundTable[0]
                                    .filter((item) => item.status === "yes")
                                    .map((item, index) => {
                                      return (
                                        <Row key={index}>
                                          <div>
                                            <span>
                                              ------------------------------------------------------------------------------------------------------------------------------------
                                            </span>
                                          </div>
                                          <Col md={12}>
                                            <Row>
                                              <Col md={4}>
                                                <div className="mb-3">
                                                  <span className="form-label">
                                                    Does the shareholder owns{" "}
                                                    {">"} 51%
                                                  </span>
                                                  {["radio"].map((type) => (
                                                    <div
                                                      key={`inline-${type}`}
                                                      className="radioBx"
                                                    >
                                                      <Form.Check
                                                        inline
                                                        label="Yes"
                                                        name={`owns-${index}`}
                                                        type={type}
                                                        value="Yes"
                                                        checked={
                                                          BatchScreeningRequest[
                                                            index
                                                          ]?.shipperOwn ===
                                                          "Yes"
                                                        }
                                                        id={`inline-${type}-1`}
                                                        onChange={(event) =>
                                                          handleOwnershipChange(
                                                            event,
                                                            index,
                                                            item.ShipperName
                                                          )
                                                        }
                                                      />
                                                      <Form.Check
                                                        inline
                                                        label="No"
                                                        name={`owns-${index}`}
                                                        type={type}
                                                        value="No"
                                                        checked={
                                                          BatchScreeningRequest[
                                                            index
                                                          ]?.shipperOwn === "No"
                                                        }
                                                        id={`inline-${type}-2`}
                                                        onChange={(event) =>
                                                          handleOwnershipChange(
                                                            event,
                                                            index,
                                                            item.ShipperName
                                                          )
                                                        }
                                                      />
                                                    </div>
                                                  ))}
                                                </div>
                                                {BatchScreeningRequest[index]
                                                  ?.shipperOwn == "" && (
                                                  <div
                                                    style={{
                                                      fontFamily:
                                                        "AvenirLTProLight",
                                                      color: "#FF0000",
                                                      opacity: 0.6,
                                                    }}
                                                  >
                                                    {"Please Select Options"}
                                                  </div>
                                                )}
                                              </Col>
                                              <div className="my-2">
                                                <strong
                                                  style={{
                                                    fontSize: "25",
                                                    color: "#056aec",
                                                  }}
                                                >
                                                  {item.ShipperName}
                                                </strong>
                                              </div>
                                              <Col md={4}>
                                                <CustomForm
                                                  value={
                                                    BatchScreeningRequest[index]
                                                      ?.ShipperRisk || ""
                                                  }
                                                  onChange={(e) =>
                                                    handleInputChange(
                                                      index,
                                                      "ShipperRisk",
                                                      e.target.value,
                                                      item.ShipperName,
                                                      item.validate
                                                    )
                                                  }
                                                  color={"#222528"}
                                                  type="text"
                                                  lableText="Shipper Owner’s name*"
                                                  placeholderText="Enter Shipper Owner’s name"
                                                />
                                                {BatchScreeningRequest[index]
                                                  ?.ShipperRisk == "" && (
                                                  <div
                                                    style={{
                                                      fontFamily:
                                                        "AvenirLTProLight",
                                                      color: "#FF0000",
                                                      opacity: 0.6,
                                                    }}
                                                  >
                                                    {
                                                      "Please enter your shipper owner’s name"
                                                    }
                                                  </div>
                                                )}
                                              </Col>

                                              <Col md={4}>
                                                <CustomForm
                                                  value={
                                                    BatchScreeningRequest[index]
                                                      ?.Shipper_Owners_Address ||
                                                    ""
                                                  }
                                                  onChange={(e) =>
                                                    handleInputChange(
                                                      index,
                                                      "Shipper_Owners_Address",
                                                      e.target.value,
                                                      item.ShipperName
                                                    )
                                                  }
                                                  color={"#222528"}
                                                  type="text"
                                                  lableText="Shipper Owner’s Address "
                                                  placeholderText="Enter Shipper Owner’s Address"
                                                />
                                                {BatchScreeningRequest[index]
                                                  ?.Shipper_Owners_Address ==
                                                  "" && (
                                                  <div
                                                    style={{
                                                      fontFamily:
                                                        "AvenirLTProLight",
                                                      color: "#FF0000",
                                                      opacity: 0.6,
                                                    }}
                                                  >
                                                    {
                                                      "Please enter your shipper owner’s address"
                                                    }
                                                  </div>
                                                )}
                                              </Col>
                                              <Col md={6}>
                                                <h3 className="s15">
                                                  Shipper’s Business Bank Name
                                                  Optional for all country
                                                  destinations except for{" "}
                                                  <span>
                                                    Russia, Belarus, Iran, Cuba,
                                                    Myanmar, Syria, Sudan, Iraq,
                                                    Libya, Somalia, Lebanon,
                                                    Yemen, Zimbabwe
                                                  </span>
                                                  – if shipment destination
                                                  these countries then Mandatory
                                                </h3>

                                                <div className="mb-3">
                                                  <span className="form-label">
                                                    Country of destinations to
                                                    these countries?
                                                  </span>
                                                  {["radio"].map((type) => (
                                                    <div
                                                      key={`inline-${type}`}
                                                      className="radioBx"
                                                    >
                                                      <Form.Check
                                                        inline
                                                        label="Yes"
                                                        name={`owns${index}`}
                                                        checked={
                                                          BatchScreeningRequest[
                                                            index
                                                          ]?.bankCheck == "Yes"
                                                            ? 1
                                                            : ""
                                                        }
                                                        onChange={() =>
                                                          radioHandler(
                                                            "Yes",
                                                            index,
                                                            item.ShipperName
                                                          )
                                                        }
                                                        type={type}
                                                        id={`inline-yes-${index}`}
                                                      />
                                                      <Form.Check
                                                        inline
                                                        label="No"
                                                        // name="owns1"
                                                        type={type}
                                                        name={`owns1${index}`}
                                                        checked={
                                                          BatchScreeningRequest[
                                                            index
                                                          ]?.bankCheck == "No"
                                                            ? 1
                                                            : ""
                                                        }
                                                        onChange={() =>
                                                          radioHandler(
                                                            "No",
                                                            index,
                                                            item.ShipperName
                                                          )
                                                        }
                                                        id={`inline-no-${index}`}
                                                      />
                                                    </div>
                                                  ))}
                                                </div>
                                              </Col>

                                              {BatchScreeningRequest[index]
                                                ?.bankCheck == "" && (
                                                <div
                                                  style={{
                                                    fontFamily:
                                                      "AvenirLTProLight",
                                                    color: "#FF0000",
                                                    opacity: 0.6,
                                                  }}
                                                >
                                                  {
                                                    "Please select country of destinations"
                                                  }
                                                </div>
                                              )}
                                              {/* {BankCheckerr && Submit && !BankCheck ? (
                                              <div
                                                style={{
                                                  fontFamily: "AvenirLTProLight",
                                                  color: "#FF0000",
                                                  opacity: 0.6,
                                                }}
                                              >
                                                {BankCheckerr}
                                              </div>
                                            ) : null} */}
                                            </Row>
                                          </Col>

                                          {yesStatusIndices.includes(index) && (
                                            <Col md={12}>
                                              <Row>
                                                <Col md={6}>
                                                  <CustomFormWithToolTip
                                                    color={"#222528"}
                                                    type="text"
                                                    toolTopContent="Shipper’s Business Bank Name
                                                      Optional for all country destinations
                                                      except for Russia, Belarus, Iran,
                                                      Cuba, Myanmar, Syria, Sudan, Iraq,
                                                      Libya, Somalia, Lebanon, Yemen,
                                                      Zimbabwe – if shipment destination
                                                      these countries then Mandatory"
                                                    lableText="Bank Name (optional except several countries)"
                                                    placeholderText="Enter Bank Name"
                                                    value={
                                                      BatchScreeningRequest[
                                                        index
                                                      ]?.bankName || ""
                                                    }
                                                    onChange={(e) =>
                                                      handleInputChange(
                                                        index,
                                                        "bankName",
                                                        e.target.value,
                                                        item.ShipperName
                                                      )
                                                    }
                                                  />
                                                  {BatchScreeningRequest[index]
                                                    ?.bankName == "" && (
                                                    <div
                                                      style={{
                                                        fontFamily:
                                                          "AvenirLTProLight",
                                                        color: "#FF0000",
                                                        opacity: 0.6,
                                                      }}
                                                    >
                                                      {"Please enter bank name"}
                                                    </div>
                                                  )}
                                                </Col>

                                                <Col md={6}>
                                                  <CustomForm
                                                    color={"#222528"}
                                                    type="text"
                                                    lableText="Bank Address *"
                                                    placeholderText="Enter Bank Address"
                                                    value={
                                                      BatchScreeningRequest[
                                                        index
                                                      ]?.bankAddress || ""
                                                    }
                                                    onChange={(e) =>
                                                      handleInputChange(
                                                        index,
                                                        "bankAddress",
                                                        e.target.value,
                                                        item.ShipperName
                                                      )
                                                    }
                                                  />
                                                  {BatchScreeningRequest[index]
                                                    ?.bankAddress == "" && (
                                                    <div
                                                      style={{
                                                        fontFamily:
                                                          "AvenirLTProLight",
                                                        color: "#FF0000",
                                                        opacity: 0.6,
                                                      }}
                                                    >
                                                      {
                                                        "Please enter bank address"
                                                      }
                                                    </div>
                                                  )}
                                                  {/* {BankAdresserr && Submit && !BankAdress ? (
                                                  <div
                                                    style={{
                                                      fontFamily: "AvenirLTProLight",
                                                      color: "#FF0000",
                                                      opacity: 0.6,
                                                    }}
                                                  >
                                                    {BankAdresserr}
                                                  </div>
                                                ) : null} */}
                                                </Col>
                                              </Row>
                                            </Col>
                                          )}

                                          {FirstScreeningrespone === "1" && (
                                            <>
                                              {shipperRiskData.map(
                                                (items, key) => {
                                                  const matchedShipper =
                                                    BatchScreeningRequest[index]
                                                      ?.ShipperRisk ==
                                                    items.name;
                                                  const matchedShippers =
                                                    BatchScreeningRequest[index]
                                                      ?.ShipperRisk ==
                                                    items.ShipperName;
                                                  if (
                                                    matchedShipper ||
                                                    matchedShippers
                                                  ) {
                                                    return (
                                                      <>
                                                        <small className="smallText">
                                                          Second Screening
                                                          Return Hit
                                                        </small>
                                                        <Row key={key}>
                                                          <Col md={6}>
                                                            <CustomForm
                                                              color={"#222528"}
                                                              type="date"
                                                              lableText={`Shipper DOB (${items.name}) *`}
                                                              placeholderText={`Enter Shipper DOB for ${items.name}`}
                                                              value={
                                                                BatchScreeningRequest[
                                                                  index
                                                                ]?.shipperDOB ||
                                                                ""
                                                              }
                                                              onChange={(e) =>
                                                                handleInputChange(
                                                                  index,
                                                                  "shipperDOB",
                                                                  e.target
                                                                    .value,
                                                                  items.ShipperName
                                                                )
                                                              }
                                                            />
                                                            {(FirstScreeningrespone ===
                                                              "1" &&
                                                            BatchScreeningRequest[
                                                              index
                                                            ]?.shipperDOB == ""
                                                              ? (shipperDoberr =
                                                                  "1")
                                                              : "") && (
                                                              <div
                                                                style={{
                                                                  fontFamily:
                                                                    "AvenirLTProLight",
                                                                  color:
                                                                    "#FF0000",
                                                                  opacity: 0.6,
                                                                }}
                                                              >
                                                                {`Please enter shipper (DOB) for ${items.name}`}
                                                              </div>
                                                            )}
                                                          </Col>
                                                          <Col md={6}>
                                                            <CustomFileUpload
                                                              color={"#222528"}
                                                              type="file"
                                                              lableText="Shipper Passport (optional)"
                                                              placeholderText="Upload Passport"
                                                              onChange={
                                                                onChange
                                                              }
                                                            />
                                                          </Col>
                                                        </Row>
                                                      </>
                                                    );
                                                  } else {
                                                    return null; // Render nothing if no matching ShipperRisk and shipperOwn 'Yes' found
                                                  }
                                                }
                                              )}
                                            </>
                                          )}

                                          {yesStatusIndices.includes(index) &&
                                            null}
                                        </Row>
                                      );
                                    })}
                              </>
                            ) : (
                              <></>
                            )}
                          </Col>
                        </div>
                      )}

                      <Button
                        onClick={() => {
                          ScreeningAPi();
                        }}
                        className="btn btn-primary mb-4 mt-2 width_btn"
                      >
                        {shipperloader == true ? (
                          <Oval
                            height="30"
                            width="30"
                            radius="30"
                            color="#f2f5f3"
                            ariaLabel="loading"
                            wrapperStyle={{ marginLeft: 100 }}
                            wrapperClass
                          />
                        ) : (
                          <>Start Screening</>
                        )}
                      </Button>
                      
                      <ToastContainer />
                    </Col>
                  </Row>
                </Accordion.Body>
              </Accordion.Item>
            </>
          ) : (
            <></>
          )}

          {consigneevalue == "Consignee" ? (
            <>
              <Accordion.Item eventKey="0">
                <Accordion.Header>Consignee</Accordion.Header>
                <Accordion.Body>
                  <Row>
                    <Col md={12}>
                      <Row className="card-boxx card-boxx1 mb-3">
                        <Col md={12}>
                          {ConsigneeshipperData.map((item, indexs) => {
                            return (
                              <div className="repeat_sec" key={indexs}>
                                <div className="position-relative">
                                  <CustomForm
                                    onChange={(e) =>
                                      consigneehandleInputChanges(
                                        e.target.value,
                                        indexs
                                      )
                                    }
                                    // onChange={(e) => {
                                    //   const old_arr = shipperData;
                                    //   old_arr[index] = e.target.value;
                                    //   setShipper_Name([...old_arr]);
                                    // }}
                                    value={ConsigneeshipperData[indexs]}
                                    color={"#222528"}
                                    type="text"
                                    lableText="Consignee Name *"
                                    readOnly={
                                      ConsigneesetBox == "1" ? true : false
                                    }
                                    placeholderText="Enter Consignee Name"
                                  />

                                  {consigneeseachingLoader[indexs] ? (
                                    <div className="text-center mb-3 position-absolute loadBox">
                                      <Spinner
                                        variant="primary"
                                        animation="border"
                                      />
                                    </div>
                                  ) : (
                                    <>
                                      {showNameModal1 &&
                                        showNameModalIndex1 == indexs && (
                                          <div
                                            className="modal-container"
                                            ref={modalRef}
                                          >
                                            {consigneegetSearchApi.map(
                                              (item, index) => (
                                                <li
                                                  key={index}
                                                  onClick={() =>
                                                    consigneehadleSelectedtName(
                                                      item,
                                                      indexs
                                                    )
                                                  }
                                                >
                                                  {item}
                                                </li>
                                              )
                                            )}
                                          </div>
                                        )}
                                    </>
                                  )}
                                </div>
                                <button
                                  className="btn btn-primary search_btn"
                                  style={{
                                    width: 60,
                                    position: "relative",
                                    top: 5,
                                    marginRight: 8,
                                  }}
                                  type="button"
                                  onClick={() => consigneegetsearchaPI(indexs)}
                                >
                                  <Icon
                                    icon="iconoir:search"
                                    className="icon_pos"
                                    fontSize={18}
                                  />
                                </button>

                                {indexs != 0 ? (
                                  <Icon
                                    icon="ic:outline-delete"
                                    className="delete"
                                    fontSize={22}
                                    style={{ position: "relative", top: 3 }}
                                    onClick={() => consigneedeleteItem(indexs)}
                                  />
                                ) : (
                                  ""
                                )}
                                {/* <Icon
                                  icon="ic:outline-delete"
                                  className="delete"
                                  fontSize={22}
                                  onClick={() => consigneedeleteItem(indexs)}
                                /> */}
                              </div>
                            );
                          })}

                          {/* {shipperNameerr && Submit && !name ? (
                            <div
                              style={{
                                fontFamily: "AvenirLTProLight",
                                color: "#FF0000",
                                opacity: 0.6,
                              }}
                            >
                              {shipperNameerr}
                            </div>
                          ) : null} */}
                          {ConsigneeSubmit &&
                          Consigneename.length == 0 &&
                          ConsigneeuserType == "Consignee" ? (
                            <div
                              style={{
                                fontFamily: "AvenirLTProLight",
                                color: "#FF0000",
                                opacity: 0.6,
                              }}
                            >
                              {"Please enter your consignee name."}
                            </div>
                          ) : null}
                          {/* {ConsigneeshipperSubmitError ? (
                            <div
                              style={{
                                fontFamily: "AvenirLTProLight",
                                color: "#FF0000",
                                opacity: 0.6,
                              }}
                            >
                              {"Please enter your consignee name."}
                            </div>
                          ) : null} */}
                          {consgineescreeningCheck.hitOne !=
                            consigneeScreening ||
                          (consgineescreeningCheck.hitSec !=
                            consigneeScreening &&
                            ConsigneesetBox == "0") ? (
                            <Link
                              className="textUnderline"
                              onClick={() => {
                                if (Consigneename === "") {
                                  toast("Please enter consignee name");
                                } else {
                                  consigneehandleRepeatColumn();
                                }
                              }}
                            >
                              + Add New Row
                            </Link>
                          ) : (
                            <></>
                          )}
                        </Col>
                      </Row>
                      {(consgineescreeningCheck.hitOne === consigneeScreening ||
                        consgineescreeningCheck.hitSec ===
                          consigneeScreening) && (
                        <div>
                          <Col md={12}>
                            <div className="my-3">
                              <span className="rejected mx-4">{`${Consigneerisk}`}</span>
                              {/* <span className="rejected mx-4">
                                UK Sanctioned List
                              </span>
                              <span className="rejected">
                                EU Sanctioned List
                              </span> */}
                            </div>
                          </Col>

                          <Col md={12}>
                            <small className="smallText">
                              First Screening Return Hit
                            </small>
                            <Row className="align-items-end">
                              <Col md={6}>
                                <Card className="mb-3 card_Block card_BlockBordeColor card_BlockBordeColor1">
                                  <Table responsive className="cont_table1">
                                    <tbody>
                                      <tr>
                                        <td>
                                          <strong>Name</strong>
                                        </td>
                                        <td className="text-center">
                                          <strong>Risk</strong>
                                        </td>
                                        <td className="text-center">
                                          <strong>No Risk</strong>
                                        </td>
                                      </tr>

                                      {ConsigneerepoundTable.length > 0 ? (
                                        <>
                                          {ConsigneerepoundTable[0].map(
                                            (item, index) => {
                                              if (!item.ShipperName)
                                                return null;
                                              return (
                                                <tr key={index}>
                                                  <td>{`${item.ShipperName}`}</td>

                                                  {item.status == "yes" ? (
                                                    <>
                                                      <td className="text-center">
                                                        <span className="borderRight">
                                                          <OverlayTrigger
                                                            overlay={
                                                              <Tooltip className="overUk">
                                                                {`${item.risk}`}
                                                              </Tooltip>
                                                            }
                                                          >
                                                            <Image
                                                              src={RiskIcon}
                                                              alt=""
                                                            />
                                                          </OverlayTrigger>
                                                        </span>
                                                      </td>
                                                    </>
                                                  ) : (
                                                    <td className="text-center"></td>
                                                  )}

                                                  {item.status == "No" ? (
                                                    <>
                                                      <td className="text-center">
                                                        <span className="borderRight">
                                                          <OverlayTrigger
                                                            overlay={
                                                              <Tooltip className="overUk">
                                                                {`${"No Risk"}`}
                                                              </Tooltip>
                                                            }
                                                          >
                                                            <Image
                                                              src={RiskIcon}
                                                              alt=""
                                                            />
                                                          </OverlayTrigger>
                                                        </span>
                                                      </td>
                                                    </>
                                                  ) : (
                                                    <td className="text-center"></td>
                                                  )}
                                                </tr>
                                              );
                                            }
                                          )}
                                        </>
                                      ) : (
                                        <></>
                                      )}
                                    </tbody>
                                  </Table>
                                </Card>
                              </Col>
                            </Row>

                            {Consigneeriskststus == "1" ? (
                              <>
                                {ConsigneerepoundTable &&
                                  ConsigneerepoundTable[0]
                                    .filter((item) => item.status === "yes")
                                    .map((item, index) => {
                                      return (
                                        <Row key={index}>
                                          <div>
                                            <span>
                                              ------------------------------------------------------------------------------------------------------------------------------------
                                            </span>
                                          </div>
                                          <Col md={12}>
                                            <Row>
                                              <Col md={4}>
                                                <div className="mb-3">
                                                  <span className="form-label">
                                                    Does the shareholder owns{" "}
                                                    {">"} 51%
                                                  </span>
                                                  {/* {["radio"].map((type) => (
                                                  <div
                                                    key={`inline-${type}`}
                                                    className="radioBx"
                                                  >
                                                  

                                                  </div>
                                                ))} */}
                                                  <div>
                                                    <Form.Check
                                                      inline
                                                      label="Yes"
                                                      name={`owns67`}
                                                      type={"radio"}
                                                      value="Yes"
                                                      checked={
                                                        BatchConsigneeRequest[
                                                          index
                                                        ]?.shipperOwn === "Yes"
                                                      }
                                                      id={`inline-44`}
                                                      onChange={(event) =>
                                                        consgoineehandleOwnershipChange(
                                                          "Yes",
                                                          index,
                                                          item.ShipperName
                                                        )
                                                      }
                                                    />
                                                    <Form.Check
                                                      inline
                                                      label="No"
                                                      name={`owns45`}
                                                      type={"radio"}
                                                      value="No"
                                                      checked={
                                                        BatchConsigneeRequest[
                                                          index
                                                        ]?.shipperOwn === "No"
                                                      }
                                                      id={`inline-34`}
                                                      onChange={(event) =>
                                                        consgoineehandleOwnershipChange(
                                                          "No",
                                                          index,
                                                          item.ShipperName
                                                        )
                                                      }
                                                    />
                                                  </div>
                                                </div>
                                                {BatchConsigneeRequest[index]
                                                  ?.shipperOwn == "" && (
                                                  <div
                                                    style={{
                                                      fontFamily:
                                                        "AvenirLTProLight",
                                                      color: "#FF0000",
                                                      opacity: 0.6,
                                                    }}
                                                  >
                                                    {"Please Select Options"}
                                                  </div>
                                                )}
                                              </Col>
                                              <div className="my-2">
                                                <strong
                                                  style={{
                                                    fontSize: "25",
                                                    color: "#056aec",
                                                  }}
                                                >
                                                  {item.ShipperName}
                                                </strong>
                                              </div>
                                              <Col md={4}>
                                                <CustomForm
                                                  value={
                                                    BatchConsigneeRequest[index]
                                                      ?.ShipperRisk || ""
                                                  }
                                                  onChange={(e) =>
                                                    congsigneehandleInputChange(
                                                      index,
                                                      "ShipperRisk",
                                                      e.target.value,
                                                      item.ShipperName,
                                                      item.validate
                                                    )
                                                  }
                                                  color={"#222528"}
                                                  type="text"
                                                  lableText="Consignee Owner’s Name*"
                                                  placeholderText="Enter Consignee Owner’s Name"
                                                />
                                                {BatchConsigneeRequest[index]
                                                  ?.ShipperRisk == "" && (
                                                  <div
                                                    style={{
                                                      fontFamily:
                                                        "AvenirLTProLight",
                                                      color: "#FF0000",
                                                      opacity: 0.6,
                                                    }}
                                                  >
                                                    {
                                                      "Please enter your consignee owner’s name"
                                                    }
                                                  </div>
                                                )}
                                              </Col>

                                              <Col md={4}>
                                                <CustomForm
                                                  value={
                                                    BatchConsigneeRequest[index]
                                                      ?.Shipper_Owners_Address ||
                                                    ""
                                                  }
                                                  onChange={(e) =>
                                                    congsigneehandleInputChange(
                                                      index,
                                                      "Shipper_Owners_Address",
                                                      e.target.value,
                                                      item.ShipperName
                                                    )
                                                  }
                                                  color={"#222528"}
                                                  type="text"
                                                  lableText="Consignee Owner’s Address "
                                                  placeholderText="Enter consignee owner’s address "
                                                />
                                                {BatchConsigneeRequest[index]
                                                  ?.Shipper_Owners_Address ==
                                                  "" && (
                                                  <div
                                                    style={{
                                                      fontFamily:
                                                        "AvenirLTProLight",
                                                      color: "#FF0000",
                                                      opacity: 0.6,
                                                    }}
                                                  >
                                                    {
                                                      "Please enter your consignee owner’s address "
                                                    }
                                                  </div>
                                                )}
                                              </Col>
                                              <Col md={6}>
                                                <h3 className="s15">
                                                  Shipper’s Business Bank Name
                                                  Optional for all country
                                                  destinations except for{" "}
                                                  <span>
                                                    Russia, Belarus, Iran, Cuba,
                                                    Myanmar, Syria, Sudan, Iraq,
                                                    Libya, Somalia, Lebanon,
                                                    Yemen, Zimbabwe
                                                  </span>
                                                  – if shipment destination
                                                  these countries then Mandatory
                                                </h3>

                                                <div className="mb-3">
                                                  <span className="form-label">
                                                    Country of destinations to
                                                    these countries?
                                                  </span>
                                                  {["radio"].map((type) => (
                                                    <div
                                                      key={`inline-${type}`}
                                                      className="radioBx"
                                                    >
                                                      <Form.Check
                                                        inline
                                                        label="Yes"
                                                        name={`owns466`}
                                                        checked={
                                                          BatchConsigneeRequest[
                                                            index
                                                          ]?.bankCheck === "Yes"
                                                            ? 1
                                                            : ""
                                                        }
                                                        onChange={() =>
                                                          consgineeradioHandler(
                                                            "Yes",
                                                            index,
                                                            item.ShipperName
                                                          )
                                                        }
                                                        type={type}
                                                        id={`inline-45`}
                                                      />
                                                      <Form.Check
                                                        inline
                                                        label="No"
                                                        // name="owns1"
                                                        type={type}
                                                        name={`owns54`}
                                                        checked={
                                                          BatchConsigneeRequest[
                                                            index
                                                          ]?.bankCheck === "No"
                                                            ? 1
                                                            : ""
                                                        }
                                                        onChange={() =>
                                                          consgineeradioHandler(
                                                            "No",
                                                            index,
                                                            item.ShipperName
                                                          )
                                                        }
                                                        id={`inline-4454`}
                                                      />
                                                    </div>
                                                  ))}
                                                </div>
                                              </Col>

                                              {BatchConsigneeRequest[index]
                                                ?.bankCheck == "" && (
                                                <div
                                                  style={{
                                                    fontFamily:
                                                      "AvenirLTProLight",
                                                    color: "#FF0000",
                                                    opacity: 0.6,
                                                  }}
                                                >
                                                  {
                                                    "Please select country of destinations"
                                                  }
                                                </div>
                                              )}
                                            </Row>
                                          </Col>

                                          {ConsigneeyesStatusIndices.includes(
                                            index
                                          ) && (
                                            <Col md={12}>
                                              <Row>
                                                <Col md={6}>
                                                  <CustomFormWithToolTip
                                                    color={"#222528"}
                                                    type="text"
                                                    toolTopContent="Shipper’s Business Bank Name
                      Optional for all country destinations
                      except for Russia, Belarus, Iran,
                      Cuba, Myanmar, Syria, Sudan, Iraq,
                      Libya, Somalia, Lebanon, Yemen,
                      Zimbabwe – if shipment destination
                      these countries then Mandatory"
                                                    lableText="Bank Name (optional except several countries)"
                                                    placeholderText="Enter Bank Name"
                                                    value={
                                                      BatchConsigneeRequest[
                                                        index
                                                      ]?.bankName || ""
                                                    }
                                                    onChange={(e) =>
                                                      congsigneehandleInputChange(
                                                        index,
                                                        "bankName",
                                                        e.target.value,
                                                        item.ShipperName
                                                      )
                                                    }
                                                  />
                                                  {BatchConsigneeRequest[index]
                                                    ?.bankName == "" && (
                                                    <div
                                                      style={{
                                                        fontFamily:
                                                          "AvenirLTProLight",
                                                        color: "#FF0000",
                                                        opacity: 0.6,
                                                      }}
                                                    >
                                                      {"Please enter bank name"}
                                                    </div>
                                                  )}
                                                </Col>

                                                <Col md={6}>
                                                  <CustomForm
                                                    color={"#222528"}
                                                    type="text"
                                                    lableText="Bank Address *"
                                                    placeholderText="Enter Bank Address"
                                                    value={
                                                      BatchConsigneeRequest[
                                                        index
                                                      ]?.bankAddress || ""
                                                    }
                                                    onChange={(e) =>
                                                      congsigneehandleInputChange(
                                                        index,
                                                        "bankAddress",
                                                        e.target.value,
                                                        item.ShipperName
                                                      )
                                                    }
                                                  />
                                                  {BatchConsigneeRequest[index]
                                                    ?.bankAddress == "" && (
                                                    <div
                                                      style={{
                                                        fontFamily:
                                                          "AvenirLTProLight",
                                                        color: "#FF0000",
                                                        opacity: 0.6,
                                                      }}
                                                    >
                                                      {
                                                        "Please enter bank address"
                                                      }
                                                    </div>
                                                  )}
                                                </Col>
                                              </Row>
                                            </Col>
                                          )}

                                          {ConsigneeFirstScreeningrespone ===
                                            "1" && (
                                            <>
                                              {consigneeshipperRiskData.map(
                                                (items, key) => {
                                                  const matchedShipper =
                                                    BatchConsigneeRequest[index]
                                                      ?.ShipperRisk ==
                                                    items.name;

                                                  const matchedShippers =
                                                    BatchConsigneeRequest[index]
                                                      ?.ShipperRisk ==
                                                    items.ShipperName;

                                                  if (
                                                    matchedShipper ||
                                                    matchedShippers
                                                  ) {
                                                    return (
                                                      <>
                                                        <small className="smallText">
                                                          Second Screening
                                                          Return Hit
                                                        </small>
                                                        <Row key={key}>
                                                          <Col md={6}>
                                                            <CustomForm
                                                              color={"#222528"}
                                                              type="date"
                                                              lableText={`Consignee DOB * (${items.name}) *`}
                                                              placeholderText={`Enter consignee DOB for ${items.name}`}
                                                              value={
                                                                BatchConsigneeRequest[
                                                                  index
                                                                ]?.shipperDOB ||
                                                                ""
                                                              }
                                                              onChange={(e) =>
                                                                congsigneehandleInputChange(
                                                                  index,
                                                                  "shipperDOB",
                                                                  e.target
                                                                    .value,
                                                                  items.ShipperName
                                                                )
                                                              }
                                                            />
                                                            {(ConsigneeFirstScreeningrespone ===
                                                              "1" &&
                                                            BatchConsigneeRequest[
                                                              index
                                                            ]?.shipperDOB == ""
                                                              ? (consgineeDoberr =
                                                                  "1")
                                                              : "") && (
                                                              <div
                                                                style={{
                                                                  fontFamily:
                                                                    "AvenirLTProLight",
                                                                  color:
                                                                    "#FF0000",
                                                                  opacity: 0.6,
                                                                }}
                                                              >
                                                                {`Please enter consignee (DOB) for ${items.name}`}
                                                              </div>
                                                            )}
                                                          </Col>
                                                          <Col md={6}>
                                                            <CustomFileUpload
                                                              color={"#222528"}
                                                              type="file"
                                                              lableText="Consignee Passport (optional)"
                                                              placeholderText="Upload Passport"
                                                              onChange={
                                                                onChangeconsignne
                                                              }
                                                            />
                                                          </Col>
                                                        </Row>
                                                      </>
                                                    );
                                                  } else {
                                                    return null; // Render nothing if no matching ShipperRisk and shipperOwn 'Yes' found
                                                  }
                                                }
                                              )}
                                            </>
                                          )}

                                          {ConsigneeyesStatusIndices.includes(
                                            index
                                          ) && null}
                                        </Row>
                                      );
                                    })}
                              </>
                            ) : (
                              <></>
                            )}
                          </Col>
                        </div>
                      )}

                      <Button
                        onClick={() => {
                          consigneeScreeningAPi();
                        }}
                        className="btn btn-primary mb-4 mt-2 width_btn"
                      >
                        {Consigneeloader == true ? (
                          <Oval
                            height="30"
                            width="30"
                            radius="30"
                            color="#f2f5f3"
                            ariaLabel="loading"
                            wrapperStyle={{ marginLeft: 100 }}
                            wrapperClass
                          />
                        ) : (
                          <>Start Screening</>
                        )}
                      </Button>
                      <ToastContainer />
                    </Col>
                  </Row>
                </Accordion.Body>
              </Accordion.Item>
            </>
          ) : (
            <></>
          )}

          {EndUservalue == "End User" ? (
            <>
              <Accordion.Item eventKey="0">
                <Accordion.Header>End User</Accordion.Header>
                <Accordion.Body>
                  <Row>
                    <Col md={12}>
                      <Row className="card-boxx card-boxx1 mb-3">
                        <Col md={12}>
                          {EndUsershipperData.map((item, indexs) => {
                            return (
                              <div className="repeat_sec" key={indexs}>
                                <div className="position-relative">
                                  <CustomForm
                                    onChange={(e) =>
                                      enduserhandleInputChanges(
                                        e.target.value,
                                        indexs
                                      )
                                    }
                                    // onChange={(e) => {
                                    //   const old_arr = shipperData;
                                    //   old_arr[index] = e.target.value;
                                    //   setShipper_Name([...old_arr]);
                                    // }}
                                    value={EndUsershipperData[indexs]}
                                    color={"#222528"}
                                    type="text"
                                    lableText="End User Name *"
                                    readOnly={
                                      EndUsersetBox == "1" ? true : false
                                    }
                                    placeholderText="Enter end user Name"
                                  />

                                  {EndseachingLoader[indexs] ? (
                                    <div className="text-center mb-3 position-absolute loadBox">
                                      <Spinner
                                        variant="primary"
                                        animation="border"
                                      />
                                    </div>
                                  ) : (
                                    <>
                                      {showNameModal2 &&
                                        showNameModalIndex2 == indexs && (
                                          <div
                                            className="modal-container"
                                            ref={modalRef}
                                          >
                                            {cendUsergetSearchApi.map(
                                              (item, index) => (
                                                <li
                                                  key={index}
                                                  onClick={() =>
                                                    userhadleSelectedtName(
                                                      item,
                                                      indexs
                                                    )
                                                  }
                                                >
                                                  {item}
                                                </li>
                                              )
                                            )}
                                          </div>
                                        )}
                                    </>
                                  )}
                                </div>
                                <button
                                  className="btn btn-primary search_btn"
                                  style={{
                                    width: 60,
                                    position: "relative",
                                    top: 5,
                                    marginRight: 8,
                                  }}
                                  type="button"
                                  onClick={() => EndusergetsearchaPI(indexs)}
                                >
                                  <Icon
                                    icon="iconoir:search"
                                    className="icon_pos"
                                    fontSize={18}
                                  />
                                </button>
                                {indexs != 0 ? (
                                  <Icon
                                    icon="ic:outline-delete"
                                    className="delete"
                                    fontSize={22}
                                    style={{ position: "relative", top: 3 }}
                                    onClick={() => EndUserdeleteItem(indexs)}
                                  />
                                ) : (
                                  ""
                                )}
                                {/* <Icon
                                  icon="ic:outline-delete"
                                  className="delete"
                                  fontSize={22}
                                  onClick={() => EndUserdeleteItem(indexs)}
                                /> */}
                              </div>
                            );
                          })}

                          {EndUserSubmit &&
                          EndUsername.length == 0 &&
                          EndUseruserType == "End User" ? (
                            <div
                              style={{
                                fontFamily: "AvenirLTProLight",
                                color: "#FF0000",
                                opacity: 0.6,
                              }}
                            >
                              {"Please enter your end user name."}
                            </div>
                          ) : null}
                          {/* {EndUsershipperSubmitError ? (
                            <div
                              style={{
                                fontFamily: "AvenirLTProLight",
                                color: "#FF0000",
                                opacity: 0.6,
                              }}
                            >
                              {"Please enter your end user name."}
                            </div>
                          ) : null} */}
                          {EndUserscreeningCheck.hitOne != EndUserScreening ||
                          (EndUserscreeningCheck.hitSec != EndUserScreening &&
                            EndUsersetBox == "0") ? (
                            <Link
                              className="textUnderline"
                              onClick={() => {
                                if (EndUsername === "") {
                                  toast("Please enter end User name");
                                } else {
                                  EndUserhandleRepeatColumn();
                                }
                              }}
                            >
                              + Add New Row
                            </Link>
                          ) : (
                            <></>
                          )}
                        </Col>
                      </Row>
                      {(EndUserscreeningCheck.hitOne === EndUserScreening ||
                        EndUserscreeningCheck.hitSec === EndUserScreening) && (
                        <div>
                          <Col md={12}>
                            <div className="my-3">
                              <span className="rejected mx-4">{`${EndUserrisk}`}</span>
                              {/* <span className="rejected mx-4">
                                UK Sanctioned List
                              </span>
                              <span className="rejected">
                                EU Sanctioned List
                              </span> */}
                            </div>
                          </Col>

                          <Col md={12}>
                            <small className="smallText">
                              First Screening Return Hit
                            </small>
                            <Row className="align-items-end">
                              <Col md={6}>
                                <Card className="mb-3 card_Block card_BlockBordeColor card_BlockBordeColor1">
                                  <Table responsive className="cont_table1">
                                    <tbody>
                                      <tr>
                                        <td>
                                          <strong>Name</strong>
                                        </td>
                                        <td className="text-center">
                                          <strong>Risk</strong>
                                        </td>
                                        <td className="text-center">
                                          <strong>No Risk</strong>
                                        </td>
                                      </tr>

                                      {/* {EndUserrepoundTable.length > 0 ? (
                                          <>
                                            {EndUserrepoundTable[0].map((item, index) => {
                                              if (!item.ShipperName) return null;

                                              return (
                                                <tr key={index}>
                                                  <td>{`${item.ShipperName}`}</td>

                                                  {item.status == "yes" ? <><td className="text-center">
                                                    <span className="borderRight">
                                                      <OverlayTrigger
                                                        overlay={
                                                          <Tooltip className="overUk">
                                                            {`${item.risk
                                                              }`}
                                                          </Tooltip>
                                                        }
                                                      >

                                                        <Image
                                                          src={RiskIcon}
                                                          alt=""
                                                        />



                                                      </OverlayTrigger>
                                                    </span>
                                                  </td></> : <td className="text-center"></td>
                                                  }

                                                  {item.status == "No" ? <><td className="text-center">
                                                    <span className="borderRight">
                                                      <OverlayTrigger
                                                        overlay={
                                                          <Tooltip className="overUk">
                                                            {`${'No Risk'
                                                              }`}
                                                          </Tooltip>
                                                        }
                                                      >

                                                        <Image
                                                          src={RiskIcon}
                                                          alt=""
                                                        />



                                                      </OverlayTrigger>
                                                    </span>
                                                  </td></> : <td className="text-center"></td>
                                                  }


                                                </tr>
                                              );
                                            })}
                                          </>
                                        ) : (
                                          <></>
                                        )} */}

                                      {EndUserrepoundTable.length > 0 ? (
                                        <>
                                          {EndUserrepoundTable[0].map(
                                            (item, index) => {
                                              if (!item.ShipperName)
                                                return null;

                                              return (
                                                <tr key={index}>
                                                  <td>{`${item.ShipperName}`}</td>

                                                  {item.status == "yes" ? (
                                                    <>
                                                      <td className="text-center">
                                                        <span className="borderRight">
                                                          <OverlayTrigger
                                                            overlay={
                                                              <Tooltip className="overUk">
                                                                {`${item.risk}`}
                                                              </Tooltip>
                                                            }
                                                          >
                                                            <Image
                                                              src={RiskIcon}
                                                              alt=""
                                                            />
                                                          </OverlayTrigger>
                                                        </span>
                                                      </td>
                                                    </>
                                                  ) : (
                                                    <td className="text-center"></td>
                                                  )}

                                                  {item.status == "No" ? (
                                                    <>
                                                      <td className="text-center">
                                                        <span className="borderRight">
                                                          <OverlayTrigger
                                                            overlay={
                                                              <Tooltip className="overUk">
                                                                {`${"No Risk"}`}
                                                              </Tooltip>
                                                            }
                                                          >
                                                            <Image
                                                              src={RiskIcon}
                                                              alt=""
                                                            />
                                                          </OverlayTrigger>
                                                        </span>
                                                      </td>
                                                    </>
                                                  ) : (
                                                    <td className="text-center"></td>
                                                  )}
                                                </tr>
                                              );
                                            }
                                          )}
                                        </>
                                      ) : (
                                        <></>
                                      )}
                                    </tbody>
                                  </Table>
                                </Card>
                              </Col>
                            </Row>

                            {EndUserriskststus == "1" ? (
                              <>
                                {EndUserrepoundTable &&
                                  EndUserrepoundTable[0]
                                    .filter((item) => item.status === "yes")
                                    .map((item, index) => {
                                      return (
                                        <Row key={index}>
                                          <div>
                                            <span>
                                              ------------------------------------------------------------------------------------------------------------------------------------
                                            </span>
                                          </div>
                                          <Col md={12}>
                                            <Row>
                                              <Col md={4}>
                                                <div className="mb-3">
                                                  <span className="form-label">
                                                    Does the shareholder owns{" "}
                                                    {">"} 51%
                                                  </span>
                                                  {["radio"].map((type) => (
                                                    <div
                                                      key={`inline-${type}`}
                                                      className="radioBx"
                                                    >
                                                      <Form.Check
                                                        inline
                                                        label="Yes"
                                                        name={`owns-34`}
                                                        type={type}
                                                        value="Yes"
                                                        checked={
                                                          BatchEndUserRequest[
                                                            index
                                                          ]?.shipperOwn == "Yes"
                                                        }
                                                        id={`inline-333`}
                                                        onChange={(event) =>
                                                          EndUserhandleOwnershipChange(
                                                            event,
                                                            index,
                                                            item.ShipperName
                                                          )
                                                        }
                                                      />
                                                      <Form.Check
                                                        inline
                                                        label="No"
                                                        name={`owns-22`}
                                                        type={type}
                                                        value="No"
                                                        checked={
                                                          BatchEndUserRequest[
                                                            index
                                                          ]?.shipperOwn == "No"
                                                        }
                                                        id={`inline-133`}
                                                        onChange={(event) =>
                                                          EndUserhandleOwnershipChange(
                                                            event,
                                                            index,
                                                            item.ShipperName
                                                          )
                                                        }
                                                      />
                                                    </div>
                                                  ))}
                                                </div>
                                                {BatchEndUserRequest[index]
                                                  ?.shipperOwn == "" && (
                                                  <div
                                                    style={{
                                                      fontFamily:
                                                        "AvenirLTProLight",
                                                      color: "#FF0000",
                                                      opacity: 0.6,
                                                    }}
                                                  >
                                                    {"Please Select Options"}
                                                  </div>
                                                )}
                                              </Col>
                                              <div className="my-2">
                                                <strong
                                                  style={{
                                                    fontSize: "25",
                                                    color: "#056aec",
                                                  }}
                                                >
                                                  {item.ShipperName}
                                                </strong>
                                              </div>
                                              <Col md={4}>
                                                <CustomForm
                                                  value={
                                                    BatchEndUserRequest[index]
                                                      ?.ShipperRisk || ""
                                                  }
                                                  onChange={(e) =>
                                                    EnduSERhandleInputChange(
                                                      index,
                                                      "ShipperRisk",
                                                      e.target.value,
                                                      item.ShipperName,
                                                      item.validate
                                                    )
                                                  }
                                                  color={"#222528"}
                                                  type="text"
                                                  lableText="End User Owner’s Name*"
                                                  placeholderText="Enter End User Owner’s Name"
                                                />
                                                {BatchEndUserRequest[index]
                                                  ?.ShipperRisk == "" && (
                                                  <div
                                                    style={{
                                                      fontFamily:
                                                        "AvenirLTProLight",
                                                      color: "#FF0000",
                                                      opacity: 0.6,
                                                    }}
                                                  >
                                                    {
                                                      "Please enter end user owner’s name"
                                                    }
                                                  </div>
                                                )}
                                              </Col>

                                              <Col md={4}>
                                                <CustomForm
                                                  value={
                                                    BatchEndUserRequest[index]
                                                      ?.Shipper_Owners_Address ||
                                                    ""
                                                  }
                                                  onChange={(e) =>
                                                    EnduSERhandleInputChange(
                                                      index,
                                                      "Shipper_Owners_Address",
                                                      e.target.value,
                                                      item.ShipperName
                                                    )
                                                  }
                                                  color={"#222528"}
                                                  type="text"
                                                  lableText="End User Owner’s Address "
                                                  placeholderText="Enter End User Owner’s Address"
                                                />
                                                {BatchEndUserRequest[index]
                                                  ?.Shipper_Owners_Address ==
                                                  "" && (
                                                  <div
                                                    style={{
                                                      fontFamily:
                                                        "AvenirLTProLight",
                                                      color: "#FF0000",
                                                      opacity: 0.6,
                                                    }}
                                                  >
                                                    {
                                                      "Please enter end user owner’s address"
                                                    }
                                                  </div>
                                                )}
                                              </Col>
                                              <Col md={6}>
                                                <h3 className="s15">
                                                  Shipper’s Business Bank Name
                                                  Optional for all country
                                                  destinations except for{" "}
                                                  <span>
                                                    Russia, Belarus, Iran, Cuba,
                                                    Myanmar, Syria, Sudan, Iraq,
                                                    Libya, Somalia, Lebanon,
                                                    Yemen, Zimbabwe
                                                  </span>
                                                  – if shipment destination
                                                  these countries then Mandatory
                                                </h3>

                                                <div className="mb-3">
                                                  <span className="form-label">
                                                    Country of destinations to
                                                    these countries?
                                                  </span>
                                                  {["radio"].map((type) => (
                                                    <div
                                                      key={`inline-${type}`}
                                                      className="radioBx"
                                                    >
                                                      <Form.Check
                                                        inline
                                                        label="Yes"
                                                        name={`owns47`}
                                                        checked={
                                                          BatchEndUserRequest[
                                                            index
                                                          ]?.bankCheck == "Yes"
                                                            ? 1
                                                            : ""
                                                        }
                                                        onChange={() =>
                                                          EndUserradioHandler(
                                                            "Yes",
                                                            index,
                                                            item.ShipperName
                                                          )
                                                        }
                                                        type={type}
                                                        id={`inline44`}
                                                      />
                                                      <Form.Check
                                                        inline
                                                        label="No"
                                                        // name="owns1"
                                                        type={type}
                                                        name={`owns45`}
                                                        checked={
                                                          BatchEndUserRequest[
                                                            index
                                                          ]?.bankCheck == "No"
                                                            ? 1
                                                            : ""
                                                        }
                                                        onChange={() =>
                                                          EndUserradioHandler(
                                                            "No",
                                                            index,
                                                            item.ShipperName
                                                          )
                                                        }
                                                        id={`inline43`}
                                                      />
                                                    </div>
                                                  ))}
                                                </div>
                                              </Col>

                                              {BatchEndUserRequest[index]
                                                ?.bankCheck == "" && (
                                                <div
                                                  style={{
                                                    fontFamily:
                                                      "AvenirLTProLight",
                                                    color: "#FF0000",
                                                    opacity: 0.6,
                                                  }}
                                                >
                                                  {
                                                    "Please select country of destinations"
                                                  }
                                                </div>
                                              )}
                                              {/* {BankCheckerr && Submit && !BankCheck ? (
              <div
                style={{
                  fontFamily: "AvenirLTProLight",
                  color: "#FF0000",
                  opacity: 0.6,
                }}
              >
                {BankCheckerr}
              </div>
            ) : null} */}
                                            </Row>
                                          </Col>

                                          {EndUseryesStatusIndices.includes(
                                            index
                                          ) && (
                                            <Col md={12}>
                                              <Row>
                                                <Col md={6}>
                                                  <CustomFormWithToolTip
                                                    color={"#222528"}
                                                    type="text"
                                                    toolTopContent="Shipper’s Business Bank Name
                      Optional for all country destinations
                      except for Russia, Belarus, Iran,
                      Cuba, Myanmar, Syria, Sudan, Iraq,
                      Libya, Somalia, Lebanon, Yemen,
                      Zimbabwe – if shipment destination
                      these countries then Mandatory"
                                                    lableText="Bank Name (optional except several countries)"
                                                    placeholderText="Enter Bank Name"
                                                    value={
                                                      BatchEndUserRequest[index]
                                                        ?.bankName || ""
                                                    }
                                                    onChange={(e) =>
                                                      EnduSERhandleInputChange(
                                                        index,
                                                        "bankName",
                                                        e.target.value,
                                                        item.ShipperName
                                                      )
                                                    }
                                                  />
                                                  {BatchEndUserRequest[index]
                                                    ?.bankName == "" && (
                                                    <div
                                                      style={{
                                                        fontFamily:
                                                          "AvenirLTProLight",
                                                        color: "#FF0000",
                                                        opacity: 0.6,
                                                      }}
                                                    >
                                                      {"Please enter bank name"}
                                                    </div>
                                                  )}
                                                </Col>

                                                <Col md={6}>
                                                  <CustomForm
                                                    color={"#222528"}
                                                    type="text"
                                                    lableText="Bank Address *"
                                                    placeholderText="Enter Bank Address"
                                                    value={
                                                      BatchEndUserRequest[index]
                                                        ?.bankAddress || ""
                                                    }
                                                    onChange={(e) =>
                                                      EnduSERhandleInputChange(
                                                        index,
                                                        "bankAddress",
                                                        e.target.value,
                                                        item.ShipperName
                                                      )
                                                    }
                                                  />
                                                  {BatchEndUserRequest[index]
                                                    ?.bankAddress == "" && (
                                                    <div
                                                      style={{
                                                        fontFamily:
                                                          "AvenirLTProLight",
                                                        color: "#FF0000",
                                                        opacity: 0.6,
                                                      }}
                                                    >
                                                      {
                                                        "Please enter bank address"
                                                      }
                                                    </div>
                                                  )}
                                                  {/* {BankAdresserr && Submit && !BankAdress ? (
                  <div
                    style={{
                      fontFamily: "AvenirLTProLight",
                      color: "#FF0000",
                      opacity: 0.6,
                    }}
                  >
                    {BankAdresserr}
                  </div>
                ) : null} */}
                                                </Col>
                                              </Row>
                                            </Col>
                                          )}

                                          {EndUserScreeningrespone === "1" && (
                                            <>
                                              {EndUsershipperRiskData.map(
                                                (items, key) => {
                                                  const matchedShipper =
                                                    BatchEndUserRequest[index]
                                                      ?.ShipperRisk ==
                                                    items.name;
                                                  const matchedShippers =
                                                    BatchEndUserRequest[index]
                                                      ?.ShipperRisk ==
                                                    items.ShipperName;

                                                  if (
                                                    matchedShipper ||
                                                    matchedShippers
                                                  ) {
                                                    return (
                                                      <>
                                                        <small className="smallText">
                                                          Second Screening
                                                          Return Hit
                                                        </small>
                                                        <Row key={key}>
                                                          <Col md={6}>
                                                            <CustomForm
                                                              color={"#222528"}
                                                              type="date"
                                                              lableText={`End User DOB * (${items.name}) *`}
                                                              placeholderText={`Enter End User DOB * for ${items.name}`}
                                                              value={
                                                                BatchEndUserRequest[
                                                                  index
                                                                ]?.shipperDOB ||
                                                                ""
                                                              }
                                                              onChange={(e) =>
                                                                EnduSERhandleInputChange(
                                                                  index,
                                                                  "shipperDOB",
                                                                  e.target
                                                                    .value,
                                                                  items.ShipperName
                                                                )
                                                              }
                                                            />
                                                            {(EndUserScreeningrespone ===
                                                              "1" &&
                                                            BatchEndUserRequest[
                                                              index
                                                            ]?.shipperDOB == ""
                                                              ? endUserDoberr ==
                                                                "1"
                                                              : "") && (
                                                              <div
                                                                style={{
                                                                  fontFamily:
                                                                    "AvenirLTProLight",
                                                                  color:
                                                                    "#FF0000",
                                                                  opacity: 0.6,
                                                                }}
                                                              >
                                                                {`Please enter end user (DOB) for ${items.name}`}
                                                              </div>
                                                            )}
                                                          </Col>
                                                          <Col md={6}>
                                                            <CustomFileUpload
                                                              color={"#222528"}
                                                              type="file"
                                                              lableText="End User Passport (optional)"
                                                              placeholderText="Upload Passport"
                                                              onChange={
                                                                onChangeEndUser
                                                              }
                                                            />
                                                          </Col>
                                                        </Row>
                                                      </>
                                                    );
                                                  } else {
                                                    return null; // Render nothing if no matching ShipperRisk and shipperOwn 'Yes' found
                                                  }
                                                }
                                              )}
                                            </>
                                          )}

                                          {EndUseryesStatusIndices.includes(
                                            index
                                          ) && null}
                                        </Row>
                                      );
                                    })}
                              </>
                            ) : (
                              <></>
                            )}
                          </Col>
                        </div>
                      )}

                      <Button
                        onClick={() => {
                          EndUserUserScreeningAPi();
                        }}
                        className="btn btn-primary mb-4 mt-2 width_btn"
                      >
                        {EndUserloader == true ? (
                          <Oval
                            height="30"
                            width="30"
                            radius="30"
                            color="#f2f5f3"
                            ariaLabel="loading"
                            wrapperStyle={{ marginLeft: 100 }}
                            wrapperClass
                          />
                        ) : (
                          <>Start Screening</>
                        )}
                      </Button>
                      <ToastContainer />
                    </Col>
                  </Row>
                </Accordion.Body>
              </Accordion.Item>
            </>
          ) : (
            <></>
          )}

          {excelSelectedFile && risk.length > 0 ? (
            <>
              <Accordion.Item eventKey="0">
                <Accordion.Header>Shipper</Accordion.Header>
                <Accordion.Body>
                  <Row>
                    <Col md={12}>
                      {(screeningCheck.hitOne === screening ||
                        screeningCheck.hitSec === screening) && (
                        <div>
                          <Col md={12}>
                            <div className="my-3">
                              <span className="rejected mx-4">{`${risk}`}</span>
                            </div>
                          </Col>

                          <Col md={12}>
                            <small className="smallText">
                              First Screening Return Hit
                            </small>
                            <Row className="align-items-end">
                              <Col md={6}>
                                <Card className="mb-3 card_Block card_BlockBordeColor card_BlockBordeColor1">
                                  <Table responsive className="cont_table1">
                                    <tbody>
                                      <tr>
                                        <td>
                                          <strong>Name</strong>
                                        </td>
                                        <td className="text-center">
                                          <strong>Risk</strong>
                                        </td>
                                        <td className="text-center">
                                          <strong>No Risk</strong>
                                        </td>
                                      </tr>

                                      {repoundTable.length > 0 ? (
                                        <>
                                          {repoundTable[0].map(
                                            (item, index) => {
                                              if (!item.ShipperName)
                                                return null;

                                              return (
                                                <tr key={index}>
                                                  <td>{`${item.ShipperName}`}</td>

                                                  {item.status == "yes" ? (
                                                    <>
                                                      <td className="text-center">
                                                        <span className="borderRight">
                                                          <OverlayTrigger
                                                            overlay={
                                                              <Tooltip className="overUk">
                                                                {`${item.risk}`}
                                                              </Tooltip>
                                                            }
                                                          >
                                                            <Image
                                                              src={RiskIcon}
                                                              alt=""
                                                            />
                                                          </OverlayTrigger>
                                                        </span>
                                                      </td>
                                                    </>
                                                  ) : (
                                                    <td className="text-center"></td>
                                                  )}

                                                  {item.status == "No" ? (
                                                    <>
                                                      <td className="text-center">
                                                        <span className="borderRight">
                                                          <OverlayTrigger
                                                            overlay={
                                                              <Tooltip className="overUk">
                                                                {`${"No Risk"}`}
                                                              </Tooltip>
                                                            }
                                                          >
                                                            <Image
                                                              src={RiskIcon}
                                                              alt=""
                                                            />
                                                          </OverlayTrigger>
                                                        </span>
                                                      </td>
                                                    </>
                                                  ) : (
                                                    <td className="text-center"></td>
                                                  )}
                                                </tr>
                                              );
                                            }
                                          )}
                                        </>
                                      ) : (
                                        <></>
                                      )}
                                    </tbody>
                                  </Table>
                                </Card>
                              </Col>
                            </Row>

                            {riskststus == "1" ? (
                              <>
                                {repoundTable &&
                                  repoundTable[0]
                                    .filter((item) => item.status === "yes")
                                    .map((item, index) => {
                                      return (
                                        <Row key={index}>
                                          <div>
                                            <span>
                                              ------------------------------------------------------------------------------------------------------------------------------------
                                            </span>
                                          </div>
                                          <Col md={12}>
                                            <Row>
                                              <Col md={4}>
                                                <div className="mb-3">
                                                  <span className="form-label">
                                                    Does the shareholder owns{" "}
                                                    {">"} 51%
                                                  </span>
                                                  {["radio"].map((type) => (
                                                    <div
                                                      key={`inline-${type}`}
                                                      className="radioBx"
                                                    >
                                                      <Form.Check
                                                        inline
                                                        label="Yes"
                                                        name={`owns-${index}`}
                                                        type={type}
                                                        value="Yes"
                                                        checked={
                                                          BatchScreeningRequest[
                                                            index
                                                          ]?.shipperOwn ===
                                                          "Yes"
                                                        }
                                                        id={`inline-${type}-1`}
                                                        onChange={(event) =>
                                                          handleOwnershipChange(
                                                            event,
                                                            index,
                                                            item.ShipperName
                                                          )
                                                        }
                                                      />
                                                      <Form.Check
                                                        inline
                                                        label="No"
                                                        name={`owns-${index}`}
                                                        type={type}
                                                        value="No"
                                                        checked={
                                                          BatchScreeningRequest[
                                                            index
                                                          ]?.shipperOwn === "No"
                                                        }
                                                        id={`inline-${type}-2`}
                                                        onChange={(event) =>
                                                          handleOwnershipChange(
                                                            event,
                                                            index,
                                                            item.ShipperName
                                                          )
                                                        }
                                                      />
                                                    </div>
                                                  ))}
                                                </div>
                                                {BatchScreeningRequest[index]
                                                  ?.shipperOwn == "" && (
                                                  <div
                                                    style={{
                                                      fontFamily:
                                                        "AvenirLTProLight",
                                                      color: "#FF0000",
                                                      opacity: 0.6,
                                                    }}
                                                  >
                                                    {"Please Select Options"}
                                                  </div>
                                                )}
                                              </Col>
                                              <div className="my-2">
                                                <strong
                                                  style={{
                                                    fontSize: "25",
                                                    color: "#056aec",
                                                  }}
                                                >
                                                  {item.ShipperName}
                                                </strong>
                                              </div>
                                              <Col md={4}>
                                                <CustomForm
                                                  value={
                                                    BatchScreeningRequest[index]
                                                      ?.ShipperRisk || ""
                                                  }
                                                  onChange={(e) =>
                                                    handleInputChange(
                                                      index,
                                                      "ShipperRisk",
                                                      e.target.value,
                                                      item.ShipperName
                                                    )
                                                  }
                                                  color={"#222528"}
                                                  type="text"
                                                  lableText="Shipper Owner’s name*"
                                                  placeholderText="Enter Shipper Owner’s name"
                                                />
                                                {BatchScreeningRequest[index]
                                                  ?.ShipperRisk == "" && (
                                                  <div
                                                    style={{
                                                      fontFamily:
                                                        "AvenirLTProLight",
                                                      color: "#FF0000",
                                                      opacity: 0.6,
                                                    }}
                                                  >
                                                    {
                                                      "Please enter your shipper owner’s name"
                                                    }
                                                  </div>
                                                )}
                                              </Col>

                                              <Col md={4}>
                                                <CustomForm
                                                  value={
                                                    BatchScreeningRequest[index]
                                                      ?.Shipper_Owners_Address ||
                                                    ""
                                                  }
                                                  onChange={(e) =>
                                                    handleInputChange(
                                                      index,
                                                      "Shipper_Owners_Address",
                                                      e.target.value,
                                                      item.ShipperName
                                                    )
                                                  }
                                                  color={"#222528"}
                                                  type="text"
                                                  lableText="Shipper Owner’s Address "
                                                  placeholderText="Enter Shipper Owner’s Address"
                                                />
                                                {BatchScreeningRequest[index]
                                                  ?.Shipper_Owners_Address ==
                                                  "" && (
                                                  <div
                                                    style={{
                                                      fontFamily:
                                                        "AvenirLTProLight",
                                                      color: "#FF0000",
                                                      opacity: 0.6,
                                                    }}
                                                  >
                                                    {
                                                      "Please enter your shipper owner’s address"
                                                    }
                                                  </div>
                                                )}
                                              </Col>
                                              <Col md={6}>
                                                <h3 className="s15">
                                                  Shipper’s Business Bank Name
                                                  Optional for all country
                                                  destinations except for
                                                  <span>
                                                    Russia, Belarus, Iran, Cuba,
                                                    Myanmar, Syria, Sudan, Iraq,
                                                    Libya, Somalia, Lebanon,
                                                    Yemen, Zimbabwe
                                                  </span>
                                                  – if shipment destination
                                                  these countries then Mandatory
                                                </h3>

                                                <div className="mb-3">
                                                  <span className="form-label">
                                                    Country of destinations to
                                                    these countries?
                                                  </span>
                                                  {["radio"].map((type) => (
                                                    <div
                                                      key={`inline-${type}`}
                                                      className="radioBx"
                                                    >
                                                      <Form.Check
                                                        inline
                                                        label="Yes"
                                                        name={`owns${index}`}
                                                        checked={
                                                          BatchScreeningRequest[
                                                            index
                                                          ]?.bankCheck == "Yes"
                                                            ? 1
                                                            : ""
                                                        }
                                                        onChange={() =>
                                                          radioHandler(
                                                            "Yes",
                                                            index,
                                                            item.ShipperName
                                                          )
                                                        }
                                                        type={type}
                                                        id={`inline-yes-${index}`}
                                                      />
                                                      <Form.Check
                                                        inline
                                                        label="No"
                                                        // name="owns1"
                                                        type={type}
                                                        name={`owns1${index}`}
                                                        checked={
                                                          BatchScreeningRequest[
                                                            index
                                                          ]?.bankCheck == "No"
                                                            ? 1
                                                            : ""
                                                        }
                                                        onChange={() =>
                                                          radioHandler(
                                                            "No",
                                                            index,
                                                            item.ShipperName
                                                          )
                                                        }
                                                        id={`inline-no-${index}`}
                                                      />
                                                    </div>
                                                  ))}
                                                </div>
                                              </Col>

                                              {BatchScreeningRequest[index]
                                                ?.bankCheck == "" && (
                                                <div
                                                  style={{
                                                    fontFamily:
                                                      "AvenirLTProLight",
                                                    color: "#FF0000",
                                                    opacity: 0.6,
                                                  }}
                                                >
                                                  {
                                                    "Please select country of destinations"
                                                  }
                                                </div>
                                              )}
                                            </Row>
                                          </Col>

                                          {yesStatusIndices.includes(index) && (
                                            <Col md={12}>
                                              <Row>
                                                <Col md={6}>
                                                  <CustomFormWithToolTip
                                                    color={"#222528"}
                                                    type="text"
                                                    toolTopContent="Shipper’s Business Bank Name
                                                      Optional for all country destinations
                                                      except for Russia, Belarus, Iran,
                                                      Cuba, Myanmar, Syria, Sudan, Iraq,
                                                      Libya, Somalia, Lebanon, Yemen,
                                                      Zimbabwe – if shipment destination
                                                      these countries then Mandatory"
                                                    lableText="Bank Name (optional except several countries)"
                                                    placeholderText="Enter Bank Name"
                                                    value={
                                                      BatchScreeningRequest[
                                                        index
                                                      ]?.bankName || ""
                                                    }
                                                    onChange={(e) =>
                                                      handleInputChange(
                                                        index,
                                                        "bankName",
                                                        e.target.value,
                                                        item.ShipperName
                                                      )
                                                    }
                                                  />
                                                  {BatchScreeningRequest[index]
                                                    ?.bankName == "" && (
                                                    <div
                                                      style={{
                                                        fontFamily:
                                                          "AvenirLTProLight",
                                                        color: "#FF0000",
                                                        opacity: 0.6,
                                                      }}
                                                    >
                                                      {"Please enter bank name"}
                                                    </div>
                                                  )}
                                                </Col>

                                                <Col md={6}>
                                                  <CustomForm
                                                    color={"#222528"}
                                                    type="text"
                                                    lableText="Bank Address *"
                                                    placeholderText="Enter Bank Address"
                                                    value={
                                                      BatchScreeningRequest[
                                                        index
                                                      ]?.bankAddress || ""
                                                    }
                                                    onChange={(e) =>
                                                      handleInputChange(
                                                        index,
                                                        "bankAddress",
                                                        e.target.value,
                                                        item.ShipperName
                                                      )
                                                    }
                                                  />
                                                  {BatchScreeningRequest[index]
                                                    ?.bankAddress == "" && (
                                                    <div
                                                      style={{
                                                        fontFamily:
                                                          "AvenirLTProLight",
                                                        color: "#FF0000",
                                                        opacity: 0.6,
                                                      }}
                                                    >
                                                      {
                                                        "Please enter bank address"
                                                      }
                                                    </div>
                                                  )}
                                                </Col>
                                              </Row>
                                            </Col>
                                          )}

                                          {FirstScreeningrespone === "1" && (
                                            <>
                                              {shipperRiskData.map(
                                                (items, key) => {
                                                  const matchedShipper =
                                                    BatchScreeningRequest[index]
                                                      ?.ShipperRisk ==
                                                    items.ShipperRisk;

                                                  if (matchedShipper) {
                                                    return (
                                                      <>
                                                        <small className="smallText">
                                                          Second Screening
                                                          Return Hit
                                                        </small>
                                                        <Row key={key}>
                                                          <Col md={6}>
                                                            <CustomForm
                                                              color={"#222528"}
                                                              type="date"
                                                              lableText={`Shipper DOB (${items.name}) *`}
                                                              placeholderText={`Enter Shipper DOB for ${items.name}`}
                                                              value={
                                                                BatchScreeningRequest[
                                                                  index
                                                                ]?.shipperDOB ||
                                                                ""
                                                              }
                                                              onChange={(e) => {
                                                                // Log the item details
                                                                handleInputChange(
                                                                  index,
                                                                  "shipperDOB",
                                                                  e.target
                                                                    .value,
                                                                  item.ShipperName
                                                                );
                                                              }}
                                                            />
                                                            {(FirstScreeningrespone ===
                                                              "1" &&
                                                            BatchScreeningRequest[
                                                              index
                                                            ]?.shipperDOB == ""
                                                              ? (shipperDoberr =
                                                                  "1")
                                                              : "") && (
                                                              <div
                                                                style={{
                                                                  fontFamily:
                                                                    "AvenirLTProLight",
                                                                  color:
                                                                    "#FF0000",
                                                                  opacity: 0.6,
                                                                }}
                                                              >
                                                                {`Please enter shipper (DOB) for ${items.name}`}
                                                              </div>
                                                            )}
                                                          </Col>
                                                          <Col md={6}>
                                                            <CustomFileUpload
                                                              color={"#222528"}
                                                              type="file"
                                                              lableText="Shipper Passport (optional)"
                                                              placeholderText="Upload Passport"
                                                              onChange={(e) =>
                                                                onChange(
                                                                  e,
                                                                  index
                                                                )
                                                              }
                                                            />
                                                          </Col>
                                                        </Row>
                                                      </>
                                                    );
                                                  } else {
                                                    return null; // Render nothing if no matching ShipperRisk and shipperOwn 'Yes' found
                                                  }
                                                }
                                              )}
                                            </>
                                          )}

                                          {yesStatusIndices.includes(index) &&
                                            null}
                                        </Row>
                                      );
                                    })}
                              </>
                            ) : (
                              <></>
                            )}
                          </Col>
                        </div>
                      )}
                      <Button
                        onClick={() => {
                          excelSheetShipperScreeningAPi();
                        }}
                        className="btn btn-primary mb-4 mt-2 width_btn"
                      >
                        Start Screening
                      </Button>

                      <ToastContainer />
                    </Col>
                  </Row>
                </Accordion.Body>
              </Accordion.Item>
            </>
          ) : (
            <></>
          )}

          {excelSelectedFile && Consigneerisk.length > 0 ? (
            <>
              <Accordion.Item eventKey="0">
                <Accordion.Header>Consignee</Accordion.Header>
                <Accordion.Body>
                  <Row>
                    <Col md={12}>
                      <Row className="card-boxx card-boxx1 mb-3"></Row>
                      {(consgineescreeningCheck.hitOne === consigneeScreening ||
                        consgineescreeningCheck.hitSec ===
                          consigneeScreening) && (
                        <div>
                          <Col md={12}>
                            <div className="my-3">
                              <span className="rejected mx-4">{`${Consigneerisk}`}</span>
                            </div>
                          </Col>

                          <Col md={12}>
                            <small className="smallText">
                              First Screening Return Hit
                            </small>
                            <Row className="align-items-end">
                              <Col md={6}>
                                <Card className="mb-3 card_Block card_BlockBordeColor card_BlockBordeColor1">
                                  <Table responsive className="cont_table1">
                                    <tbody>
                                      <tr>
                                        <td>
                                          <strong>Name</strong>
                                        </td>
                                        <td className="text-center">
                                          <strong>Risk</strong>
                                        </td>
                                        <td className="text-center">
                                          <strong>No Risk</strong>
                                        </td>
                                      </tr>

                                      {ConsigneerepoundTable.length > 0 ? (
                                        <>
                                          {ConsigneerepoundTable[0].map(
                                            (item, index) => {
                                              if (!item.ShipperName)
                                                return null;
                                              return (
                                                <tr key={index}>
                                                  <td>{`${item.ShipperName}`}</td>

                                                  {item.status == "yes" ? (
                                                    <>
                                                      <td className="text-center">
                                                        <span className="borderRight">
                                                          <OverlayTrigger
                                                            overlay={
                                                              <Tooltip className="overUk">
                                                                {`${item.risk}`}
                                                              </Tooltip>
                                                            }
                                                          >
                                                            <Image
                                                              src={RiskIcon}
                                                              alt=""
                                                            />
                                                          </OverlayTrigger>
                                                        </span>
                                                      </td>
                                                    </>
                                                  ) : (
                                                    <td className="text-center"></td>
                                                  )}

                                                  {item.status == "No" ? (
                                                    <>
                                                      <td className="text-center">
                                                        <span className="borderRight">
                                                          <OverlayTrigger
                                                            overlay={
                                                              <Tooltip className="overUk">
                                                                {`${"No Risk"}`}
                                                              </Tooltip>
                                                            }
                                                          >
                                                            <Image
                                                              src={RiskIcon}
                                                              alt=""
                                                            />
                                                          </OverlayTrigger>
                                                        </span>
                                                      </td>
                                                    </>
                                                  ) : (
                                                    <td className="text-center"></td>
                                                  )}
                                                </tr>
                                              );
                                            }
                                          )}
                                        </>
                                      ) : (
                                        <></>
                                      )}
                                    </tbody>
                                  </Table>
                                </Card>
                              </Col>
                            </Row>

                            {Consigneeriskststus == "1" ? (
                              <>
                                {ConsigneerepoundTable &&
                                  ConsigneerepoundTable[0]
                                    .filter((item) => item.status === "yes")
                                    .map((item, index) => {
                                      return (
                                        <Row key={index}>
                                          <div>
                                            <span>
                                              ------------------------------------------------------------------------------------------------------------------------------------
                                            </span>
                                          </div>
                                          <Col md={12}>
                                            <Row>
                                              <Col md={4}>
                                                <div className="mb-3">
                                                  <span className="form-label">
                                                    Does the shareholder owns{" "}
                                                    {">"} 51%
                                                  </span>
                                                  {["radio"].map((type) => (
                                                    <div
                                                      key={`inline-${type}`}
                                                      className="radioBx"
                                                    >
                                                      <Form.Check
                                                        inline
                                                        label="Yes"
                                                        name={`owns`}
                                                        type={type}
                                                        value="Yes"
                                                        checked={
                                                          BatchConsigneeRequest[
                                                            index
                                                          ]?.shipperOwn ===
                                                          "Yes"
                                                        }
                                                        id={`inline-12`}
                                                        onChange={(event) =>
                                                          consgoineehandleOwnershipChange(
                                                            "Yes",
                                                            index,
                                                            item.ShipperName
                                                          )
                                                        }
                                                      />
                                                      <Form.Check
                                                        inline
                                                        label="No"
                                                        name={`owns`}
                                                        type={type}
                                                        value="No"
                                                        checked={
                                                          BatchConsigneeRequest[
                                                            index
                                                          ]?.shipperOwn === "No"
                                                        }
                                                        id={`inline-11`}
                                                        onChange={(event) =>
                                                          consgoineehandleOwnershipChange(
                                                            "No",
                                                            index,
                                                            item.ShipperName
                                                          )
                                                        }
                                                      />
                                                    </div>
                                                  ))}
                                                </div>
                                                {BatchConsigneeRequest[index]
                                                  ?.shipperOwn == "" && (
                                                  <div
                                                    style={{
                                                      fontFamily:
                                                        "AvenirLTProLight",
                                                      color: "#FF0000",
                                                      opacity: 0.6,
                                                    }}
                                                  >
                                                    {"Please Select Options"}
                                                  </div>
                                                )}
                                              </Col>
                                              <div className="my-2">
                                                <strong
                                                  style={{
                                                    fontSize: "25",
                                                    color: "#056aec",
                                                  }}
                                                >
                                                  {item.ShipperName}
                                                </strong>
                                              </div>
                                              <Col md={4}>
                                                <CustomForm
                                                  value={
                                                    BatchConsigneeRequest[index]
                                                      ?.ShipperRisk || ""
                                                  }
                                                  onChange={(e) =>
                                                    congsigneehandleInputChange(
                                                      index,
                                                      "ShipperRisk",
                                                      e.target.value,
                                                      item.ShipperName,
                                                      item.validate
                                                    )
                                                  }
                                                  color={"#222528"}
                                                  type="text"
                                                  lableText="Consignee Owner’s Name*"
                                                  placeholderText="Enter Consignee Owner’s Name"
                                                />
                                                {BatchConsigneeRequest[index]
                                                  ?.ShipperRisk == "" && (
                                                  <div
                                                    style={{
                                                      fontFamily:
                                                        "AvenirLTProLight",
                                                      color: "#FF0000",
                                                      opacity: 0.6,
                                                    }}
                                                  >
                                                    {
                                                      "Please enter your consignee owner’s name"
                                                    }
                                                  </div>
                                                )}
                                              </Col>

                                              <Col md={4}>
                                                <CustomForm
                                                  value={
                                                    BatchConsigneeRequest[index]
                                                      ?.Shipper_Owners_Address ||
                                                    ""
                                                  }
                                                  onChange={(e) =>
                                                    congsigneehandleInputChange(
                                                      index,
                                                      "Shipper_Owners_Address",
                                                      e.target.value,
                                                      item.ShipperName
                                                    )
                                                  }
                                                  color={"#222528"}
                                                  type="text"
                                                  lableText="Consignee Owner’s Address "
                                                  placeholderText="Enter consignee owner’s address "
                                                />
                                                {BatchConsigneeRequest[index]
                                                  ?.Shipper_Owners_Address ==
                                                  "" && (
                                                  <div
                                                    style={{
                                                      fontFamily:
                                                        "AvenirLTProLight",
                                                      color: "#FF0000",
                                                      opacity: 0.6,
                                                    }}
                                                  >
                                                    {
                                                      "Please enter your consignee owner’s address "
                                                    }
                                                  </div>
                                                )}
                                              </Col>
                                              <Col md={6}>
                                                <h3 className="s15">
                                                  Shipper’s Business Bank Name
                                                  Optional for all country
                                                  destinations except for
                                                  <span>
                                                    Russia, Belarus, Iran, Cuba,
                                                    Myanmar, Syria, Sudan, Iraq,
                                                    Libya, Somalia, Lebanon,
                                                    Yemen, Zimbabwe
                                                  </span>
                                                  – if shipment destination
                                                  these countries then Mandatory
                                                </h3>

                                                <div className="mb-3">
                                                  <span className="form-label">
                                                    Country of destinations to
                                                    these countries?
                                                  </span>
                                                  {["radio"].map((type) => (
                                                    <div
                                                      key={`inline-${type}`}
                                                      className="radioBx"
                                                    >
                                                      <Form.Check
                                                        inline
                                                        label="Yes"
                                                        name={`owns2`}
                                                        checked={
                                                          BatchConsigneeRequest[
                                                            index
                                                          ]?.bankCheck == "Yes"
                                                            ? 1
                                                            : ""
                                                        }
                                                        onChange={() =>
                                                          consgineeradioHandler(
                                                            "Yes",
                                                            index,
                                                            item.ShipperName
                                                          )
                                                        }
                                                        type={type}
                                                        id={`inline222`}
                                                      />
                                                      <Form.Check
                                                        inline
                                                        label="No"
                                                        // name="owns1"
                                                        type={type}
                                                        name={`owns1`}
                                                        checked={
                                                          BatchConsigneeRequest[
                                                            index
                                                          ]?.bankCheck == "No"
                                                            ? 1
                                                            : ""
                                                        }
                                                        onChange={() =>
                                                          consgineeradioHandler(
                                                            "No",
                                                            index,
                                                            item.ShipperName
                                                          )
                                                        }
                                                        id={`inline111`}
                                                      />
                                                    </div>
                                                  ))}
                                                </div>
                                              </Col>

                                              {BatchConsigneeRequest[index]
                                                ?.bankCheck == "" && (
                                                <div
                                                  style={{
                                                    fontFamily:
                                                      "AvenirLTProLight",
                                                    color: "#FF0000",
                                                    opacity: 0.6,
                                                  }}
                                                >
                                                  {
                                                    "Please select country of destinations"
                                                  }
                                                </div>
                                              )}
                                            </Row>
                                          </Col>

                                          {ConsigneeyesStatusIndices.includes(
                                            index
                                          ) && (
                                            <Col md={12}>
                                              <Row>
                                                <Col md={6}>
                                                  <CustomFormWithToolTip
                                                    color={"#222528"}
                                                    type="text"
                                                    toolTopContent="Shipper’s Business Bank Name
                                                  Optional for all country destinations
                                                  except for Russia, Belarus, Iran,
                                                  Cuba, Myanmar, Syria, Sudan, Iraq,
                                                  Libya, Somalia, Lebanon, Yemen,
                                                  Zimbabwe – if shipment destination
                                                  these countries then Mandatory"
                                                    lableText="Bank Name (optional except several countries)"
                                                    placeholderText="Enter Bank Name"
                                                    value={
                                                      BatchConsigneeRequest[
                                                        index
                                                      ]?.bankName || ""
                                                    }
                                                    onChange={(e) =>
                                                      congsigneehandleInputChange(
                                                        index,
                                                        "bankName",
                                                        e.target.value,
                                                        item.ShipperName
                                                      )
                                                    }
                                                  />
                                                  {BatchConsigneeRequest[index]
                                                    ?.bankName == "" && (
                                                    <div
                                                      style={{
                                                        fontFamily:
                                                          "AvenirLTProLight",
                                                        color: "#FF0000",
                                                        opacity: 0.6,
                                                      }}
                                                    >
                                                      {"Please enter bank name"}
                                                    </div>
                                                  )}
                                                </Col>

                                                <Col md={6}>
                                                  <CustomForm
                                                    color={"#222528"}
                                                    type="text"
                                                    lableText="Bank Address *"
                                                    placeholderText="Enter Bank Address"
                                                    value={
                                                      BatchConsigneeRequest[
                                                        index
                                                      ]?.bankAddress || ""
                                                    }
                                                    onChange={(e) =>
                                                      congsigneehandleInputChange(
                                                        index,
                                                        "bankAddress",
                                                        e.target.value,
                                                        item.ShipperName
                                                      )
                                                    }
                                                  />
                                                  {BatchConsigneeRequest[index]
                                                    ?.bankAddress == "" && (
                                                    <div
                                                      style={{
                                                        fontFamily:
                                                          "AvenirLTProLight",
                                                        color: "#FF0000",
                                                        opacity: 0.6,
                                                      }}
                                                    >
                                                      {
                                                        "Please enter bank address"
                                                      }
                                                    </div>
                                                  )}
                                                </Col>
                                              </Row>
                                            </Col>
                                          )}

                                          {ConsigneeFirstScreeningrespone ===
                                            "1" && (
                                            <>
                                              {consigneeshipperRiskData.map(
                                                (items, key) => {
                                                  const matchedShipper =
                                                    BatchConsigneeRequest[index]
                                                      ?.ShipperRisk ==
                                                    items.ShipperRisk;

                                                  if (matchedShipper) {
                                                    return (
                                                      <>
                                                        <small className="smallText">
                                                          Second Screening
                                                          Return Hit
                                                        </small>
                                                        <Row key={key}>
                                                          <Col md={6}>
                                                            <CustomForm
                                                              color={"#222528"}
                                                              type="date"
                                                              lableText={`Consignee DOB * (${items.name}) *`}
                                                              placeholderText={`Enter consignee DOB for ${items.name}`}
                                                              value={
                                                                BatchConsigneeRequest[
                                                                  index
                                                                ]?.shipperDOB ||
                                                                ""
                                                              }
                                                              onChange={(e) =>
                                                                congsigneehandleInputChange(
                                                                  index,
                                                                  "shipperDOB",
                                                                  e.target
                                                                    .value,
                                                                  item.ShipperName
                                                                )
                                                              }
                                                            />
                                                            {(ConsigneeFirstScreeningrespone ===
                                                              "1" &&
                                                            BatchConsigneeRequest[
                                                              index
                                                            ]?.shipperDOB == ""
                                                              ? (consgineeDoberr =
                                                                  "1")
                                                              : "") && (
                                                              <div
                                                                style={{
                                                                  fontFamily:
                                                                    "AvenirLTProLight",
                                                                  color:
                                                                    "#FF0000",
                                                                  opacity: 0.6,
                                                                }}
                                                              >
                                                                {`Please enter consignee (DOB) for ${items.name}`}
                                                              </div>
                                                            )}
                                                          </Col>
                                                          <Col md={6}>
                                                            <CustomFileUpload
                                                              color={"#222528"}
                                                              type="file"
                                                              lableText="Consignee Passport (optional)"
                                                              placeholderText="Upload Passport"
                                                              onChange={(e) =>
                                                                onChangeconsignne(
                                                                  e,
                                                                  index
                                                                )
                                                              }
                                                            />
                                                          </Col>
                                                        </Row>
                                                      </>
                                                    );
                                                  } else {
                                                    return null; // Render nothing if no matching ShipperRisk and shipperOwn 'Yes' found
                                                  }
                                                }
                                              )}
                                            </>
                                          )}

                                          {ConsigneeyesStatusIndices.includes(
                                            index
                                          ) && null}
                                        </Row>
                                      );
                                    })}
                              </>
                            ) : (
                              <></>
                            )}
                          </Col>
                        </div>
                      )}

                      <Button
                        onClick={() => {
                          excelSheetConsigneeScreeningAPi();
                        }}
                        className="btn btn-primary mb-4 mt-2 width_btn"
                      >
                        {Consigneeloader == true ? (
                          <Oval
                            height="30"
                            width="30"
                            radius="30"
                            color="#f2f5f3"
                            ariaLabel="loading"
                            wrapperStyle={{ marginLeft: 100 }}
                            wrapperClass
                          />
                        ) : (
                          <>Start Screening</>
                        )}
                      </Button>
                      <ToastContainer />
                    </Col>
                  </Row>
                </Accordion.Body>
              </Accordion.Item>
            </>
          ) : (
            <></>
          )}

          {excelSelectedFile && EndUserrisk.length > 0 ? (
            <>
              {" "}
              <Accordion.Item eventKey="0">
                <Accordion.Header>End User</Accordion.Header>
                <Accordion.Body>
                  <Row>
                    <Col md={12}>
                      <Row className="card-boxx card-boxx1 mb-3"></Row>
                      {(EndUserscreeningCheck.hitOne === EndUserScreening ||
                        EndUserscreeningCheck.hitSec === EndUserScreening) && (
                        <div>
                          <Col md={12}>
                            <div className="my-3">
                              <span className="rejected mx-4">{`${EndUserrisk}`}</span>
                              {/* <span className="rejected mx-4">
                                UK Sanctioned List
                              </span>
                              <span className="rejected">
                                EU Sanctioned List
                              </span> */}
                            </div>
                          </Col>

                          <Col md={12}>
                            <small className="smallText">
                              First Screening Return Hit
                            </small>
                            <Row className="align-items-end">
                              <Col md={6}>
                                <Card className="mb-3 card_Block card_BlockBordeColor card_BlockBordeColor1">
                                  <Table responsive className="cont_table1">
                                    <tbody>
                                      <tr>
                                        <td>
                                          <strong>Name</strong>
                                        </td>
                                        <td className="text-center">
                                          <strong>Risk</strong>
                                        </td>
                                        <td className="text-center">
                                          <strong>No Risk</strong>
                                        </td>
                                      </tr>

                                      {EndUserrepoundTable.length > 0 ? (
                                        <>
                                          {EndUserrepoundTable[0].map(
                                            (item, index) => {
                                              if (!item.ShipperName)
                                                return null;

                                              return (
                                                <tr key={index}>
                                                  <td>{`${item.ShipperName}`}</td>

                                                  {item.status == "yes" ? (
                                                    <>
                                                      <td className="text-center">
                                                        <span className="borderRight">
                                                          <OverlayTrigger
                                                            overlay={
                                                              <Tooltip className="overUk">
                                                                {`${item.risk}`}
                                                              </Tooltip>
                                                            }
                                                          >
                                                            <Image
                                                              src={RiskIcon}
                                                              alt=""
                                                            />
                                                          </OverlayTrigger>
                                                        </span>
                                                      </td>
                                                    </>
                                                  ) : (
                                                    <td className="text-center"></td>
                                                  )}

                                                  {item.status == "No" ? (
                                                    <>
                                                      <td className="text-center">
                                                        <span className="borderRight">
                                                          <OverlayTrigger
                                                            overlay={
                                                              <Tooltip className="overUk">
                                                                {`${"No Risk"}`}
                                                              </Tooltip>
                                                            }
                                                          >
                                                            <Image
                                                              src={RiskIcon}
                                                              alt=""
                                                            />
                                                          </OverlayTrigger>
                                                        </span>
                                                      </td>
                                                    </>
                                                  ) : (
                                                    <td className="text-center"></td>
                                                  )}
                                                </tr>
                                              );
                                            }
                                          )}
                                        </>
                                      ) : (
                                        <></>
                                      )}
                                    </tbody>
                                  </Table>
                                </Card>
                              </Col>
                            </Row>

                            {EndUserriskststus == "1" ? (
                              <>
                                {EndUserrepoundTable &&
                                  EndUserrepoundTable[0]
                                    .filter((item) => item.status === "yes")
                                    .map((item, index) => {
                                      return (
                                        <Row key={index}>
                                          <div>
                                            <span>
                                              ------------------------------------------------------------------------------------------------------------------------------------
                                            </span>
                                          </div>
                                          <Col md={12}>
                                            <Row>
                                              <Col md={4}>
                                                <div className="mb-3">
                                                  <span className="form-label">
                                                    Does the shareholder owns{" "}
                                                    {">"} 51%
                                                  </span>
                                                  {["radio"].map((type) => (
                                                    <div
                                                      key={`inline-${type}`}
                                                      className="radioBx"
                                                    >
                                                      <Form.Check
                                                        inline
                                                        label="Yes"
                                                        name={`owns33`}
                                                        type={type}
                                                        value="Yes"
                                                        checked={
                                                          BatchEndUserRequest[
                                                            index
                                                          ]?.shipperOwn ===
                                                          "Yes"
                                                        }
                                                        id={`inline77`}
                                                        onChange={(event) =>
                                                          EndUserhandleOwnershipChange(
                                                            event,
                                                            index,
                                                            item.ShipperName
                                                          )
                                                        }
                                                      />
                                                      <Form.Check
                                                        inline
                                                        label="No"
                                                        name={`owns44`}
                                                        type={type}
                                                        value="No"
                                                        checked={
                                                          BatchEndUserRequest[
                                                            index
                                                          ]?.shipperOwn === "No"
                                                        }
                                                        id={`inline66`}
                                                        onChange={(event) =>
                                                          EndUserhandleOwnershipChange(
                                                            event,
                                                            index,
                                                            item.ShipperName
                                                          )
                                                        }
                                                      />
                                                    </div>
                                                  ))}
                                                </div>
                                                {BatchEndUserRequest[index]
                                                  ?.shipperOwn == "" && (
                                                  <div
                                                    style={{
                                                      fontFamily:
                                                        "AvenirLTProLight",
                                                      color: "#FF0000",
                                                      opacity: 0.6,
                                                    }}
                                                  >
                                                    {"Please Select Options"}
                                                  </div>
                                                )}
                                              </Col>
                                              <div className="my-2">
                                                <strong
                                                  style={{
                                                    fontSize: "25",
                                                    color: "#056aec",
                                                  }}
                                                >
                                                  {item.ShipperName}
                                                </strong>
                                              </div>
                                              <Col md={4}>
                                                <CustomForm
                                                  value={
                                                    BatchEndUserRequest[index]
                                                      ?.ShipperRisk || ""
                                                  }
                                                  onChange={(e) =>
                                                    EnduSERhandleInputChange(
                                                      index,
                                                      "ShipperRisk",
                                                      e.target.value,
                                                      item.ShipperName,
                                                      item.validate
                                                    )
                                                  }
                                                  color={"#222528"}
                                                  type="text"
                                                  lableText="End User Owner’s Name*"
                                                  placeholderText="Enter End User Owner’s Name"
                                                />
                                                {BatchEndUserRequest[index]
                                                  ?.ShipperRisk == "" && (
                                                  <div
                                                    style={{
                                                      fontFamily:
                                                        "AvenirLTProLight",
                                                      color: "#FF0000",
                                                      opacity: 0.6,
                                                    }}
                                                  >
                                                    {
                                                      "Please enter end user owner’s name"
                                                    }
                                                  </div>
                                                )}
                                              </Col>

                                              <Col md={4}>
                                                <CustomForm
                                                  value={
                                                    BatchEndUserRequest[index]
                                                      ?.Shipper_Owners_Address ||
                                                    ""
                                                  }
                                                  onChange={(e) =>
                                                    EnduSERhandleInputChange(
                                                      index,
                                                      "Shipper_Owners_Address",
                                                      e.target.value,
                                                      item.ShipperName
                                                    )
                                                  }
                                                  color={"#222528"}
                                                  type="text"
                                                  lableText="End User Owner’s Address "
                                                  placeholderText="Enter End User Owner’s Address"
                                                />
                                                {BatchEndUserRequest[index]
                                                  ?.Shipper_Owners_Address ==
                                                  "" && (
                                                  <div
                                                    style={{
                                                      fontFamily:
                                                        "AvenirLTProLight",
                                                      color: "#FF0000",
                                                      opacity: 0.6,
                                                    }}
                                                  >
                                                    {
                                                      "Please enter end user owner’s address"
                                                    }
                                                  </div>
                                                )}
                                              </Col>
                                              <Col md={6}>
                                                <h3 className="s15">
                                                  Shipper’s Business Bank Name
                                                  Optional for all country
                                                  destinations except for
                                                  <span>
                                                    Russia, Belarus, Iran, Cuba,
                                                    Myanmar, Syria, Sudan, Iraq,
                                                    Libya, Somalia, Lebanon,
                                                    Yemen, Zimbabwe
                                                  </span>
                                                  – if shipment destination
                                                  these countries then Mandatory
                                                </h3>

                                                <div className="mb-3">
                                                  <span className="form-label">
                                                    Country of destinations to
                                                    these countries?
                                                  </span>
                                                  {["radio"].map((type) => (
                                                    <div
                                                      key={`inline-${type}`}
                                                      className="radioBx"
                                                    >
                                                      <Form.Check
                                                        inline
                                                        label="Yes"
                                                        name={`owns88`}
                                                        checked={
                                                          BatchEndUserRequest[
                                                            index
                                                          ]?.bankCheck == "Yes"
                                                            ? 1
                                                            : ""
                                                        }
                                                        onChange={() =>
                                                          EndUserradioHandler(
                                                            "Yes",
                                                            index,
                                                            item.ShipperName
                                                          )
                                                        }
                                                        type={type}
                                                        id={`inline-99`}
                                                      />
                                                      <Form.Check
                                                        inline
                                                        label="No"
                                                        // name="owns1"
                                                        type={type}
                                                        name={`owns77`}
                                                        checked={
                                                          BatchEndUserRequest[
                                                            index
                                                          ]?.bankCheck == "No"
                                                            ? 1
                                                            : ""
                                                        }
                                                        onChange={() =>
                                                          EndUserradioHandler(
                                                            "No",
                                                            index,
                                                            item.ShipperName
                                                          )
                                                        }
                                                        id={`inline98`}
                                                      />
                                                    </div>
                                                  ))}
                                                </div>
                                              </Col>

                                              {BatchEndUserRequest[index]
                                                ?.bankCheck == "" && (
                                                <div
                                                  style={{
                                                    fontFamily:
                                                      "AvenirLTProLight",
                                                    color: "#FF0000",
                                                    opacity: 0.6,
                                                  }}
                                                >
                                                  {
                                                    "Please select country of destinations"
                                                  }
                                                </div>
                                              )}
                                              {/* {BankCheckerr && Submit && !BankCheck ? (
<div
style={{
fontFamily: "AvenirLTProLight",
color: "#FF0000",
opacity: 0.6,
}}
>
{BankCheckerr}
</div>
) : null} */}
                                            </Row>
                                          </Col>

                                          {EndUseryesStatusIndices.includes(
                                            index
                                          ) && (
                                            <Col md={12}>
                                              <Row>
                                                <Col md={6}>
                                                  <CustomFormWithToolTip
                                                    color={"#222528"}
                                                    type="text"
                                                    toolTopContent="Shipper’s Business Bank Name
Optional for all country destinations
except for Russia, Belarus, Iran,
Cuba, Myanmar, Syria, Sudan, Iraq,
Libya, Somalia, Lebanon, Yemen,
Zimbabwe – if shipment destination
these countries then Mandatory"
                                                    lableText="Bank Name (optional except several countries)"
                                                    placeholderText="Enter Bank Name"
                                                    value={
                                                      BatchEndUserRequest[index]
                                                        ?.bankName || ""
                                                    }
                                                    onChange={(e) =>
                                                      EnduSERhandleInputChange(
                                                        index,
                                                        "bankName",
                                                        e.target.value,
                                                        item.ShipperName
                                                      )
                                                    }
                                                  />
                                                  {BatchEndUserRequest[index]
                                                    ?.bankName == "" && (
                                                    <div
                                                      style={{
                                                        fontFamily:
                                                          "AvenirLTProLight",
                                                        color: "#FF0000",
                                                        opacity: 0.6,
                                                      }}
                                                    >
                                                      {"Please enter bank name"}
                                                    </div>
                                                  )}
                                                </Col>

                                                <Col md={6}>
                                                  <CustomForm
                                                    color={"#222528"}
                                                    type="text"
                                                    lableText="Bank Address *"
                                                    placeholderText="Enter Bank Address"
                                                    value={
                                                      BatchEndUserRequest[index]
                                                        ?.bankAddress || ""
                                                    }
                                                    onChange={(e) =>
                                                      EnduSERhandleInputChange(
                                                        index,
                                                        "bankAddress",
                                                        e.target.value,
                                                        item.ShipperName
                                                      )
                                                    }
                                                  />
                                                  {BatchEndUserRequest[index]
                                                    ?.bankAddress == "" && (
                                                    <div
                                                      style={{
                                                        fontFamily:
                                                          "AvenirLTProLight",
                                                        color: "#FF0000",
                                                        opacity: 0.6,
                                                      }}
                                                    >
                                                      {
                                                        "Please enter bank address"
                                                      }
                                                    </div>
                                                  )}
                                                  {/* {BankAdresserr && Submit && !BankAdress ? (
<div
style={{
fontFamily: "AvenirLTProLight",
color: "#FF0000",
opacity: 0.6,
}}
>
{BankAdresserr}
</div>
) : null} */}
                                                </Col>
                                              </Row>
                                            </Col>
                                          )}

                                          {EndUserScreeningrespone === "1" && (
                                            <>
                                              {EndUsershipperRiskData.map(
                                                (items, key) => {
                                                  const matchedShipper =
                                                    BatchEndUserRequest[index]
                                                      ?.ShipperRisk ==
                                                    items.ShipperRisk;

                                                  if (matchedShipper) {
                                                    return (
                                                      <>
                                                        <small className="smallText">
                                                          Second Screening
                                                          Return Hit
                                                        </small>
                                                        <Row key={key}>
                                                          <Col md={6}>
                                                            <CustomForm
                                                              color={"#222528"}
                                                              type="date"
                                                              lableText={`End User DOB * (${items.name}) *`}
                                                              placeholderText={`Enter End User DOB * for ${items.name}`}
                                                              value={
                                                                BatchEndUserRequest[
                                                                  index
                                                                ]?.shipperDOB ||
                                                                ""
                                                              }
                                                              onChange={(e) =>
                                                                EnduSERhandleInputChange(
                                                                  index,
                                                                  "shipperDOB",
                                                                  e.target
                                                                    .value,
                                                                  item.ShipperName
                                                                )
                                                              }
                                                            />
                                                            {(EndUserScreeningrespone ===
                                                              "1" &&
                                                            BatchEndUserRequest[
                                                              index
                                                            ]?.shipperDOB == ""
                                                              ? endUserDoberr ==
                                                                "1"
                                                              : "") && (
                                                              <div
                                                                style={{
                                                                  fontFamily:
                                                                    "AvenirLTProLight",
                                                                  color:
                                                                    "#FF0000",
                                                                  opacity: 0.6,
                                                                }}
                                                              >
                                                                {`Please enter end user (DOB) for ${items.name}`}
                                                              </div>
                                                            )}
                                                          </Col>
                                                          <Col md={6}>
                                                            <CustomFileUpload
                                                              color={"#222528"}
                                                              type="file"
                                                              lableText="End User Passport (optional)"
                                                              placeholderText="Upload Passport"
                                                              onChange={(e) =>
                                                                onChangeEndUser(
                                                                  e,
                                                                  index
                                                                )
                                                              }
                                                            />
                                                          </Col>
                                                        </Row>
                                                      </>
                                                    );
                                                  } else {
                                                    return null;
                                                  }
                                                }
                                              )}
                                            </>
                                          )}
                                        </Row>
                                      );
                                    })}
                              </>
                            ) : (
                              <></>
                            )}
                          </Col>
                        </div>
                      )}

                      <Button
                        onClick={() => {
                          excelSheetEndUserScreeningAPi();
                        }}
                        className="btn btn-primary mb-4 mt-2 width_btn"
                      >
                        {EndUserloader == true ? (
                          <Oval
                            height="30"
                            width="30"
                            radius="30"
                            color="#f2f5f3"
                            ariaLabel="loading"
                            wrapperStyle={{ marginLeft: 100 }}
                            wrapperClass
                          />
                        ) : (
                          <>Start Screening</>
                        )}
                      </Button>
                      <ToastContainer />
                    </Col>
                  </Row>
                </Accordion.Body>
              </Accordion.Item>
            </>
          ) : (
            <></>
          )}
        </Accordion>
      </>
    );
  };
  const Step2Content = () => {
    const [rows, setRows] = useState([
      {
        hsCode: "",
        goodsDescription: "",
        chemicalDangerios: "",
        DualUseExport: "",
      },
    ]);

    const [hsloader, setloader] = useState(false);

    const [Submitloader, setSubmitloader] = useState(false);

    const [rowshscode, setRowshscode] = useState("");
    const [addrowstatus, setaddrowstatusl] = useState(0);
    const [Deleterow, setDeleterow] = useState(0);

    const [HsCodeSearch, setHsCodeSearch] = useState("");
    const [currentIndex, setCurrentIndex] = useState(null);

    const [excelSelectedFile, setexcelSelectedFile] = useState(null);

    const [ChemicalselectedValue, setChemicalselectedValue] = useState("");
    const [DangerousselectedValue, setDangerousselectedValue] = useState("");
    const [ControlSselectedValue, setControlSselectedValue] = useState("");

    const [selectedOption, setSelectedOption] = useState("");
    const [DualSelectedOption, setDualSelectedOption] = useState("");

    const [deleteIconhide, setdeleteIconhide] = useState(false);

    const [buttonEnable, setbuttonEnable] = useState(false);

    const handleSelectChange = (e) => {
      setChemicalselectedValue(e.target.value);
    };

    const handleSelectChanges = (event) => {
      setSelectedOption(event.target.value);
    };
    const handleDualSelectChanges = (event) => {
      setDualSelectedOption(event.target.value);
    };

    const handleDownload = () => {
      // Create a temporary link element
      const link = document.createElement("a");
      link.href = `${API.BASE_URL}server/data/accelBackend/DownloadSampleHscode.xlsx`;

      // Set the desired file name here
      const fileName = "DownlaodSampleHscode.xlsx";

      // Set the download attribute to the desired file name
      link.setAttribute("download", fileName);

      // Append the link to the body
      document.body.appendChild(link);

      // Trigger the click event on the link
      link.click();

      // Clean up: remove the link from the DOM
      document.body.removeChild(link);
    };

    const handleNewRows = () => {
      let newRows = []; // Create a new array to hold the new rows

      for (let i = 0; i < rows.length; i++) {
        const item = rows[i];
        if (item.hsCode === "") {
          newRows.push(item.goodsDescription);
          toast("Please fill in the HS code");
          return; // Stop code execution here
        } else if (item.goodsDescription === "") {
          newRows.push(item.hsCode);
          toast("Please fill in the goods description");
          return; // Stop code execution here
        } else if (item.chemicalDangerios === "") {
          newRows.push(item.chemicalDangerios);
          toast("Please select chemical or dangerous Good");
          return; // Stop code execution here
        } else if (item.DualUseExport === "") {
          newRows.push(item.DualUseExport);
          toast("Please select dual use export controlled goods");
          return; // Stop code execution here
        } else {
          newRows.push(item); // Add the current item to the new array
        }
      }
      // Check if new rows need to be added
      if (addrowstatus === 0) {
        newRows.push({
          hsCode: "",
          goodsDescription: "",
          chemicalDangerios: "",
          DualUseExport: "",
        });
      }

      // Update the state with the new array
      setRows(newRows);

      // Update the state with the new array
    };

    const hadleSelectedtName = (item) => {
      setRowshscode(item);
    };

    // const handleChange = async (index, fieldName, value) => {

    //   setCurrentIndex(index);

    //   const updatedRows = [...rows];
    //   updatedRows[index][fieldName] = value;

    //   // If the fieldName is 'hsCode', do not modify 'goodsDescription'
    //   if (fieldName === 'hsCode') {
    //     setRowshscode(value);
    //     setCurrentIndex(index);
    //   } else if (fieldName === 'goodsDescription') {
    //     // If the fieldName is 'goodsDescription', do not modify 'hsCode'
    //     setCurrentIndex(null);
    //   }

    //   setRows(updatedRows, () => {
    //     const debouncedSetRows = debounce(setRows);
    //     debouncedSetRows(updatedRows);
    //   });
    // };

    const handleChange = async (index, fieldName, value) => {
      setCurrentIndex(index);

      const updatedRows = [...rows];
      updatedRows[index][fieldName] = value;

      if (fieldName === "hsCode") {
        setRowshscode(value);
        setCurrentIndex(index);
      } else if (fieldName === "goodsDescription") {
        setRowshscode(value);
        setCurrentIndex(index);
      }

      // Add selected option value to chemicalDangerios and DualUseExport fields
      if (fieldName === "selectedOption") {
        updatedRows[index].chemicalDangerios = value === "Yes" ? "Yes" : "No";
      } else if (fieldName === "DualSelectedOption") {
        updatedRows[index].DualUseExport = value === "Yes" ? "Yes" : "No";
      }

      setRows(updatedRows, () => {
        const debouncedSetRows = debounce(setRows);
        debouncedSetRows(updatedRows);
      });
    };

    const handleFileChange = (event) => {
      const file = event.target.files[0];
      setexcelSelectedFile(file);

      HsCodeSearchExcelUpload(file);
    };

    const handleDeleteRow = (index) => {
      if (index > 0 && Deleterow == 0) {
        const updatedRows = rows.filter((_, idx) => idx !== index);
        setRows(updatedRows);
      }
    };
    const getHsCodesearch = async (index) => {
      setloader(true);

      const hsCodeToSearch = rows[index].hsCode;
      const goodsDescriptionSerach = rows[index].goodsDescription;

      const chemicalDangeriosSerach = rows[index].chemicalDangerios;
      const DualUseExportSerach = rows[index].DualUseExport; // Get the HS code from the specific row
      // Get the HS code from the specific row
      // Get the HS code from the specific row DualUseExport
      // Get the HS code from the specific row

      await fetch(`${API.BASE_URL}hscodeSearchDeatil`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          hsCodeNumber: hsCodeToSearch,
          description: goodsDescriptionSerach,
        }),
      })
        .then((response) => response.json())
        .then(async (res) => {
          setloader(false);
          if (res.status && res.data.length > 0) {
            const { hsCode, description } = res.data[0];
            const updatedRows = [...rows];
            updatedRows[index] = {
              hsCode: hsCode,
              goodsDescription: description,
            };
            setRows(updatedRows);
          } else if (hsCodeToSearch !== "") {
            const updatedRows = [...rows];
            updatedRows[index] = {
              hsCode: hsCodeToSearch,
              goodsDescription: goodsDescriptionSerach,
              chemicalDangerios: chemicalDangeriosSerach,
              DualUseExport: DualUseExportSerach,
            };
            setRows(updatedRows);
          }

          setHsCodeSearch([res.data[0].hsCode]);
        })
        .catch((error) => {});
    };

    const HsCodeSearchExcelUpload = async (file) => {
      const userData = localStorage.getItem("userDetail");
      if (userData) {
        var data = JSON.parse(userData);
      }
      let excelData = new FormData();
      excelData.append("attachments", file ? file : excelSelectedFile);

      var requestOptions = {
        method: "POST",
        body: excelData,
      };

      const response = await fetch(
        `${API.BASE_URL}uploadHsCodeSearchExcelFile`,
        requestOptions
      )
        .then((response) => response.json())
        .then(async (res) => {
          if (res.success && Array.isArray(res.data)) {
            // Map over the data array and create new rows
            const newRows = res.data.map((item) => ({
              hsCode: item.HS6,
              goodsDescription: item.Description,
              chemicalDangerios: "",
              DualUseExport: "",
            }));

            // Check if the initial empty object is present
            const hasInitialEmptyObject =
              rows.length > 0 &&
              rows[0].hsCode === "" &&
              rows[0].goodsDescription === "" &&
              rows[0].chemicalDangerios === "" &&
              rows[0].DualUseExport === "";

            // Concatenate the new rows with the existing rows state
            setRows((prevRows) =>
              hasInitialEmptyObject
                ? [...prevRows.slice(1), ...newRows] // Exclude the initial empty object
                : [...prevRows, ...newRows]
            ); // Include the initial empty object
          }
        });
    };
    const HsCodeSeacrh = async () => {
      setSubmitloader(true);
      const userData = localStorage.getItem("userDetail");
      const reference = localStorage.getItem("reference");

      if (!userData || !reference) {
        console.error("User data or reference not found in localStorage.");
        return; // Exit function if required data is missing
      }

      const data = JSON.parse(userData);
      const dataReference = JSON.parse(reference);

      const isValidHSCode = (hsCode) => {
        console.log("hsCode:", hsCode);
        const hsCodeStr = hsCode.toString();

        // Check if the HS code contains a decimal point
        if (hsCodeStr.includes(".")) {
          console.log("HS code contains a decimal point.");
          // Remove the decimal point and check if the resulting string is 6 digits
          return /^\d{6}$/.test(hsCodeStr.replace(/\./g, ""));
        } else {
          console.log("HS code does not contain a decimal point.");
          // Check if the HS code is exactly 6 digits
          return /^\d{6}$/.test(hsCodeStr);
        }
      };

      // Check if any HS code is invalid
      const invalidHSCodeRows = rows.filter(
        (item) => !isValidHSCode(item.hsCode)
      );
      if (invalidHSCodeRows.length > 0) {
        invalidHSCodeRows.forEach((invalidRow) => {
          setSubmitloader(false);
          toast(
            `Invalid HS code: ${invalidRow.hsCode}. HS code must be exactly 6 digits long.`
          );
        });
        return; // Exit function if there are invalid HS codes
      }

      // Check if any field in the rows is empty
      const isAnyFieldEmpty = rows.some((row) =>
        Object.values(row).some((value) => value === "")
      );
      if (isAnyFieldEmpty) {
        setSubmitloader(false);
        toast("One or more fields in the rows are empty.");
        return; // Exit function if any field is empty
      }

      const payload = {
        hscodeType: rows,
        reference: dataReference,
        userId: data._id,
      };

      try {
        const response = await fetch(
          `${API.BASE_URL}addHsCodeverificationData`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
            },
            mode: "cors",
            body: JSON.stringify(payload),
          }
        );

        const res = await response.json();
        setSubmitloader(false);
        if (res.status === true) {
          localStorage.setItem(
            "hsCodeVerficationData",
            JSON.stringify(res.data)
          );
          setbuttonEnable(true);
          setaddrowstatusl(1);
          setDeleterow(1);
          setHSCodeVerification(true);
          setdeleteIconhide(true);
          toast("Submit successful. You can proceed to the next step.");
        } else {
          console.error("API returned false status.");
        }
      } catch (error) {
        console.error("Error in API call:", error);
      }
    };

    //   const HsCodeSeacrh = async () => {
    //     const userData = localStorage.getItem("userDetail");
    //     const reference = localStorage.getItem("reference");

    //     if (userData) {
    //       var data = JSON.parse(userData);
    //       var dataReference = JSON.parse(reference);
    //     }
    //     const isValidHSCode = (hsCode) => {
    //       return /^\d{6}$/.test(hsCode); // Checks if hsCode is exactly 6 digits long
    //     };
    //     // Define your rows array here
    //     rows.forEach((item) => {
    //       if (!isValidHSCode(item.hsCode)) {
    //  return  toast(`Invalid HS code: ${item.hsCode}. HS code must be 6 digits long.`);
    //         // Handle the invalid HS code case here (e.g., show an error message)
    //       } else {
    //         // HS code is valid, proceed with processing

    //         // Process the data further
    //       }
    //     });
    //     const isAnyFieldEmpty = rows.some((row) =>
    //       Object.values(row).some((value) => value === "")
    //     );

    //     if (isAnyFieldEmpty) {
    //       toast("One or more fields in the rows are empty.");
    //       return; // Stop execution if any field is empty
    //     }

    //     let payload = {
    //       hscodeType: rows,
    //       reference: dataReference,
    //       userId: data._id,
    //     };

    //     try {
    //       const response = await fetch(
    //         `${API.BASE_URL}addHsCodeverificationData`,
    //         {
    //           method: "POST",
    //           headers: {
    //             "Content-Type": "application/json",
    //             Accept: "application/json",
    //           },
    //           mode: "cors",
    //           body: JSON.stringify({
    //             hscodeType: rows,
    //             reference: dataReference,
    //             userId: data._id,
    //           }),
    //         }
    //       );

    //       const res = await response.json();

    //       if (res.status === true) {
    //         await localStorage.setItem(
    //           "hsCodeVerficationData",
    //           JSON.stringify(res.data)
    //         );
    //         setaddrowstatusl(1);
    //         setDeleterow(1);
    //         setHSCodeVerification(true);
    //         toast("Submit successfully, You can proceed to next");
    //       } else {
    //         console.error("API returned false status.");
    //       }
    //     } catch (error) {
    //       console.error("Error in API call:", error);
    //     }
    //   };

    const getHsCodeSearch = async (req, res) => {
      const userData = localStorage.getItem("userDetail");
      const reference = localStorage.getItem("reference");
      const hscodeVerfication = localStorage.getItem("hsCodeVerficationData");

      // if (reference) {
      // } else {
      //   localStorage.removeItem("hsCodeVerficationData");
      // }
      if (userData) {
        var data = JSON.parse(userData);
        var dataReference = JSON.parse(reference);
        var hsCodeverfication = JSON.parse(hscodeVerfication);

        // sethscodeid(hsCodeverfication ? hsCodeverfication._id : []);
      }
      if (hsCodeverfication && hsCodeverfication.hscodeType) {
        // Create a new array to store the rows
        const newRows = [];
        hsCodeverfication.hscodeType.forEach((item) => {
          // Add each object from hsCodeverfication.hscodeType to newRows
          newRows.push({
            hsCode: item.hsCode,
            goodsDescription: item.goodDescription,
            chemicalDangerios: item.chemicalDangerios,
            DualUseExport: item.DualUseExport,
          });
        });
        // Set the rows state to the newRows array
        setRows(newRows);
      }
    };

    const sampleFileUrl = "";

    useEffect(() => {
      if (rowshscode !== "" && currentIndex !== null) {
        // getHsCodesearch(currentIndex); // Pass the current index to the function
      }
    }, [rowshscode, currentIndex]);

    useEffect(() => {
      // getECCNcodesearch();
      getHsCodeSearch();
    }, []);

    console.log("rowshscode", rowshscode);
    var noteValidate = JSON.parse(
      localStorage.getItem("hsCodeVerficationData")
    );

    return (
      <>
        <div className="d-flex align-items-center">
          <h2 className="rpsHeading">HS Code Verification</h2>
          <div className="upload-btn-wrapper d-flex align-items-center">
            <div class="uploadBtn uploadBtn1">
              <Button
                className="uploadBtn uploadBtn1"
                onClick={() => handleFileChange()}
                disabled={buttonEnable}
              >
                <Icon icon="carbon:add-filled" fontSize={18} /> File Upload
              </Button>
              <input
                type="file"
                name="myfile"
                accept=".xls,.xlsx"
                onChange={handleFileChange}
              />
            </div>
          </div>

          {/* <a href="/Testinghscode.xlsx" download className="textDeco ms-2">
      Download Sample
    </a> */}
          <Button
            variant="primary"
            className="w-25"
            size="md"
            onClick={handleDownload}
          >
            Download Sample
          </Button>

          <div className="ms-auto d-flex align-items-center">
            <Button
              variant="primary"
              size="md"
              className="me-1 wfix"
              onClick={() => {
                handleNewRows();
              }}
              disabled={buttonEnable}
            >
              <Icon icon="pixelarticons:text-add" fontSize={18} />
              Add Row
            </Button>
          </div>
        </div>

        <>
          {rows.map((row, index) => (
            <div key={index}>
              <Row className="mb-3 mb-lg-2">
                <Col md={12}>
                  <div className="scree_btn scree_btnShrink">
                    <CustomFormIcon
                      color={"#222528"}
                      type="text"
                      lableText="HS Code Search (6 digits) *"
                      value={row.hsCode}
                      onChange={(e) =>
                        handleChange(index, "hsCode", e.target.value)
                      }
                      placeholderText="Enter HS Code Search"
                    />
                    <span className="orHeading mx-3"> OR </span>
                    <div className="scree_btn scree_btn1 w-100">
                      <CustomForm
                        color={"#222528"}
                        type="text"
                        lableText="Goods Description *"
                        value={row.goodsDescription}
                        onChange={(e) => {
                          handleChange(
                            index,
                            "goodsDescription",
                            e.target.value
                          );
                        }}
                        placeholderText="If HS Code is unknown enter goods description"
                      />
                    </div>
                    <div className="scree_btn scree_btn1 wWidth ms-4">
                      <Form.Group className="mb-3">
                        <Form.Label>Chemical Or Dangerous Good *</Form.Label>
                        <select
                          className="form-control"
                          value={row.selectedOption || row.chemicalDangerios}
                          onChange={(e) =>
                            handleChange(
                              index,
                              "selectedOption",
                              e.target.value
                            )
                          }
                        >
                          <option value="">Select...</option>
                          <option value="Yes">Yes</option>
                          <option value="No">No</option>
                        </select>
                      </Form.Group>
                    </div>

                    <div className="scree_btn scree_btn1 wWidth">
                      <Form.Group className="mb-3">
                        <Form.Label>
                          Dual Use Export Controlled Goods *
                        </Form.Label>
                        <select
                          className="form-control"
                          value={row.DualSelectedOption || row.DualUseExport}
                          onChange={(e) =>
                            handleChange(
                              index,
                              "DualSelectedOption",
                              e.target.value
                            )
                          }
                        >
                          <option value="">Select...</option>
                          <option value="Yes">Yes</option>
                          <option value="No">No</option>
                        </select>
                      </Form.Group>
                    </div>
                    {deleteIconhide != true ? (
                      <>
                        {index != 0 ? (
                          <Button
                            variant="primary"
                            size="md"
                            className="mr-1"
                            style={{ minWidth: 100 }}
                            onClick={() => handleDeleteRow(index)}
                          >
                            <Icon icon="pixelarticons:text-add" fontSize={18} />
                            Delete
                          </Button>
                        ) : (
                          ""
                        )}
                      </>
                    ) : (
                      ""
                    )}

                    <ToastContainer />
                  </div>
                </Col>
              </Row>
            </div>
          ))}
          <div className="text-center mt-4 d-flex justify-content-center align-items-center">
            <Button
              variant="warning"
              size="md"
              className="btn btn-primary  width_btn me-2"
              onClick={() => HsCodeSeacrh()}
              disabled={buttonEnable}
            >
              {Submitloader == true ? (
                <Oval
                  height="30"
                  width="30"
                  radius="30"
                  color="#f2f5f3"
                  ariaLabel="loading"
                  wrapperStyle={{ marginLeft: 120 }}
                  wrapperClass
                />
              ) : (
                <>Submit</>
              )}
            </Button>
            <Button
              variant="warning"
              size="md"
              className="btn btn-primary  width_btn"
              onClick={() => {
                if (rowshscode !== "" && currentIndex !== null) {
                  getHsCodesearch(currentIndex); // Pass the current index to the function
                }
              }}
              disabled={buttonEnable}
            >
              {hsloader == true ? (
                <Oval
                  height="30"
                  width="30"
                  radius="30"
                  color="#f2f5f3"
                  ariaLabel="loading"
                  wrapperStyle={{ marginLeft: 120 }}
                  wrapperClass
                />
              ) : (
                <>HS Code Search</>
              )}
            </Button>
          </div>
          {noteValidate && (
                        <div className="noteBox mt-4" >
                          <span>Note:</span> For continue need to click on
                          Screening button
                        </div>
                      )}
        </>

        {/* <div className="arrowUpDown">
          <Icon
            icon="fluent:chevron-down-12-filled"
            onClick={() => setOpen(!open)}
            fontSize={18}
          />
          <Icon icon="fluent:chevron-up-12-filled" fontSize={18} />
        </div> */}
      </>
    );
  };

  const Step3Content = () => {
    const [Submitloader, setSubmitloader] = useState(false);

    const [Submit, setSubmit] = useState(false);
    const [showDropdown, setShowDropdown] = useState(true);
    const [hsCodeHide, sethsCodeShow] = useState(false);
    const [hscodeid, sethscodeid] = useState("");
    const [hsCodeVerificationData, sethsCodeVerificationData] = useState([]);
    const [CountryOrigin, setCountryOrigin] = useState("");
    const [DestinationOrigin, setDestinationOrigin] = useState("");
    const [CountryOrigin1, setCountryOrigin1] = useState("");
    const [DestinationOrigin1, setDestinationOrigin1] = useState("");
    const [TransDestinationOrigin, setTransDestinationOrigin] = useState("");

    const [Trans_shipment, setTrans_shipment] = useState("");
    const [Trans_shipment1, setTrans_shipment1] = useState("");
    const [Trans_shipment11, setTrans_shipment11] = useState("");

    const [Trans_shipmentDescription, setTrans_shipmentDescription] =
      useState("");
    const [Trans_shipmentDescription1, setTrans_shipmentDescription2] =
      useState("");

    const [
      Trans_shipmentDescriptionmanual,
      setTrans_shipmentDescriptionmanual,
    ] = useState("");

    const [countryCode, setcountrycode] = useState([
      {
        _id: "",
        country: "",
      },
    ]);
    const [loader, setloader] = useState(false);
    const [currentForm, setcurrentForm] = useState(0);

    const [CountryOriginerr, setCountryOriginerr] = useState("");
    const [DestinationOriginerr, setDestinationOriginerr] = useState("");
    const [Trans_shipmenterr, setTrans_shipmenterr] = useState("");
    const [Trans_shipmenterr1, setTrans_shipmenterr1] = useState("");

    const [Trans_shipmentDescriptionerr, setTrans_shipmentDescriptionerr] =
      useState("");

    const [reexportDesciption, setreexportDesciptionerr] = useState("");

    const [errorCount, seterrCount] = useState("");
    const [ECCNcodevalue, setECCNcodevalue] = useState([]);
    const [linces, setlinces] = useState([]);

    const [anyTransment, setanyTransment] = useState("");
    const [anyTransment1, setanyTransment1] = useState("");
    const [EccnExist, setEccnExist] = useState("0");

    const onProfileValidation = async () => {
      let errorCount = 0;
      // if (SERVICE.hasError(CountryOrigin)) {
      //   errorCount++;
      //   let msg = "Please enter your country origin.";
      //   setCountryOriginerr(msg);
      // } else {
      //   setCountryOriginerr(null);
      // }

      // if (SERVICE.hasError(Trans_shipmentDescription)) {
      //   errorCount++;
      //   let msg = "Please select your country destination.";
      //   setDestinationOriginerr(msg);
      // } else {
      //   setDestinationOriginerr(null);
      // }

      // if (SERVICE.hasError(Trans_shipment)) {
      //   errorCount++;
      //   let msg = "Please select any trans-shipment.";
      //   setTrans_shipmenterr(msg);
      // } else {
      //   setTrans_shipmenterr(null);
      // }

      // if (SERVICE.hasError(Trans_shipment1)) {
      //   errorCount++;
      //   let msg = "Please select any-rexport.";
      //   setTrans_shipmenterr1(msg);
      // } else {
      //   setTrans_shipmenterr1(null);
      // }
      if (anyTransment == "1") {
        if (SERVICE.hasError(Trans_shipmentDescriptionmanual)) {
          errorCount++;
          let msg = "Please enter your trans-shipment destination.";
          setTrans_shipmentDescriptionerr(msg);
        } else {
          setTrans_shipmentDescriptionerr(null);
        }
      }
      if (anyTransment == "1") {
        if (SERVICE.hasError(Trans_shipmentDescription)) {
          errorCount++;
          let msg = "Please select your trans-shipment destination.";
          setreexportDesciptionerr(msg);
        } else {
          setreexportDesciptionerr(null);
        }
      }

      if (errorCount > 0) return seterrCount(errorCount);
      else seterrCount(0);
    };

    const handleOwnershipChange = (event) => {
      setanyTransment(event.target.value == "Yes" ? "1" : "");
      setTrans_shipment(event.target.value);
    };

    const handleOwnershipChange1 = (event) => {
      setanyTransment1(event.target.value == "Yes" ? "1" : "");
      setTrans_shipment1(event.target.value);
      setTrans_shipment11(event.target.value);
    };
    var reference_number = "";
    const getHsCodeSearch = async (req, res) => {
      const userData = localStorage.getItem("userDetail");
      const reference = localStorage.getItem("reference");
      const hscodeVerfication = localStorage.getItem("hsCodeVerficationData");

      const hscodeSanctions = JSON.parse(localStorage.getItem("Sanctions"));

      // if (reference) {

      // } else {

      //   localStorage.removeItem("hsCodeVerficationData")
      // }
      if (userData) {
        var data = JSON.parse(userData);
        var dataReference = JSON.parse(reference);
        var hsCodeverfication = JSON.parse(hscodeVerfication);

        sethsCodeVerificationData(
          hsCodeverfication ? hsCodeverfication.hscodeType : []
        );
        sethscodeid(hsCodeverfication ? hsCodeverfication._id : []);
      }
      if (hscodeSanctions) {
        setCountryOrigin1(hscodeSanctions.countryOrigin);
        setDestinationOrigin1(hscodeSanctions.countryDestination);
        setTrans_shipmentDescription(hscodeSanctions.TransShipmentDesription);
        setTrans_shipment(hscodeSanctions.anyTransShipment);
        setTrans_shipment11(hscodeSanctions.anyReexport);
        setTrans_shipmentDescriptionmanual(
          hscodeSanctions.TrandestinationDesription
        );
        sethsCodeVerificationData(
          hscodeSanctions ? hscodeSanctions.hscodeType : []
        );
        sethsCodeShow(true);
        if (hscodeSanctions.anyTransShipment == "Yes") {
          setanyTransment("1");
        }
      }
    };

    const HsCodeSeacrh = async () => {
      setSubmit(true);
      setSubmitloader(true);
      onProfileValidation();

      if (Trans_shipment1 == "" && Trans_shipment == "") {
        setSubmitloader(false);
      }
      if (errorCount > 0) {
        setSubmitloader(false);
      }
      if (errorCount > 0) return;

      const updatedHsCodeVerificationData = hsCodeVerificationData.map(
        (item) => {
          if (!item.hasOwnProperty("licines")) {
            return { ...item, licines: "No" };
          }
          return item;
        }
      );

      console.log(updatedHsCodeVerificationData);
      const userData = localStorage.getItem("userDetail");
      const reference = localStorage.getItem("reference");

      if (userData) {
        var data = JSON.parse(userData);
        var dataReference = JSON.parse(reference);
      }

      let payload = {
        hscodeType: updatedHsCodeVerificationData,
        hsCodeVerifiyID: hscodeid,
        userId: data._id,
        countryOrigin: CountryOrigin1,
        countryDestination: DestinationOrigin1,
        anyTransShipment: Trans_shipment,
        TransShipmentDesription: Trans_shipmentDescription1,
        TrandestinationDesription: Trans_shipmentDescriptionmanual,

        anyReexport: Trans_shipment1,
      };

      await fetch(`${API.BASE_URL}addHsCodeverificationData`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        mode: "cors",
        body: JSON.stringify({
          reference: dataReference,
          hscodeType: updatedHsCodeVerificationData,
          hsCodeVerifiyID: hscodeid,
          userId: data._id,
          countryOrigin: CountryOrigin,
          countryDestination: DestinationOrigin,
          anyTransShipment: Trans_shipment,
          TransShipmentDesription: Trans_shipmentDescription,
          TrandestinationDesription: Trans_shipmentDescriptionmanual,

          anyReexport: Trans_shipment1,

          // CountryId:countryCode._id,
        }),
      })
        .then((response) => response.json())
        .then(async (res) => {
          console.log("resres", res);
          setSubmitloader(false);
          await localStorage.setItem("Sanctions", JSON.stringify(payload));
          setEccnExist("1");
          if (res.status == true) {
            console.log("1234567890-");
            if (res.data.hscodeType[0].EccN) {
              setCodeSanctions(true);
              setcurrentForm(0);
            } else {
              setcurrentForm(1);
            }

            toast("Submit successfully, You can proceed to next");
            await localStorage.setItem(
              "hsCodeVerficationData",
              JSON.stringify(res.data)
            );
            // if (hsCodeVerificationData[0].EccN) {
            // } else {
            //   hsToggle();
            // }
            if (EccnExist == "0") {
              hsToggle();
            } else {
            }
          }
        })
        .catch((error) => {});
    };

    const countrycodeSearch = async () => {
      setloader(true);
      await fetch(`${API.BASE_URL}CountryCodeSearchDeatil`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        mode: "cors",
        body: JSON.stringify({}),
      })
        .then((response) => response.json())
        .then(async (res) => {
          if (res.status === true) {
            const formattedData = res.data.map((country) => ({
              _id: country._id,
              country: country.country,
            }));
            setcountrycode(formattedData);
            countryCodeData.push(formattedData);
            setloader(false);

            // await localStorage.setItem("hsCodeVerficationData", JSON.stringify(res.data));
            // hsToggle()
          }
        })
        .catch((error) => {});
    };

    const [ECCNcode, setECCNcode] = useState([]);

    const [showNameModal, setShowNameModal] = useState(false);

    const hadleSelectedtName = (item) => {
      setShowNameModal(false);
    };

    const getECCNcodesearch = async (eccn) => {
      let payload = {
        EccnCode: eccn,
      };

      await fetch(`${API.BASE_URL}eccnCodeSearchDeatil`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          EccnCode: eccn,
        }),
      })
        .then((response) => response.json())
        .then(async (res) => {
          const formattedData = res.data.map((esCode) => ({
            esCode: esCode,
          }));

          setECCNcode(formattedData);

          setShowNameModal(true);
        })
        .catch((error) => {});
    };

    useEffect(() => {
      if (ECCNcodevalue === "") {
        setShowNameModal(false);
      }
    }, [ECCNcodevalue]);
    useEffect(() => {
      // getECCNcodesearch();
      getHsCodeSearch();
      countrycodeSearch();
    }, []);

    useEffect(() => {
      onProfileValidation();
    }, [
      CountryOrigin,
      DestinationOrigin,
      Trans_shipment,
      Trans_shipmentDescription,
    ]);

    const hsToggle = () => {
      setEccnExist("1 ");
      sethsCodeShow(!hsCodeHide);
    };

    const handleHsSearch = () => {
      const isBlank = hsCodeVerificationData.some(
        (item) => !item.goodDescription || !item.EccN || !item.licines
      );

      HsCodeSeacrh();
      // if (isBlank && hsCodeHide == true) {

      //   // toast("Please fill in all fields before searching.");
      // } else {
      //   HsCodeSeacrh();
      // }
    };

    return (
      <>
        <div className="d-flex align-items-center">
          <h2 className="rpsHeading">HS Code Sanctions</h2>
        </div>

        <Row>
          <Col md={12}>
            <Card className="mb-3 mb-lg-4 mb-md-4 card-boxx">
              <Card.Body>
                {hsCodeVerificationData.length > 0 &&
                  hsCodeVerificationData.map((item, index) => {
                    return (
                      <div>
                        <Row key={index}>
                          <Col lg={3}>
                            <div className="repeat_sec">
                              <CustomForm
                                color={"#222528"}
                                type="text"
                                value={item.hsCode}
                                lableText="Goods Description *"
                                placeholderText="Enter Goods Description"
                              />
                            </div>
                          </Col>
                          <Col lg={3}>
                            <div className="repeat_sec">
                              <CustomForm
                                color={"#222528"}
                                type="text"
                                value={item.goodDescription}
                                lableText="HS Code or Taric Code (EU)"
                                placeholderText="Enter HS Code or Taric Code EU"
                              />

                              {/* <Icon
                        icon="ic:outline-delete"
                        className="delete"
                        fontSize={22}
                      /> */}
                            </div>
                          </Col>

                          <Col lg={3}>
                            <div className="repeat_sec">
                              <CustomForm
                                color={"#222528"}
                                type="text"
                                value={item.chemicalDangerios}
                                lableText="Chemical Or Dangerous Good *"
                                placeholderText="Enter Goods Description"
                              />
                            </div>
                          </Col>

                          <Col lg={3}>
                            <div className="repeat_sec">
                              <CustomForm
                                color={"#222528"}
                                type="text"
                                value={item.DualUseExport}
                                lableText="Dual Use Export Controlled Goods?*"
                                placeholderText="Enter Goods Description"
                              />
                            </div>
                          </Col>
                        </Row>
                      </div>
                    );
                  })}
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <Row className="mb-3 mb-lg-2">
          {/* <Col md={6}>
            <CustomFormIcon
              color={"#222528"}
              type="text"
              value={CountryOrigin}
              onChange={(e)=>setCountryOrigin(e.target.value)}
              lableText="Country Origin of Shipment *"
              placeholderText="Enter Country Origin of Shipment"
            />
          </Col> */}

          <Col md={6}>
            <select
              className="form-control"
              value={CountryOrigin}
              onChange={(e) => {
                setCountryOrigin(e.target.value);
                const selectedCountry = countryCode.find(
                  (country) => country._id === e.target.value
                );
                if (selectedCountry) {
                  setCountryOrigin1(selectedCountry.country);
                }
              }}
            >
              <option>
                {CountryOrigin1
                  ? CountryOrigin1
                  : "Select Origin Country of Shipment"}
              </option>
              {loader && <option disabled>Loading...</option>}
              {!loader &&
                countryCode.map((country) => (
                  <option key={country._id} value={country._id}>
                    {country.country}
                  </option>
                ))}
            </select>

            {CountryOriginerr && Submit && !CountryOrigin ? (
              <div
                style={{
                  fontFamily: "AvenirLTProLight",
                  color: "#FF0000",
                  opacity: 0.6,
                }}
              >
                {CountryOriginerr}
              </div>
            ) : null}
          </Col>

          <Col md={6}>
            <select
              className="form-control"
              value={DestinationOrigin}
              onChange={(e) => {
                setDestinationOrigin(e.target.value);
                const selectedCountry = countryCode.find(
                  (country) => country._id === e.target.value
                );
                if (selectedCountry) {
                  setDestinationOrigin1(selectedCountry.country);
                }
              }}
            >
              <option value="" disabled={loader}>
                {DestinationOrigin1
                  ? DestinationOrigin1
                  : "Select Origin Country of Shipment"}
              </option>
              {loader && <option disabled>Loading...</option>}
              {!loader &&
                countryCode.map((country) => (
                  <option key={country._id} value={country._id}>
                    {country.country}
                  </option>
                ))}
            </select>
            {/* {DestinationOriginerr && Submit && !DestinationOrigin ? (
              <div
                style={{
                  fontFamily: "AvenirLTProLight",
                  color: "#FF0000",
                  opacity: 0.6,
                }}
              >
                {DestinationOriginerr}
              </div>
            ) : null} */}
          </Col>
        </Row>

        <Row>
          <Col md={12} lg={6}>
            <div className="mb-3">
              <span className="form-label">Any Trans-shipment?</span>
              {["radio"].map((type) => (
                <div key={`inline-${type}`} className="radioBx">
                  <Form.Check
                    inline
                    label="Yes"
                    name="group1"
                    type={type}
                    value="Yes"
                    checked={Trans_shipment === "Yes"}
                    id={`inline-${type}-1`}
                    onChange={handleOwnershipChange}
                  />
                  <Form.Check
                    inline
                    label="No"
                    name="group1"
                    type={type}
                    value="No"
                    checked={Trans_shipment === "No"}
                    id={`inline-${type}-2`}
                    onChange={handleOwnershipChange}
                  />
                </div>
              ))}
              {Trans_shipmenterr && Submit && !Trans_shipment ? (
                <div
                  style={{
                    fontFamily: "AvenirLTProLight",
                    color: "#FF0000",
                    opacity: 0.6,
                  }}
                >
                  {Trans_shipmenterr}
                </div>
              ) : null}
            </div>
          </Col>

          {anyTransment == "1" ? (
            <>
              {" "}
              <Col md={12} lg={6}>
                <Col md={12}>
                  <div className="repeat_sec">
                    <CustomForm
                      color={"#222528"}
                      type="text"
                      lableText="Trans-shipment Destination *"
                      placeholderText="Trans-shipment Destination"
                      value={Trans_shipmentDescriptionmanual}
                      onChange={(e) =>
                        setTrans_shipmentDescriptionmanual(e.target.value)
                      }
                    />

                    {Trans_shipmentDescriptionerr &&
                    Submit &&
                    !Trans_shipmentDescriptionmanual ? (
                      <div
                        style={{
                          fontFamily: "AvenirLTProLight",
                          color: "#FF0000",
                          opacity: 0.6,
                        }}
                      >
                        {Trans_shipmentDescriptionerr}
                      </div>
                    ) : null}
                  </div>
                  {/* <select
                className="form-control"
                value={Trans_shipmentDescription}
                onChange={(e) => setTrans_shipmentDescription(e.target.value)}
              >
                <option value="">Select Any Trans-shipment Destination</option>

                {loader && <option disabled>Loading...</option>}
                {!loader && countryCode.map(country => (
                  <option key={country._id} value={country._id}>
                    {country.country}
                  </option>
                ))}

              </select>
              {Trans_shipmentDescriptionerr && Submit && !Trans_shipmentDescription ? (
                <div
                  style={{
                    fontFamily: "AvenirLTProLight",
                    color: "#FF0000",
                    opacity: 0.6,
                  }}
                >
                  {Trans_shipmentDescriptionerr}
                </div>
              ) : null} */}
                </Col>

                {/* <CustomForm
              color={"#222528"}
              type="text"
              lableText="Trans-shipment Description *"
              value={Trans_shipmentDescription}
              onChange={(e) => setTrans_shipmentDescription(e.target.value)}
              placeholderText="Enter Trans-shipment Description"
            />
            {Trans_shipmentDescriptionerr && Submit && !Trans_shipmentDescription ? (
              <div
                style={{
                  fontFamily: "AvenirLTProLight",
                  color: "#FF0000",
                  opacity: 0.6,
                }}
              >
                {Trans_shipmentDescriptionerr}
              </div>
            ) : null} */}
              </Col>
            </>
          ) : (
            <></>
          )}
        </Row>

        <Row>
          <Col md={12} lg={6}>
            <div className="mb-3">
              <span className="form-label">Any Re-Export?</span>
              {["radio"].map((type) => (
                <div key={`inline-${type}`} className="radioBx">
                  <Form.Check
                    inline
                    label="Yes"
                    name="group11"
                    type={type}
                    value="Yes"
                    checked={Trans_shipment11 == "Yes"}
                    id={`inline-11`}
                    onChange={handleOwnershipChange1}
                  />
                  <Form.Check
                    inline
                    label="No"
                    name="group12"
                    type={type}
                    value="No"
                    checked={Trans_shipment11 == "No"}
                    id={`inline-12`}
                    onChange={handleOwnershipChange1}
                  />
                </div>
              ))}
              {Trans_shipmenterr1 && Submit && !Trans_shipment1 ? (
                <div
                  style={{
                    fontFamily: "AvenirLTProLight",
                    color: "#FF0000",
                    opacity: 0.6,
                  }}
                >
                  {Trans_shipmenterr1}
                </div>
              ) : null}
            </div>
          </Col>

          {anyTransment == "1" ? (
            <>
              {" "}
              <Col md={12} lg={6}>
                <Col md={12}>
                  <select
                    className="form-control"
                    value={Trans_shipmentDescription}
                    onChange={(e) => {
                      setTrans_shipmentDescription(e.target.value);
                      const selectedCountry = countryCode.find(
                        (country) => country._id === e.target.value
                      );
                      if (selectedCountry) {
                        console.log("selectedCountry", selectedCountry);
                        setTrans_shipmentDescription2(selectedCountry.country);
                      }
                    }}
                  >
                    <option value="">
                      {Trans_shipmentDescription
                        ? Trans_shipmentDescription
                        : "Select Any Trans-shipment Destination"}
                    </option>

                    {loader && <option disabled>Loading...</option>}
                    {!loader &&
                      countryCode.map((country) => (
                        <option key={country._id} value={country._id}>
                          {country.country}
                        </option>
                      ))}
                  </select>
                  {reexportDesciption &&
                  Submit &&
                  !Trans_shipmentDescription ? (
                    <div
                      style={{
                        fontFamily: "AvenirLTProLight",
                        color: "#FF0000",
                        opacity: 0.6,
                      }}
                    >
                      {reexportDesciption}
                    </div>
                  ) : null}
                </Col>

                {/* <CustomForm
              color={"#222528"}
              type="text"
              lableText="Trans-shipment Description *"
              value={Trans_shipmentDescription}
              onChange={(e) => setTrans_shipmentDescription(e.target.value)}
              placeholderText="Enter Trans-shipment Description"
            />
            {Trans_shipmentDescriptionerr && Submit && !Trans_shipmentDescription ? (
              <div
                style={{
                  fontFamily: "AvenirLTProLight",
                  color: "#FF0000",
                  opacity: 0.6,
                }}
              >
                {Trans_shipmentDescriptionerr}
              </div>
            ) : null} */}
              </Col>
            </>
          ) : (
            <></>
          )}
        </Row>
        {hsCodeHide && (
          <Row>
            <Col md={12}>
              <Card className="mb-3 mb-lg-4 mb-md-4 card-boxx">
                <Card.Body>
                  <Table responsive className="table_Box">
                    <thead>
                      <tr>
                        <th>Goods Description</th>
                        <th>Chemical Or Dangerous Good</th>

                        <th>Dual Use Export Controlled Goods</th>

                        <th>
                          ECCN/<strong>Taric-DU#</strong>
                        </th>
                        <th>No Licence Required?</th>
                      </tr>
                    </thead>
                    <tbody>
                      {hsCodeVerificationData.length > 0 &&
                        hsCodeVerificationData.map((item, index) => {
                          return (
                            <>
                              <tr key={index}>
                                <td>
                                  <Form.Group>
                                    <Form.Control
                                      type={"text"}
                                      placeholder={"Enter Goods Description"}
                                      value={item.goodDescription}
                                    />
                                  </Form.Group>
                                </td>

                                <td>
                                  <Form.Group>
                                    <Form.Control
                                      type={"text"}
                                      placeholder={
                                        "Chemical Or Dangerous Good "
                                      }
                                      value={item.chemicalDangerios}
                                    />
                                  </Form.Group>
                                </td>

                                <td>
                                  <Form.Group>
                                    <Form.Control
                                      type={"text"}
                                      placeholder={
                                        "Dual Use Export Controlled Goods?"
                                      }
                                      value={item.DualUseExport}
                                    />
                                  </Form.Group>
                                </td>
                                <td>
                                  <Form.Group>
                                    <Form.Control
                                      type={"text"}
                                      placeholder={"Enter ECCN/Taric-DU# "}
                                      value={item.EccN} // Use item.EccN for the value
                                      onChange={(e) => {
                                        const newValue = e.target.value;
                                        sethsCodeVerificationData(
                                          (prevData) => {
                                            const newData = [...prevData];
                                            newData[index].EccN = newValue;
                                            // Update EccN for specific index
                                            getECCNcodesearch(e.target.value);
                                            setECCNcodevalue(e.target.value);
                                            return newData;
                                          }
                                        );
                                      }}
                                    />
                                  </Form.Group>
                                </td>
                                <td>
                                  <Form.Group>
                                    <Form.Check
                                      type="checkbox"
                                      // label="Dual Use"
                                      checked={item.licines === "Yes"} // Checked if item.licines is "Yes"
                                      onChange={(e) => {
                                        const isChecked = e.target.checked;
                                        const newValue = isChecked
                                          ? "Yes"
                                          : "No";
                                        sethsCodeVerificationData(
                                          (prevData) => {
                                            const newData = [...prevData];
                                            newData[index].licines = newValue;
                                            // Update licines for specific index
                                            setlinces(newValue); // Assuming setlinces is the correct setter function for licines
                                            return newData;
                                          }
                                        );
                                      }}
                                    />
                                  </Form.Group>
                                  {/* Conditional rendering for icon */}
                                  {/* {item.licines === "Yes" ? (
                                    <FontAwesomeIcon
                                      icon={faCheck}
                                      style={{ color: "green" }}
                                    />
                                  ) : item.licines === "No" ? (
                                    <FontAwesomeIcon
                                      icon={faTimes}
                                      style={{ color: "red" }}
                                    />
                                  ) : null} */}
                                </td>

                                {/* <td>
                                  <Form.Group>
                                    <Form.Control
                                      as="select"
                                      placeholder="Not Dual Use"
                                      value={item.licines}
                                      onChange={(e) => {
                                        const newValue = e.target.value;
                                        sethsCodeVerificationData(
                                          (prevData) => {
                                            const newData = [...prevData];
                                            newData[index].licines = newValue;
                                            // Update licines for specific index
                                            setlinces(newValue); // Assuming setlinces is the correct setter function for licines
                                            return newData;
                                          }
                                        );
                                      }}
                                    >
                                      <option value="">Select Option</option>

                                      <option value="Yes">Yes</option>
                                      <option value="No">No</option>

                                      {/* Add more options as needed */}
                                {/* </Form.Control>
                                  </Form.Group>
                                </td> */}
                              </tr>
                            </>
                          );
                        })}
                      {/* {showNameModal &&
                        <div>{ECCNcode.map((item, index) => {
                          return (<>
                            <li key={index}
                              onClick={() => hadleSelectedtName(item.EccN)}
                            >
                              {item.esCode.esCode}
                            </li>
                          </>)
                        })}</div>

                      } */}

                      {/* <tr>
                        <td>
                          <Form.Group>
                            <Form.Control
                              type={"text"}
                              placeholder={"Enter Goods Description"}
                            />
                          </Form.Group>
                        </td>
                        <td>
                          <Form.Group>
                            <Form.Control
                              type={"text"}
                              placeholder={"Enter ECCN/Taric-DU#"}
                            />
                          </Form.Group>
                        </td>
                        <td>
                          <Form.Group>
                            <Form.Control
                              type={"text"}
                              placeholder={"Not Dual Use"}
                            />
                          </Form.Group>
                        </td>
                      </tr> */}
                    </tbody>
                  </Table>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        )}

        <div className="text-center mt-2">
          <Button
            className="btn btn-primary  width_btn"
            variant="primary"
            onClick={() => {
              handleHsSearch();
            }}
            size="md"
          >
            {Submitloader == true ? (
              <Oval
                height="30"
                width="30"
                radius="30"
                color="#f2f5f3"
                ariaLabel="loading"
                wrapperStyle={{ marginLeft: 120 }}
                wrapperClass
              />
            ) : (
              <>Submit</>
            )}
          </Button>
          <ToastContainer />
        </div>

        {/* <div className="arrowUpDown">
          <Icon
            icon="fluent:chevron-down-12-filled"
            onClick={() => setOpen(!open)}
            fontSize={18}
          />
          <Icon icon="fluent:chevron-up-12-filled" fontSize={18} />
        </div> */}
      </>
    );
  };

  const Step4Content = () => {
    const [hsCodeVerificationData, sethsCodeVerificationData] = useState([]);
    const [hsCodeHide, sethsCodeShow] = useState(false);
    const [hscodeid, sethscodeid] = useState("");
    const [CountryOrigin, setCountryOrigin] = useState("");
    const [DestinationOrigin, setDestinationOrigin] = useState("");
    const [Trans_shipment, setTrans_shipment] = useState("");
    const [Trans_shipmentDescription, setTrans_shipmentDescription] =
      useState("");

    const [loader, setloader] = useState(false);
    const getHsCodeSearch = async (req, res) => {
      const userData = localStorage.getItem("userDetail");
      const reference = localStorage.getItem("reference");
      const hscodeVerfication = localStorage.getItem("hsCodeVerficationData");

      if (userData) {
        var data = JSON.parse(userData);
        var dataReference = JSON.parse(reference);
        var hsCodeverfication = JSON.parse(hscodeVerfication);

        sethsCodeVerificationData(
          hsCodeverfication && hsCodeverfication.hscodeType
        );
        setTrans_shipmentDescription(
          hsCodeverfication && hsCodeverfication.TransShipmentDesription
        );
        setCountryOrigin(hsCodeverfication && hsCodeverfication.countryOrigin);
        setDestinationOrigin(
          hsCodeverfication && hsCodeverfication.countryDestination
        );
        setTrans_shipment(
          hsCodeverfication && hsCodeverfication.anyTransShipment
        );
        sethscodeid(hsCodeverfication && hsCodeverfication._id);

        // sethscodeid(hsCodeverfication._id)
      }
    };

    useEffect(() => {
      // getECCNcodesearch();
      getHsCodeSearch();
      // countrycodeSearch()
    }, []);

    const Dual_Use_Goods = async () => {
      // setSubmit(true);
      setloader(true);
      // setshipperloader(true)
      // onProfileValidation();

      // if (errorCount > 0) return;
      // await detectWhitespace(domain.trim());
      const userData = localStorage.getItem("userDetail");
      const reference = localStorage.getItem("reference");

      if (userData) {
        var data = JSON.parse(userData);
        var dataReference = JSON.parse(reference);
      }

      let payload = {
        hscodeType: hsCodeVerificationData,
        hsCodeVerifiyID: hscodeid,
        userId: data._id,
        countryOrigin: CountryOrigin,
        countryDestination: DestinationOrigin,
        anyTransShipment: Trans_shipment,
        TransShipmentDesription: Trans_shipmentDescription,
      };

      await fetch(`${API.BASE_URL}addHsCodeverificationData`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        mode: "cors",
        body: JSON.stringify({
          reference: dataReference,
          hscodeType: hsCodeVerificationData,
          hsCodeVerifiyID: hscodeid,
          userId: data._id,
          countryOrigin: CountryOrigin,
          countryDestination: DestinationOrigin,
          anyTransShipment: Trans_shipment,
          TransShipmentDesription: Trans_shipmentDescription,

          // CountryId:countryCode._id,
        }),
      })
        .then((response) => response.json())
        .then(async (res) => {
          setloader(false);
          if (res.status == true) {
            toast("Submit successfully, You can proceed to next");
            setDualUseGoods(true);
            await localStorage.setItem(
              "hsCodeVerficationData",
              JSON.stringify(res.data)
            );
            if (hsCodeVerificationData[0].EccN) {
            }
          }
        })
        .catch((error) => {});
    };

    return (
      <>
        <h2 className="rpsHeading">Dual-Use Goods</h2>

        <Row>
          <Col md={12}>
            <Card className="mb-3 mb-lg-4 mb-md-4 card-boxx card-boxx_mar">
              <Card.Body>
                <div className="repeat_sec repeat_bbbox">
                  <Table responsive className="table_Box table_Box12">
                    <thead>
                      <tr>
                        <th>Goods Description</th>
                        <th>
                          HS code or
                          <br /> Taric Code <br /> EU*
                        </th>
                        <th>
                          Chemical Or
                          <br /> Dangerous Good <br />
                        </th>
                        <th>
                          Dual Use Export
                          <br /> Controlled Goods <br />
                        </th>
                        <th>ECCN or EU DUXXX#</th>
                        {/* <th>Is DG?</th>
                      <th>Not DG?</th> */}

                        <th>No Licence Required?</th>
                        <th>Sanctioned?</th>
                        {/* <th>Re Export</th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {hsCodeVerificationData &&
                        hsCodeVerificationData.length > 0 &&
                        hsCodeVerificationData.map((item, index) => {
                          return (
                            <>
                              {" "}
                              <tr key={index}>
                                <td>
                                  <Form.Group>
                                    <Form.Control
                                      type={"text"}
                                      placeholder={"Enter Goods Description"}
                                      value={item.goodDescription}
                                    />
                                  </Form.Group>
                                </td>
                                <td>
                                  <Form.Group>
                                    <Form.Control
                                      type={"text"}
                                      placeholder={
                                        "HS code or Taric Code (EU) "
                                      }
                                      value={item.hsCode}
                                    />
                                  </Form.Group>
                                </td>

                                <td>
                                  <Form.Group>
                                    <Form.Control
                                      type={"text"}
                                      placeholder={
                                        "Chemical Or Dangerous Good  "
                                      }
                                      value={item.chemicalDangerios}
                                    />
                                  </Form.Group>
                                </td>

                                <td>
                                  <Form.Group>
                                    <Form.Control
                                      type={"text"}
                                      placeholder={
                                        "Dual Use Export Controlled Goods"
                                      }
                                      value={item.DualUseExport}
                                    />
                                  </Form.Group>
                                </td>
                                <td>
                                  <Form.Group>
                                    <Form.Control
                                      type={"text"}
                                      placeholder={"Enter ECCN or EU DUXXX#"}
                                      value={item.EccN}
                                    />
                                  </Form.Group>
                                </td>

                                {/* <td>
                            <div className="crBox">
                              <Image src={RightIcon} alt="" />
                            </div>
                          </td>
                          <td>
                            <div className="crBox">
                              <Image src={RightIcon} alt="" />
                            </div>
                          </td> */}
                                <td>
                                  <Form.Group>
                                    {item.licines === "Yes" ? (
                                      <FontAwesomeIcon
                                        icon={faCheck}
                                        style={{
                                          color: "green",
                                          marginLeft: 5,
                                        }}
                                      />
                                    ) : item.licines === "No" ? (
                                      <FontAwesomeIcon
                                        icon={faTimes}
                                        style={{ color: "red", marginLeft: 5 }}
                                      />
                                    ) : null}
                                    {/* <Form.Control
                                      type={"text"}
                                      placeholder={"Not Dual Use"}
                                      value={item.licines}
                                    /> */}
                                  </Form.Group>
                                </td>
                                <td>
                                  <Form.Group>
                                    <select
                                      className="form-control"
                                      value={
                                        hsCodeVerificationData[index].Sanctioned
                                      }
                                      onChange={(e) => {
                                        const newValue = e.target.value;

                                        sethsCodeVerificationData(
                                          (prevData) => {
                                            const newData = [...prevData];
                                            newData[index].Sanctioned =
                                              newValue;
                                            // Update EccN for specific index
                                            // getECCNcodesearch(newValue);
                                            // setECCNcodevalue(newValue);
                                            return newData;
                                          }
                                        );
                                      }}
                                    >
                                      <option value="Yes">Yes</option>
                                      <option value="No">No</option>
                                      {/* 
  {countryCode.map(country => (
    <option key={country._id} value={country._id}>
      {country.country}
    </option>
  ))} */}
                                    </select>
                                    {/* <Form.Control type={"text"} placeholder={"Yes"} value={item.EccN} // Use item.EccN for the value
                                onChange={(e) => {
                                  const newValue = e.target.value;
                                  sethsCodeVerificationData(prevData => {
                                    const newData = [...prevData];
                                    newData[index].EccN = newValue; 
                                    // Update EccN for specific index
                                    getECCNcodesearch(e.target.value)
                                    setECCNcodevalue(e.target.value)
                                    return newData;
                                  });
                                }}/> */}
                                  </Form.Group>
                                </td>
                                {/* <td>
                            <Form.Group>
                              <select
                                className="form-control"
                                value={hsCodeVerificationData[index].ReExport}
                                onChange={(e) => {
                                  const newValue = e.target.value;

                                  sethsCodeVerificationData(prevData => {
                                    const newData = [...prevData];
                                    newData[index].ReExport = newValue;
                                    // Update EccN for specific index
                                    // getECCNcodesearch(newValue);
                                    // setECCNcodevalue(newValue);
                                    return newData;
                                  });
                                }}
                              >
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                         
                              </select>
                            
                            </Form.Group>
                          </td> */}
                              </tr>
                            </>
                          );
                        })}
                    </tbody>
                  </Table>
                </div>
              </Card.Body>
            </Card>

            <div className="text-center">
              <Button
                className="btn btn-primary mt-2 width_btn"
                onClick={() => Dual_Use_Goods()}
              >
                {loader == true ? (
                  <Oval
                    height="30"
                    width="30"
                    radius="30"
                    color="#f2f5f3"
                    ariaLabel="loading"
                    wrapperStyle={{ marginLeft: 120 }}
                    wrapperClass
                  />
                ) : (
                  <> Start Screening</>
                )}
              </Button>
              <ToastContainer />
            </div>
          </Col>
        </Row>
        {/* <div className="arrowUpDown">
          <Icon
            icon="fluent:chevron-down-12-filled"
            onClick={() => setOpen(!open)}
            fontSize={18}
          />
          <Icon icon="fluent:chevron-up-12-filled" fontSize={18} />
        </div> */}
      </>
    );
  };

  const Step5Content = () => {
    const [countrtDetail, setcountrtDetail] = useState([]);

    const getHsCodeSearch = async (req, res) => {
      const userData = localStorage.getItem("userDetail");
      const reference = localStorage.getItem("reference");
      const hscodeVerfication = localStorage.getItem("hsCodeVerficationData");

      if (userData) {
        var data = JSON.parse(userData);
        var dataReference = JSON.parse(reference);
        var hsCodeverfication = JSON.parse(hscodeVerfication);

        Re_export_Country_Control(
          hsCodeverfication && hsCodeverfication.TransShipmentDesription
        );
      }
    };

    const Re_export_Country_Control = async (id) => {
      const userData = localStorage.getItem("userDetail");
      const reference = localStorage.getItem("reference");

      if (userData) {
        var data = JSON.parse(userData);
        var dataReference = JSON.parse(reference);
      }

      let payload = {
        countryDestitation: id,
      };

      await fetch(`${API.BASE_URL}getRe_export_Country_Control`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        mode: "cors",
        body: JSON.stringify({
          countryDestitation: id,
        }),
      })
        .then((response) => response.json())
        .then(async (res) => {
          if (res.status == true) {
            setcountrtDetail(res.data);
            // toast('Verrification successfull')
            //   await localStorage.setItem("hsCodeVerficationData", JSON.stringify(res.data));
            //   if(hsCodeVerificationData[0].EccN){

            //   }else{
            //     hsToggle()
            //   }
          }
        })
        .catch((error) => {});
    };

    useEffect(() => {
      getHsCodeSearch();
      // Re_export_Country_Control();

      // countrycodeSearch()
    }, []);

    return (
      <>
        <h2 className="rpsHeading">Re-export Country Control</h2>

        <Row>
          <Col md={12}>
            <Row>
              <Col md={12}>
                <Card className="mb-3 mb-lg-4 mb-md-4 card-boxx card-boxx_mar">
                  <Card.Body className="newtabLe">
                    <Table bordered responsive>
                      <thead>
                        <tr>
                          <th style={{ minWidth: "130px" }}>Country</th>
                          <th style={{ minWidth: "130px" }}>
                            <div className="text-center">
                              Chemical and
                              <br /> biological
                              <br /> weapons
                            </div>
                            <div className="cxBox">
                              <span>CB1</span>
                              <span>CB2</span>
                              <span>CB3</span>
                            </div>
                          </th>
                          <th style={{ minWidth: "130px" }}>
                            <div className="text-center">
                              Nuclear Non
                              <br /> Proliferation
                            </div>
                            <div className="cxBox">
                              <span>NP1</span>
                              <span>NP2</span>
                            </div>
                          </th>
                          <th style={{ minWidth: "130px" }}>
                            <div className="text-center">
                              National
                              <br /> Security
                            </div>
                            <div className="cxBox">
                              <span>NS1</span>
                              <span>NS2</span>
                            </div>
                          </th>
                          <th style={{ minWidth: "130px" }}>
                            <div className="text-center">
                              Missile
                              <br /> Tech
                            </div>
                            <div className="cxBox">MT1</div>
                          </th>
                          <th style={{ minWidth: "130px" }}>
                            <div className="text-center">
                              Regional
                              <br /> Stability
                            </div>
                            <div className="cxBox">
                              <span>RS1</span>
                              <span>RS2</span>
                            </div>
                          </th>
                          <th style={{ minWidth: "130px" }}>
                            <div className="text-center">
                              Firearms
                              <br /> Convention
                            </div>
                            <div className="cxBox">
                              <span>FC1</span>
                            </div>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {countrtDetail.map((item, index) => {
                          return (
                            <tr key={index}>
                              <th>{item.country}</th>
                              <th>
                                <div className="cxBox mt-0">
                                  <span>
                                    {item.CB1 == "0" ? (
                                      <OverlayTrigger
                                        placement="top"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={renderTooltip}
                                      >
                                        <Image src={CorssIconRed} alt="" />
                                      </OverlayTrigger>
                                    ) : (
                                      <Image src={RightIcon} alt="" />
                                    )}
                                  </span>
                                  <span>
                                    {item.CB2 == "0" ? (
                                      <OverlayTrigger
                                        placement="top"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={renderTooltip}
                                      >
                                        <Image src={CorssIconRed} alt="" />
                                      </OverlayTrigger>
                                    ) : (
                                      <Image src={RightIcon} alt="" />
                                    )}
                                  </span>
                                </div>
                              </th>
                              <th>
                                <div className="cxBox mt-0">
                                  <span>
                                    {item.CB3 == "0" ? (
                                      <OverlayTrigger
                                        placement="top"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={renderTooltip}
                                      >
                                        <Image src={CorssIconRed} alt="" />
                                      </OverlayTrigger>
                                    ) : (
                                      <Image src={RightIcon} alt="" />
                                    )}
                                  </span>
                                  <span>
                                    {item.CB4 == "0" ? (
                                      <OverlayTrigger
                                        placement="top"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={renderTooltip}
                                      >
                                        <Image src={CorssIconRed} alt="" />
                                      </OverlayTrigger>
                                    ) : (
                                      <Image src={RightIcon} alt="" />
                                    )}
                                  </span>
                                </div>
                              </th>
                              <th>
                                <div className="cxBox mt-0">
                                  <span>
                                    {item.CB5 == "0" ? (
                                      <OverlayTrigger
                                        placement="top"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={renderTooltip}
                                      >
                                        <Image src={CorssIconRed} alt="" />
                                      </OverlayTrigger>
                                    ) : (
                                      <Image src={RightIcon} alt="" />
                                    )}
                                  </span>
                                  <span>
                                    {item.CB6 == "0" ? (
                                      <OverlayTrigger
                                        placement="top"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={renderTooltip}
                                      >
                                        <Image src={CorssIconRed} alt="" />
                                      </OverlayTrigger>
                                    ) : (
                                      <Image src={RightIcon} alt="" />
                                    )}
                                  </span>
                                </div>
                              </th>
                              <th>
                                <div className="cxBox mt-0">
                                  <span>
                                    {item.CB7 == "0" ? (
                                      <OverlayTrigger
                                        placement="top"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={renderTooltip}
                                      >
                                        <Image src={CorssIconRed} alt="" />
                                      </OverlayTrigger>
                                    ) : (
                                      <Image src={RightIcon} alt="" />
                                    )}
                                  </span>
                                </div>
                              </th>
                              <th>
                                <div className="cxBox mt-0">
                                  <span>
                                    {item.CB8 == "0" ? (
                                      <OverlayTrigger
                                        placement="top"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={renderTooltip}
                                      >
                                        <Image src={CorssIconRed} alt="" />
                                      </OverlayTrigger>
                                    ) : (
                                      <Image src={RightIcon} alt="" />
                                    )}
                                  </span>
                                  <span>
                                    {item.CB9 == "0" ? (
                                      <OverlayTrigger
                                        placement="top"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={renderTooltip}
                                      >
                                        <Image src={CorssIconRed} alt="" />
                                      </OverlayTrigger>
                                    ) : (
                                      <Image src={RightIcon} alt="" />
                                    )}
                                  </span>
                                </div>
                              </th>
                              <th>
                                <div className="cxBox mt-0">
                                  <span>
                                    {item.CB10 == "0" ? (
                                      <OverlayTrigger
                                        placement="top"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={renderTooltip}
                                      >
                                        <Image src={CorssIconRed} alt="" />
                                      </OverlayTrigger>
                                    ) : (
                                      <Image src={RightIcon} alt="" />
                                    )}
                                  </span>
                                </div>
                              </th>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  </Card.Body>
                </Card>
              </Col>
            </Row>

            {/* <div className="text-center">
          <Button className="btn btn-primary mt-2 width_btn">
            Start Screening
          </Button>
        </div> */}
          </Col>
        </Row>
        {/* <div className="arrowUpDown">
          <Icon
            icon="fluent:chevron-down-12-filled"
            onClick={() => setOpen(!open)}
            fontSize={18}
          />
          <Icon icon="fluent:chevron-up-12-filled" fontSize={18} />
        </div> */}
      </>
    );
  };

  const Step6Content = () => {
    const [loader, setloader] = useState(false);
    const [hsCodeVerificationData, sethsCodeVerificationData] = useState([]);
    const [hsCodeHide, sethsCodeShow] = useState(false);
    const [hscodeid, sethscodeid] = useState("");
    const [CountryOrigin, setCountryOrigin] = useState("");
    const [DestinationOrigin, setDestinationOrigin] = useState("");
    const [Trans_shipment, setTrans_shipment] = useState("");
    const [Trans_shipmentDescription, setTrans_shipmentDescription] =
      useState("");

    const [seachingLoader, setseachingLoader] = useState(false);
    const [SelectedItemIndex, setSelectedItemIndex] = useState(null);
    const [usergetSearchApi, setusergetSearchApi] = useState([]);
    const [showNameModal, setShowNameModal] = useState(false);
    const [searchValue, setSearchValue] = useState("");

    const [HazemClass, setHazemClass] = useState([]);

    const [pic, setPic] = useState([]);

    // const[Trans_shipment,setTrans_shipment ]= useState('')

    const getHsCodeSearch = async (req, res) => {
      const userData = localStorage.getItem("userDetail");
      const reference = localStorage.getItem("reference");
      const hscodeVerfication = localStorage.getItem("hsCodeVerficationData");

      if (userData) {
        var data = JSON.parse(userData);
        var dataReference = JSON.parse(reference);
        var hsCodeverfication = JSON.parse(hscodeVerfication);

        sethsCodeVerificationData(
          hsCodeverfication && hsCodeverfication.hscodeType
        );

        setTrans_shipmentDescription(
          hsCodeverfication && hsCodeverfication.TransShipmentDesription
        );
        setCountryOrigin(hsCodeverfication && hsCodeverfication.countryOrigin);
        setDestinationOrigin(
          hsCodeverfication && hsCodeverfication.countryDestination
        );
        setTrans_shipment(
          hsCodeverfication && hsCodeverfication.anyTransShipment
        );
        sethscodeid(hsCodeverfication && hsCodeverfication._id);

        // sethscodeid(hsCodeverfication._id)
      }
    };

    const Dual_Use_Goods = async () => {
      // setSubmit(true);
      setloader(true);
      // setshipperloader(true)
      // onProfileValidation();

      // if (errorCount > 0) return;
      // await detectWhitespace(domain.trim());
      const userData = localStorage.getItem("userDetail");
      const reference = localStorage.getItem("reference");

      if (userData) {
        var data = JSON.parse(userData);
        var dataReference = JSON.parse(reference);
      }

      let payload = {
        reference: dataReference,
        hscodeType: hsCodeVerificationData,
        hsCodeVerifiyID: hscodeid,
        userId: data._id,
        countryOrigin: CountryOrigin,
        countryDestination: DestinationOrigin,
        anyTransShipment: Trans_shipment,
        TransShipmentDesription: Trans_shipmentDescription,
      };

      await fetch(`${API.BASE_URL}addHsCodeverificationData`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        mode: "cors",
        body: JSON.stringify({
          reference: dataReference,
          hscodeType: hsCodeVerificationData,
          hsCodeVerifiyID: hscodeid,
          userId: data._id,
          countryOrigin: CountryOrigin,
          countryDestination: DestinationOrigin,
          anyTransShipment: Trans_shipment,
          TransShipmentDesription: Trans_shipmentDescription,

          // CountryId:countryCode._id,
        }),
      })
        .then((response) => response.json())
        .then(async (res) => {
          setloader(false);
          if (res.status == true) {
            setDangerousGoods(true);
            toast("Submit successfully, You can proceed to next");
            await localStorage.setItem(
              "hsCodeVerficationData",
              JSON.stringify(res.data)
            );
          }
        })
        .catch((error) => {});
    };

    const getbadgedeatil = async () => {
      await fetch(`${API.BASE_URL}getBadgesDetail`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        mode: "cors",
        body: JSON.stringify({
          // CountryId:countryCode._id,
        }),
      })
        .then((response) => response.json())
        .then(async (res) => {
          if (res.status == true) {
            setHazemClass(res.data);
          }
        })
        .catch((error) => {});
    };

    const getHazemSeacrhing = async (data) => {
      // setseachingLoader(true);
      await fetch(`${API.BASE_URL}hazchemaSearchingWord`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        mode: "cors",
        body: JSON.stringify({
          SearchingWord: data,
          // CountryId:countryCode._id,
        }),
      })
        .then((response) => response.json())
        .then(async (res) => {
          console.log("res", res);
          // setseachingLoader(false);
          if (res.status == true) {
            // setHazemClass(res.data);

            console.log("hlo");

            const filteredData = res.data.filter((item) =>
              item.goodDescription.toLowerCase().includes(data.toLowerCase())
            );

            console.log("filteredData", filteredData);
            setShowNameModal(true);
            setusergetSearchApi(filteredData);
          } else {
            // Handle no data or unsuccessful response
          }
        })
        .catch((error) => {});
    };

    const handleInputChange = (index, value) => {
      // setSearchValue((prevValues) => ({
      //    value,
      // }));
      setSearchValue(value);

      sethsCodeVerificationData((prevData) => {
        const updatedData = [...prevData];
        if (updatedData[index].HAZCHEM) {
          updatedData[index].HAZCHEM = value;
        } else {
          updatedData[index].HAZCHEM = value;
        }
        return updatedData;
      });

      getHazemSeacrhing(value);
    };

    const hadleSelectedtName = (item, index) => {
      sethsCodeVerificationData((prevData) => {
        const updatedData = [...prevData];
        if (updatedData[index].HAZCHEM) {
          updatedData[index].HAZCHEM = item;
        } else {
          updatedData[index].HAZCHEM = item;
        }
        console.log("updatedData", updatedData);
        return updatedData;
      });
      setShowNameModal(false);
    };

    // const hadleSelectedtName = (selectedItem, index) => {

    //   console.log(
    //     'selectedItem',index
    //   );
    //   setSearchValue(selectedItem.goodDescription);
    //   setPic(selectedItem.Pic);
    //   // const oldData = hsCodeVerificationData;
    //   // const newData = oldData[index].HAZCHEM = selectedItem;
    //   // console.log("newData = ",newData)
    //   // sethsCodeVerificationData([...newData]);
    //   sethsCodeVerificationData((prevData) => {
    //     const newData = [...prevData];
    //     // Check if newData[index] exists before modifying

    //     console.log("newData[index]", newData[index]);
    //     if (newData[index]) {
    //       newData[index].HAZCHEM = selectedItem;
    //       // Assuming selectedItem is the value you want to save
    //     } else {
    //       // Handle the case where newData[index] is undefined
    //       console.error(
    //         `Index ${index} is out of bounds or newData is not initialized properly.`
    //       );
    //     }
    //     return newData;
    //   });
    //   setShowNameModal(false);
    // };

    useEffect(() => {
      getHsCodeSearch();
      getbadgedeatil();
      getHazemSeacrhing();
      // Re_export_Country_Control();

      // countrycodeSearch()
    }, []);

    console.log("vhsCodeVerificationData", hsCodeVerificationData);

    useEffect(() => {
      const handleClickOutside = (event) => {
        if (modalRef.current && !modalRef.current.contains(event.target)) {
          // setShowNameModal1(false);
          setShowNameModal(false);
          // setShowNameModal2(false)
        }
      };

      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, []);
    return (
      <>
        <h2 className="rpsHeading">Dangerous Goods</h2>
        <Row>
          <Col md={12}>
            <Card className="mb-3 mb-lg-4 mb-md-4 card-boxx">
              <Card.Body>
                <Table className="table_Box">
                  <thead>
                    <tr>
                      <th>Goods Description</th>
                      <th>HS code or Taric Code (EU)</th>
                      <th>
                        Chemical Or
                        <br /> Dangerous Good <br />
                      </th>
                      <th>
                        Dual Use Export
                        <br /> Controlled Goods <br />
                      </th>
                      {/* <th>Is DG?</th>
                    <th>Not DG?</th> */}

                      <th>HAZCHEM Class</th>
                      <th>Label</th>
                    </tr>
                  </thead>
                  <tbody>
                    {hsCodeVerificationData &&
                      hsCodeVerificationData.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td>
                              <Form.Group>
                                <Form.Control
                                  type={"text"}
                                  placeholder={"Enter Goods Description"}
                                  value={item.goodDescription}
                                />
                              </Form.Group>
                            </td>
                            <td>
                              <Form.Group>
                                <Form.Control
                                  type={"text"}
                                  placeholder={
                                    "Enter HS code or Taric Code (EU) "
                                  }
                                  value={item.hsCode}
                                />
                              </Form.Group>
                            </td>
                            <td>
                              <Form.Group>
                                <Form.Control
                                  type={"text"}
                                  placeholder={"Chemical Or Dangerous Good  "}
                                  value={item.chemicalDangerios}
                                />
                              </Form.Group>
                            </td>

                            <td>
                              <Form.Group>
                                <Form.Control
                                  type={"text"}
                                  placeholder={
                                    "Dual Use Export Controlled Goods  "
                                  }
                                  value={item.DualUseExport}
                                />
                              </Form.Group>
                            </td>
                            {/* <td>
                        <div className="crBox">
                          <Image src={CorssIconBlank} alt="" />
                        </div>
                      </td>
                      <td>
                        <div className="crBox">
                          <Image src={RightIcon} alt="" />
                        </div>
                      </td> */}

                            <td>
                              <Form.Group className="position-relative">
                                {/* <Form.Control type={"text"} placeholder={"Yes"} /> */}

                                <input
                                  type="text"
                                  value={
                                    hsCodeVerificationData[index] &&
                                    hsCodeVerificationData[index].HAZCHEM
                                      ? hsCodeVerificationData[index].HAZCHEM &&
                                        hsCodeVerificationData[
                                          index
                                        ].HAZCHEM.hasOwnProperty(
                                          "goodDescription"
                                        )
                                        ? hsCodeVerificationData[index].HAZCHEM
                                            .goodDescription
                                        : hsCodeVerificationData[index].HAZCHEM
                                        ? hsCodeVerificationData[index].HAZCHEM
                                        : searchValue
                                      : ""

                                    // hsCodeVerificationData[index].HAZCHEM
                                    //   ? hsCodeVerificationData[index].HAZCHEM
                                    //       .goodDescription ==Hsindex?hsCodeVerificationData[index].HAZCHEM
                                    //       .goodDescription:''
                                    //   : searchValue
                                  }
                                  className="form-control"
                                  onChange={(e) => {
                                    // setSearchValue(e.target.value);
                                    // getHazemSeacrhing(e.target.value);
                                    handleInputChange(index, e.target.value); // Call getHazemSearching with the new search value
                                  }}
                                  onFocus={() => {
                                    Hsindex = index;
                                  }}
                                  onBlur={() => {
                                    setSelectedItemIndex(null);
                                  }}
                                  placeholder="Search by description..."
                                />

                                {seachingLoader ? (
                                  <div className="text-center mb-3 position-absolute loadBox">
                                    <Spinner
                                      variant="primary"
                                      animation="border"
                                    />
                                  </div>
                                ) : (
                                  <>
                                    {showNameModal &&
                                      index == Hsindex &&
                                      usergetSearchApi.length > 0 && (
                                        <div
                                          className="modalTopNew"
                                          ref={modalRef}
                                        >
                                          {usergetSearchApi.map(
                                            (item, indexs) => (
                                              <li
                                                key={indexs}
                                                onClick={() =>
                                                  hadleSelectedtName(
                                                    item,
                                                    Hsindex
                                                  )
                                                }
                                              >
                                                {item.goodDescription}
                                              </li>
                                            )
                                          )}
                                        </div>
                                      )}
                                  </>
                                )}
                              </Form.Group>
                            </td>
                            <td>
                              {console.log(
                                "dd",
                                hsCodeVerificationData[index] &&
                                  hsCodeVerificationData[index].hasOwnProperty(
                                    "HAZCHEM"
                                  )
                                  ? hsCodeVerificationData[
                                      index
                                    ].HAZCHEM.hasOwnProperty("Pic") != null
                                  : ""
                              )}
                              <td>
                                <div className="text-center">
                                  {/* Render the image based on the selected badge */}
                                  {hsCodeVerificationData[index] &&
                                  hsCodeVerificationData[index].hasOwnProperty(
                                    "HAZCHEM"
                                  )
                                    ? hsCodeVerificationData[
                                        index
                                      ].HAZCHEM.hasOwnProperty("Pic")
                                      ? hsCodeVerificationData[index].HAZCHEM
                                          .Pic != null && (
                                          <Image
                                            src={
                                              hsCodeVerificationData[index] &&
                                              hsCodeVerificationData[index]
                                                .HAZCHEM &&
                                              hsCodeVerificationData[
                                                index
                                              ].HAZCHEM.hasOwnProperty("Pic")
                                                ? `${PIC.PIC_URL}` +
                                                  hsCodeVerificationData[index]
                                                    .HAZCHEM.Pic
                                                : `${PIC.PIC_URL}` + pic
                                            }
                                            alt=""
                                            style={{ width: 80, height: 80 }}
                                          />
                                        )
                                      : ""
                                    : ""}

                                  {/* <Image
                                    src={
                                      `${PIC.PIC_URL}` +
                                      (HazemClass.find(
                                        (badge) =>
                                          badge._id ===
                                          hsCodeVerificationData[index].HAZCHEM
                                      )?.BadgePic || "")
                                    }
                                    alt=""
                                  /> */}
                                </div>
                              </td>

                              {/* <div className="text-center">
      <Image src={LogoNew1} alt="" />
    </div> */}
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </Table>
              </Card.Body>

              <div className="text-center mb-4">
                <Button
                  className="btn btn-primary mt-2 width_btn"
                  onClick={() => Dual_Use_Goods()}
                >
                  {loader == true ? (
                    <Oval
                      height="30"
                      width="30"
                      radius="30"
                      color="#f2f5f3"
                      ariaLabel="loading"
                      wrapperStyle={{ marginLeft: 120 }}
                      wrapperClass
                    />
                  ) : (
                    <>Start Screening</>
                  )}
                </Button>
                <ToastContainer />
              </div>
            </Card>
          </Col>
        </Row>
        {/* <div className="arrowUpDown">
          <Icon
            icon="fluent:chevron-down-12-filled"
            onClick={() => setOpen(!open)}
            fontSize={18}
          />
          <Icon icon="fluent:chevron-up-12-filled" fontSize={18} />
        </div> */}
      </>
    );
  };

  const Step7Content = () => {
    const [loader, setloader] = useState(false);
    const [hsCodeVerificationData, sethsCodeVerificationData] = useState([]);

    const [Submit, setSubmit] = useState(false);

    const [hsCodeHide, sethsCodeShow] = useState(false);
    const [hscodeid, sethscodeid] = useState("");
    const [CountryOrigin, setCountryOrigin] = useState("");
    const [DestinationOrigin, setDestinationOrigin] = useState("");

    const [TransDestinationOrigin, setTransDestinationOrigin] = useState("");

    const [Trans_shipment, setTrans_shipment] = useState("");
    const [Trans_shipment1, setTrans_shipment1] = useState("");

    const [Trans_shipmentDescription, setTrans_shipmentDescription] =
      useState("");
    const [
      Trans_shipmentDescriptionmanual,
      setTrans_shipmentDescriptionmanual,
    ] = useState("");

    const getHsCodeSearch = async () => {
      const userData = localStorage.getItem("userDetail");
      const reference = localStorage.getItem("reference");
      const hscodeVerfication = localStorage.getItem("hsCodeVerficationData");

      if (userData) {
        var data = JSON.parse(userData);
        var dataReference = JSON.parse(reference);
        var hsCodeverfication = JSON.parse(hscodeVerfication);
        console.log("hsCodeverfication", hsCodeverfication);
        sethsCodeVerificationData(
          hsCodeverfication != null && hsCodeverfication.hscodeType
        );

        setTrans_shipmentDescription(
          hsCodeverfication && hsCodeverfication.TransShipmentDesription
        );
        setTrans_shipmentDescriptionmanual(
          hsCodeverfication && hsCodeverfication.TrandestinationDesription
        );
        setTrans_shipment1(hsCodeverfication && hsCodeverfication.anyReexport);
        setTrans_shipment(
          hsCodeverfication && hsCodeverfication.anyTransShipment
        );
        setTrans_shipment(
          hsCodeverfication && hsCodeverfication.anyTransShipment
        );

        setDestinationOrigin(
          hsCodeverfication && hsCodeverfication.countryDestination
        );
        setCountryOrigin(hsCodeverfication && hsCodeverfication.countryOrigin);

        sethscodeid(hsCodeverfication && hsCodeverfication._id);

        // sethscodeid(hsCodeverfication._id)
      }
    };
    const renderTooltip = (props) => (
      <Tooltip id="button-tooltip" {...props}>
        Please clarify - do we need to write just this text or need to upload a
        file?
      </Tooltip>
    );
    const HsCodeSeacrh = async () => {
      setloader(true);
      // await detectWhitespace(domain.trim());
      const userData = localStorage.getItem("userDetail");
      const reference = localStorage.getItem("reference");

      if (userData) {
        var data = JSON.parse(userData);
        var dataReference = JSON.parse(reference);
      }

      let payload = {
        reference: dataReference,
        hscodeType: hsCodeVerificationData,
        hsCodeVerifiyID: hscodeid,
        userId: data._id,
        countryOrigin: CountryOrigin,
        countryDestination: DestinationOrigin,
        anyTransShipment: Trans_shipment,
        TransShipmentDesription: Trans_shipmentDescription,
        TrandestinationDesription: Trans_shipmentDescriptionmanual,

        anyReexport: Trans_shipment1,
      };

      await fetch(`${API.BASE_URL}addHsCodeverificationData`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        mode: "cors",
        body: JSON.stringify({
          reference: dataReference,
          hscodeType: hsCodeVerificationData,
          hsCodeVerifiyID: hscodeid,
          userId: data._id,
          countryOrigin: CountryOrigin,
          countryDestination: DestinationOrigin,
          anyTransShipment: Trans_shipment,
          TransShipmentDesription: Trans_shipmentDescription,
          TrandestinationDesription: Trans_shipmentDescriptionmanual,

          anyReexport: Trans_shipment1,

          // CountryId:countryCode._id,
        }),
      })
        .then((response) => response.json())
        .then(async (res) => {
          setloader(false);
          if (res.status == true) {
            toast("Submit successfully, You can proceed to next");
            await localStorage.setItem(
              "hsCodeVerficationData",
              JSON.stringify(res.data)
            );
            if (hsCodeVerificationData[0].EccN) {
            }
          }
        })
        .catch((error) => {});
    };

    const getPrecusorSeachingdescription = async () => {
      // await detectWhitespace(domain.trim());
      const userData = localStorage.getItem("userDetail");
      const reference = localStorage.getItem("reference");
      const hscodeVerfication = localStorage.getItem("hsCodeVerficationData");
      var hsCodeverfication = JSON.parse(hscodeVerfication);
      if (userData) {
        var data = JSON.parse(userData);
        var dataReference = JSON.parse(reference);
      }

      let payload = {
        goodDescription:
          hsCodeverfication != null && hsCodeverfication.hscodeType,
      };
      console.log("hsCodeVerificationData", payload);
      await fetch(`${API.BASE_URL}getPrecusordata`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        mode: "cors",
        body: JSON.stringify({
          goodDescription:
            hsCodeverfication != null && hsCodeverfication.hscodeType,

          // CountryId:countryCode._id,
        }),
      })
        .then((response) => response.json())
        .then(async (res) => {
          console.log("res", res);

          if (res.status === true) {
            const matchedDescriptions = res.data.map(
              (item) => item.goodDescription
            );
            const updatedHsCodeVerificationData =
              hsCodeverfication != null &&
              hsCodeverfication.hscodeType.map((item) => ({
                ...item,
                Precursor:
                  matchedDescriptions.includes(item.goodDescription) &&
                  res.data.some(
                    (d) =>
                      d.goodDescription === item.goodDescription &&
                      d.PrecursorChemicals === "Yes"
                  )
                    ? "Yes"
                    : "No",
              }));
            sethsCodeVerificationData(updatedHsCodeVerificationData);
          }
        })
        .catch((error) => {});
    };
    useEffect(() => {
      getHsCodeSearch();
      getPrecusorSeachingdescription();
      // Re_export_Country_Control();

      // countrycodeSearch()
    }, []);

    console.log("hsCodeVerificationData+", hsCodeVerificationData);

    return (
      <>
        <h2 className="rpsHeading">Precursor Chemicals</h2>

        <Row>
          <Col md={12}>
            <Card className="mb-3 mb-lg-4 mb-md-4 card-boxx">
              <Card.Body>
                <Table responsive className="table_Box">
                  <thead>
                    <tr>
                      <th>Goods Description</th>
                      <th>HS code or Taric Code (EU)</th>
                      <th>
                        Chemical Or
                        <br /> Dangerous Good <br />
                      </th>
                      <th>
                        Dual Use Export
                        <br /> Controlled Goods <br />
                      </th>
                      <th>
                        is Precursor
                        <br /> Chemicals?
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {hsCodeVerificationData &&
                      hsCodeVerificationData.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td>
                              <Form.Group>
                                <Form.Control
                                  type={"text"}
                                  placeholder={"Enter Goods Description"}
                                  value={item.goodDescription}
                                />
                              </Form.Group>
                            </td>
                            <td>
                              <Form.Group>
                                <Form.Control
                                  type={"text"}
                                  placeholder={
                                    "Enter HS code or Taric Code (EU) "
                                  }
                                  value={item.hsCode}
                                />
                              </Form.Group>
                            </td>
                            <td>
                              <Form.Group>
                                <Form.Control
                                  type={"text"}
                                  placeholder={"Chemical Or Dangerous Good  "}
                                  value={item.chemicalDangerios}
                                />
                              </Form.Group>
                            </td>

                            <td>
                              <Form.Group>
                                <Form.Control
                                  type={"text"}
                                  placeholder={
                                    "Dual Use Export Controlled Goods  "
                                  }
                                  value={item.DualUseExport}
                                />
                              </Form.Group>
                            </td>

                            <td>
                              <Form.Group>
                                <Form.Control
                                  type={"text"}
                                  placeholder="is Precursor
                              Chemicals?"
                                  value={item.Precursor}
                                />
                              </Form.Group>

                              {item.Precursor == "Yes" ? (
                                <div className="cxBox mt-0">
                                  <span>
                                    <OverlayTrigger
                                      placement="top"
                                      delay={{ show: 250, hide: 400 }}
                                      overlay={renderTooltip}
                                    >
                                      <Image src={CorssIconRed} alt="" />
                                    </OverlayTrigger>
                                  </span>
                                </div>
                              ) : (
                                ""
                              )}
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </Table>
              </Card.Body>
              <div className="text-center mb-4">
                <Button
                  className="btn btn-primary mt-2 width_btn"
                  onClick={() => HsCodeSeacrh()}
                >
                  {loader == true ? (
                    <Oval
                      height="30"
                      width="30"
                      radius="30"
                      color="#f2f5f3"
                      ariaLabel="loading"
                      wrapperStyle={{ marginLeft: 120 }}
                      wrapperClass
                    />
                  ) : (
                    <>Start Screening</>
                  )}
                </Button>
                <ToastContainer />
              </div>
            </Card>
          </Col>
        </Row>
        {/* <div className="arrowUpDown">
          <Icon
            icon="fluent:chevron-down-12-filled"
            onClick={() => setOpen(!open)}
            fontSize={18}
          />
          <Icon icon="fluent:chevron-up-12-filled" fontSize={18} />
        </div> */}
      </>
    );
  };

  console.log("apiResult", apiResult);
  console.log("screeningTypess", screeningTypess);

  const BatchScreeningFirstHit = async () => {
    const userData = localStorage.getItem("userDetail");

    if (userData) {
      var data = JSON.parse(userData);
    }

    let payload = {
      userId: data._id,
      reference_number: array,
      shipmentType: screeningTypess,
    };

    console.log("payload", payload);

    await fetch(`${API.BASE_URL}BatchScreeningAddFeileds`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      // mode: "cors",
      body: JSON.stringify({
        userId: data._id,
        reference_number: array,
        shipmentType: screeningTypess,
        // reference_number:reference
      }),
    })
      .then((response) => response.json())
      .then(async (res) => {
        await localStorage.setItem("reference", JSON.stringify(array));
        // setreference(res.referenceNo);
      })
      .catch((error) => {});
  };
  useEffect(() => {
    RPSScreeningRef.current =
      RPSScreening || RPSScreeningConsignee || RPSScreeningConsignee;
    HSCodeVerificationRef.current = HSCodeVerification;
    HSCCodeSanctionsRef.current = HSCCodeSanctions;
    DualUseGoodsRef.current = DualUseGoods;
    DangerousGoodsRef.current = DangerousGoods;
  }, [
    RPSScreening,
    HSCodeVerification,
    HSCCodeSanctions,
    DualUseGoods,
    DangerousGoods,
    RPSScreeningConsignee,
    RPSScreeningenduser,
  ]);
  return (
    <>
      <Helmet bodyAttributes={{ style: "background-color : #efefef" }} />
      <section className="py-3">
        <Container>
          <div className={`widthSmall `}>
            {apiResponse !== null && (
              <StepProgressBar
                startingStep={0}
                onSubmit={onFormSubmit}
                steps={[
                  {
                    label: "RPS",
                    name: "step 1",
                    content: <Step1Content />,

                    // validator: () => RPSScreeningRef.current,
                    validator: () =>
                      firstresponse === "" && apiResult == false
                        ? BatchScreeningFirstHit()
                        : true,
                  },
                  {
                    label: "HS Code Verification",
                    name: "step 2",
                    content: <Step2Content />,
                    // validator: () => HSCodeVerificationRef.current,
                  },
                  {
                    label: "HS Code Sanctions",
                    name: "step 3",
                    content: <Step3Content />,
                    // validator: () => HSCCodeSanctionsRef.current,
                  },
                  {
                    label: "Dual-Use Goods",
                    name: "step 4",
                    content: <Step4Content />,
                    // validator: () => DualUseGoodsRef.current,
                  },
                  {
                    label: "Re-export Country Control",
                    name: "step 5",
                    content: <Step5Content />,
                  },
                  {
                    label: "Dangerous Goods",
                    name: "step 6",
                    content: <Step6Content />,
                    // validator: () => DangerousGoodsRef.current,
                  },
                  {
                    label: "Precursor Chemicals",
                    name: "step 7",
                    content: <Step7Content />,
                  },
                ]}
              />
            )}
            {/* Your API call logic to set apiResponse */}
            {/* Example: <button onClick={() => handleApiResponse(response)}>Get Response</button> */}
          </div>
        </Container>
      </section>
    </>
  );
};

export default BatchScreening;
