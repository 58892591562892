import React, { useState, useEffect } from "react";

import { Col, Container, Row, Card, Image, Table } from "react-bootstrap";
import Helmet from "react-helmet";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";
import { Icon } from "@iconify/react";
import LogoNew from "../images/logonew.png";
import LogoNew1 from "../images/logonew1.png";
import LogoNew2 from "../images/logonew2.png";
import { Form } from "react-bootstrap";
import RightIcon from "../images/crossii.svg";
import CorssIconBlank from "../images/crossblank.svg";
import * as API from "../../services/env";
import * as PIC from "../../services/env";

const ScreeningOverviewDangerousGoods = () => {
  const [ShipperData, setShipperData] = useState("");
  const [consineeData, setconsineeData] = useState("");

  const [EndUserData, setEndUserData] = useState("");

  const [hazchemDetails, sethazchemDetails] = useState([]);
  const [hscodeData, sethscodeData] = useState("");
  const [DangerousGoods, setDangerousGoods] = useState([]);
  const getHsCodeSearch = async (req, res) => {
    const userData = localStorage.getItem("userDetail");
    const reference = localStorage.getItem("reference");
    const hscodeVerfication = localStorage.getItem("hsCodeVerficationData");

    var screeningType = localStorage.getItem("screeningType");

    sethazchemDetails(screeningType);
    const screeningId = localStorage.getItem("screeningId");
    if (userData) {
      var data = JSON.parse(userData);
      var dataReference = JSON.parse(reference);
      var hsCodeverfication = JSON.parse(hscodeVerfication);
      var screeningIds = JSON.parse(screeningId);

      getHsCodesearchDetail(screeningIds);
    }

    //     if (userData) {
    //       var data = JSON.parse(userData);
    //       var dataReference = JSON.parse(reference);
    //       var hsCodeverfication = JSON.parse(hscodeVerfication);
    //  setEndUserData(hsCodeverfication&&hsCodeverfication.screeningId)

    //       getHsCodesearchDetail(hsCodeverfication&&hsCodeverfication.screeningId)
    //     }
  };

  const getHsCodesearchDetail = async (index) => {
    await fetch(`${API.BASE_URL}getScreenigTotalAALdeatil`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        ScreningId: index,
      }),
    })
      .then((response) => response.json())
      .then(async (res) => {
        if (res.status == true) {
          sethscodeData(res.hscodeData);
          setDangerousGoods(res.hazchemDetails);
          setShipperData(res.data[0].userType == "Shipper" ? res.data[0] : "");
          setconsineeData(
            res.data[1].userType == "Consignee" ? res.data[1] : ""
          );
          // setEndUserData(res.data[2].userType == 'End User' ? res.data[2] : "")

          // sethscodetype(res.hscodeData?res.hscodeData.hscodeType:'')
        }
      })
      .catch((error) => {});
  };

  useEffect(() => {
    // getECCNcodesearch();
    getHsCodeSearch();
  }, []);
  const getHazchemDescription = (hazchem) => {
    if (
      typeof hazchem === "object" &&
      hazchem !== null &&
      hazchem.hasOwnProperty("goodDescription")
    ) {
      return hazchem.goodDescription;
    } else if (typeof hazchem === "string") {
      return hazchem;
    } else {
      return "";
    }
  };
  const getHazchemPic = (hazchem) => {
    if (
      typeof hazchem === "object" &&
      hazchem !== null &&
      hazchem.hasOwnProperty("Pic")
    ) {
      return hazchem.Pic;
    } else {
      return ""; // Return an empty string or a placeholder image URL if desired
    }
  };
  return (
    <>
      <Helmet bodyAttributes={{ style: "background-color : #efefef" }} />
      <section className="py-3 overview_sec">
        <Container fluid className="widthSmall">
          <Row className="mb-lg-4 mb-md-4 mb-3">
            <Col md={12}>
              <div className="requestFlex">
                <h2 className="scheading">
                  Screening Overview : Dangerous Goods
                </h2>

                <Button
                  as={Link}
                  variant="warning"
                  size="md"
                  className="btnBack"
                  to={`/screening-overview/1`}
                >
                  <Icon icon="carbon:task-view" fontSize={18} />
                  Back to Screening Overview
                </Button>
              </div>
            </Col>
          </Row>

          <Row>
            <Col md={12} className="tab_link">
              {/* {hazchemDetails!='Batch Screening'?
                   <Link to="/screeningoverview-RPS">RPS</Link>
              
              :<>
         
              
              </>} */}
              <Link to="/screeningoverview-RPS">RPS</Link>

              <Link to="/screeningoverview-HSCodeVerification">
                HS Code Verification
              </Link>
              <Link to="/screeningoverview-HSCodeSanctions">
                HS Code Sanctions
              </Link>
              <Link to="/screeningoverview-DualUseGoods">Dual-Use Goods</Link>
              <Link to="/screeningoverview-DualUseExport">
                Re-export Country Control
              </Link>
              <Link className="active" to="/screeningoverview-DangerousGoods">
                Dangerous Goods
              </Link>
              <Link to="/screeningoverview-PrecursorChemicals">
                Precursor Chemicals
              </Link>
            </Col>
          </Row>

          <Row>
            <Col md={12}>
              <Card className="mb-3 mb-lg-4 mb-md-4 card-boxx">
                <Card.Body>
                  <Table responsive className="table_Box">
                    <thead>
                      <tr>
                        <th>Goods Description</th>
                        <th>HS code or Taric Code (EU)</th>
                        <th>Chemical Or Dangerous Good</th>
                        <th>Dual Use Export Controlled Goods?</th>

                        <th>HAZCHEM Class</th>
                        <th>Label</th>
                      </tr>
                    </thead>
                    <tbody>
                      {console.log("hscodeData", hscodeData)}
                      {hscodeData.length > 0 &&
                        hscodeData[0].hscodeData.map((item, index) => {
                          return (
                            <tr>
                              <td>
                                <Form.Group>
                                  <Form.Control
                                    type={"text"}
                                    placeholder={"Enter Goods Description"}
                                    value={item.goodDescription}
                                    readOnly={true}
                                  />
                                </Form.Group>
                              </td>
                              <td>
                                <Form.Group>
                                  <Form.Control
                                    type={"text"}
                                    placeholder={
                                      "Enter HS code or Taric Code (EU) "
                                    }
                                    value={item.hsCode}
                                    readOnly={true}
                                  />
                                </Form.Group>
                              </td>
                              <td>
                                <Form.Group>
                                  <Form.Control
                                    type={"text"}
                                    placeholder={
                                      "Enter HS code or Taric Code (EU) "
                                    }
                                    value={item.chemicalDangerios}
                                    readOnly={true}
                                  />
                                </Form.Group>
                              </td>
                              <td>
                                <Form.Group>
                                  <Form.Control
                                    type={"text"}
                                    placeholder={
                                      "Enter HS code or Taric Code (EU) "
                                    }
                                    value={item.DualUseExport}
                                    readOnly={true}
                                  />
                                </Form.Group>
                              </td>
                              {/* <td>
                              <div className="crBox">HAZCHEM Class	HAZCHEM Class	
                                <Image src={RightIcon} alt="" />
                              </div>
                            </td>
                            <td>
                              <div className="crBox">
                                <Image src={CorssIconBlank} alt="" />
                              </div>
                            </td> */}

                              <td>
                                <Form.Group>
                                  <Form.Control
                                    type={"text"}
                                    placeholder={"Yes"}
                                    value={
                                      item.hasOwnProperty("HAZCHEM")
                                        ? getHazchemDescription(item.HAZCHEM)
                                        : ""
                                      // item.badgeData.BadgeTitle
                                    }
                                    readOnly={true}
                                  />
                                </Form.Group>
                              </td>

                              <td>
                                <td>
                                  {item.hasOwnProperty("HAZCHEM") &&
                                  item.HAZCHEM.hasOwnProperty("Pic") ? (

                                    item.HAZCHEM.pic!=null&&<div className="text-center">
                                    <Image
                                      src={`${PIC.PIC_URL}${getHazchemPic(
                                        item.HAZCHEM
                                      )}`}
                                      alt=""
                                      style={{ height: 80, width: 80 }}
                                    />
                                  </div>

                                    
                                  ) : null}
                                </td>
                              </td>
                            </tr>
                          );
                        })}

                      {/* <tr>
                        <td>
                          <Form.Group>
                            <Form.Control
                              type={"text"}
                              placeholder={"Enter Goods Description"}
                            />
                          </Form.Group>
                        </td>
                        <td>
                          <Form.Group>
                            <Form.Control
                              type={"text"}
                              placeholder={"Enter HS code or Taric Code (EU) "}
                            />
                          </Form.Group>
                        </td>
                        <td>
                          <div className="crBox">
                            <Image src={CorssIconBlank} alt="" />
                          </div>
                        </td>
                        <td>
                          <div className="crBox">
                            <Image src={RightIcon} alt="" />
                          </div>
                        </td>

                        <td>
                          <Form.Group>
                            <Form.Control type={"text"} placeholder={"Yes"} />
                          </Form.Group>
                        </td>
                        <td>
                          <div className="text-center">
                            <Image src={LogoNew1} alt="" />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <Form.Group>
                            <Form.Control
                              type={"text"}
                              placeholder={"Enter Goods Description"}
                            />
                          </Form.Group>
                        </td>
                        <td>
                          <Form.Group>
                            <Form.Control
                              type={"text"}
                              placeholder={"Enter HS code or Taric Code (EU) "}
                            />
                          </Form.Group>
                        </td>
                        <td>
                          <div className="crBox">
                            <Image src={RightIcon} alt="" />
                          </div>
                        </td>
                        <td>
                          <div className="crBox">
                            <Image src={RightIcon} alt="" />
                          </div>
                        </td>

                        <td>
                          <Form.Group>
                            <Form.Control type={"text"} placeholder={"Yes"} />
                          </Form.Group>
                        </td>
                        <td>
                          <div className="text-center">
                            <Image src={LogoNew2} alt="" />
                          </div>
                        </td>
                      </tr> */}
                    </tbody>
                  </Table>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default ScreeningOverviewDangerousGoods;
